import { template } from "translations/templating";

export type TranslationsFR = typeof fr;

const fr = {
  abbreviations: {
    centimeter: {
      abbreviation: "cm",
      fullForm: "centimètres",
    },
    kilogram: {
      abbreviation: "kg",
      fullForm: "",
    },
    kilometer: {
      abbreviation: "km",
      fullForm: "",
    },
    kilometers: {
      abbreviation: "",
      fullForm: "",
    },
    sapv: {
      abbreviation: "",
      fullForm: "",
    },
    sgb: {
      abbreviation: "",
      fullForm: "",
    },
    sgbV: {
      abbreviation: "",
      fullForm: "",
    },
    sgbXI: {
      abbreviation: "",
      fullForm: "",
    },
  },
  accessibility: {
    skipLink: "Passer au contenu principal",
  },
  acp: {
    accountRoleAccessTooltip:
      "If ACCESS is selected, the user has access and can log in to the partner app",
    accountRoleContactTooltip:
      "If CONTACT is selected, the user is displayed as a contact for the social worker in the hospital.",
    accountTimeline: {
      accountCreated: "Account created",
      accountDeactivated: "",
      accountReactivated: "",
      accountUpdated: "Votre compte a été mis à jour",
      activationEmailSent: "L'e-mail d'activation a été envoyé",
      clinicStatusChanged: "Changement de statut de la clinique",
      login: "Account logged in",
      passwordChange: "Le mot de passe a été modifié",
      passwordResetSuccessfully: "Password reset successfully",
      passwordSetByAdmin: "Password set by admin",
      permissionsUpdated: (args: { resource: string }) =>
        template("Permissions updated: {{resource}}", args),
      resetPasswordRequestedByAdmin: "Reset password requested",
      resetPasswordRequestedByUser: "Reset password requested by user",
      ssoLogin: "",
      title: "Timeline",
    },
    acpAccountProfile: {
      activeAddons: "",
      activeIntegrations: "",
      addOnRcmReport: "",
      addOnRcmReportPurchased: "",
      addonTcare: "",
      datesTcareTrial: (args: { fromDate: string; untilDate: string }) =>
        template("from {{fromDate}} until {{untilDate}}", args),
      integrationArchive: "",
      integrationInformationMessage: "",
      integrationReceiver: "",
      integrationValue: {
        active: "",
        inactive: "",
      },
      ssoInfo: "",
      tcarePurchased: "",
      tcareTrial: "",
    },
    acpCompany: {
      ikNumberAlreadyExists: (args: { companyId: string }) =>
        template("IK number already in use for company # {{companyId}}", args),
      ikNumberAlreadyExistsError: "",
      ikNumberAlreadyTaken: (args: { ikNumber: string; companyId: string }) =>
        template(
          "IK number {{ikNumber}} already in use for company #{{companyId}}",
          args,
        ),
      messageIkNumber: "",
    },
    addAccount: "Add account",
    addAccounts: "Add accounts",
    addHospital: "Add hospital",
    addListOfStation: "Add the list of stations",
    addPriorityProvider: "Add Priority Provider",
    addProvider: "Add provider",
    alreadyHasPermission: "Account already has permissions for this entity",
    barthelIndex: {
      addNewAssessment: "Ajouter un nouvel avis",
      bathing: "",
      bathingValueFive: "",
      date: "Date",
      deplacement: "",
      deplacementValueFifteen: "",
      deplacementValueFive: "",
      deplacementValueTen: "",
      dialogTitle: "Barthel index",
      dressing: "",
      dressingValueFive: "",
      dressingValueTen: "",
      editHasData: "Remplir le formulaire détaillé",
      editHasNoData: "See more details or add new assessment",
      fecalIncontinence: "",
      fecalIncontinenceValueFive: "",
      fecalIncontinenceValueTen: "",
      fecalIncontinenceValueZero: "",
      fieldsMissing: "Some fields are missing",
      food: "",
      foodValueFive: "",
      foodValueTen: "",
      foodValueZero: "",
      inputPlaceholder: "Type in the sum and date",
      maxAmountOfDetailsReached:
        "The maximum amount of assessments has been reached",
      personalCare: "",
      personalCareValueFive: "",
      relocating: "",
      relocatingFive: "",
      relocatingValueFifteen: "",
      relocatingValueTen: "",
      relocatingZero: "",
      stairClimbing: "",
      stairClimbingValueFive: "",
      stairClimbingValueTen: "",
      toiletUse: "",
      toiletUseValueFive: "",
      toiletUseValueTen: "",
      toiletUseValueZero: "",
      uninaryIncontinence: "",
      uninaryIncontinenceValueFive: "",
      uninaryIncontinenceValueTen: "",
      uninaryIncontinenceValueZero: "",
      valueZero: "",
    },
    batchSendActivation:
      "Send activation email to all accounts with status created",
    billing: "Billing",
    blacklistedAssessmentFields: "Blacklisted assessment fields",
    careproviderPages: {
      catchmentArea: "Sectorisation",
      completeProfile: "Complete profile",
      completeProfileAccountsWarning:
        "Once the profile is completed and saved, newly created accounts will receive an activation email to set up their password. Already existing accounts will be added to the Team of the provider. Please, make sure that the data above is correct.",
      dpaSection: {
        churchOwnedInfo: "Appartient à l'église :",
        currentDpaVersion: "La DPA actuelle peut être consultée {select:ici}",
        dpaUploadOnlyForChurch:
          "Remarque : le téléchargement de la DPA n'est nécessaire que pour les fournisseurs appartenant à l'Église, car nous avons besoin d'un document signé de leur part - s'ils n'appartiennent pas à l'Église, ils doivent cocher la case dans l'application.",
        noDpaUploadedYet: "DPA pas encore téléchargé",
        signedAt: "DPA signé à :",
        uploadedFile: "Fichier téléchargé :",
      },
      duplicatedFrom: "Duplicated from",
      nearbyClinics: "Nearby clinics and Prio-clinics",
      onboarding: {
        onboardingContact: "Onboarding Contact",
      },
      stats: {
        acceptedRequests: "Accepted requests",
        activeSince: "Active since",
        allTime: "All time",
        attributedPatients: "Attributed patients",
        messagesSent: "Messages sent",
        onboardingSource: "Source of the onboarding",
        pastDays: "Past 30 days",
        receivedRequests: "Received requests",
        requestsDeclined: "Requests declined",
        responseRate: "Response rate",
        responseTime: "Response time",
        title: "Stats",
      },
      statusForm: {
        errorActiveAccountsLinked:
          "Veuillez noter qu'il existe encore des comptes actifs liés à ce fournisseur, ce qui peut entraîner des problèmes lors de l'intégration (par exemple la création d'un nouveau compte). ",
        errorInvalidStatus:
          "Status Invalid is deprecated. Please use Inactive + a reason!",
        errorMissingSpecializations:
          "Please set the required specializations, before being able to change the status",
        errorNoFacilitiesNoServices:
          'Please set the required solutions and/or services, before being able to change the status. The status "Trash" or "Inactive" can still be set.',
        errorTransitionToPendingPassword:
          'Please complete the profile including accounts and click on the "Profile filled" button. The status will automatically change to "Pending Password Activation".',
        onboardingStatus: "",
        reason: "",
        status: "",
      },
      statusUpdateErrorNoAccount:
        "Impossible de mettre le fournisseur à l'état actif, aucun compte actif associé",
      tabs: {
        accounts: "Accounts",
        capacity: "Capacity",
        catchment: "Sectorisation",
        profile: "Profile",
        services: "Services",
        timeline: "Timeline",
      },
    },
    careSeekerContacts: "Contacts",
    clinics: "Clinics",
    confirmDeleteInvoice: (args: { invoiceReference: string }) =>
      template(
        "Are you sure that you want to delete the invoice {{invoiceReference}}?",
        args,
      ),
    consultants: {
      consultantName: "",
      createSubheading: "Create consultant",
      description: "",
      documentation: {
        events: {
          consultantCreated: {
            title: "Consultant Created",
          },
          consultantFileCreated: {
            title: "Consultant File Uploaded",
          },
          consultantFileDeleted: {
            title: "Consultant File Deleted",
          },
          consultantUpdated: {
            description: "Following fields were updated: ",
            title: "Consultant Updated",
          },
          noEvents: "No events for this consultant",
        },
        title: "Documentation",
      },
      generalInformation: "",
      noRolesTooltip: "",
      openingHours: {
        description: "",
        errorMissingHours:
          "Les heures d'ouverture ne peuvent pas être vides si le consultant est ouvert ce jour-là.",
        formattingHint: "Format : 8.00 - 18.00 ou 8.00 - 12.00 & 14.00 - 18.00",
        heading: "",
        placeholder: (args: { day: string }) =>
          template("Heures d'ouverture le {{day}}", args),
      },
      projectSponsor: "",
      searchPlaceholder: "",
      subheading: "",
      uploadFilesCardTitle: "Consultant profile photo uploader",
    },
    copiedToClipboard: "",
    copyPasteStations: "Copy paste three excel columns",
    copyReceiverAccountActivationLink: "Copy Receiver account activation link",
    copySelfonboardingLink: "",
    copySenderAccountActivationLink: "Copy Sender account activation link",
    createConsultant: "Créer un consultant",
    createReceiver: "Créer un receveur",
    createSender: "Créer un demandeur de soins",
    deactivateAccountDetails:
      "The only way to reactivate this account will be via the activation email",
    deactivateAccountTitle: "Do you really want to deactivate this account?",
    digitalPatientRecord: "Digital patient record",
    emailAlreadyLinked: "This email address is already linked to an account.",
    emailsSent: "Emails were sent",
    errorAddingRole: (args: { emailDomain: string; list: string }) =>
      template(
        "Le compte a été ajouté, cependant, en raison du domaine de messagerie du compte \"{{emailDomain}}\" n'étant pas répertorié dans ces domaines autorisés Careseekers [{{list}}], l'ajout de rôles a été rejeté.",
        args,
      ),
    errorAddingSeekerRoles: (args: { emailDomain: string }) =>
      template(
        "Impossible d'ajouter des rôles pour ce Careseeker car le domaine de messagerie \"{{emailDomain}}\" pour ce compte n'est pas dans les domaines de messagerie autorisés pour cet expéditeur.",
        args,
      ),
    errorOnboardingMailNearbyClinicMissing:
      "Could not send onboarding mail, no nearby/prio clinic available",
    excelImportAccounts: "Excel import accounts",
    excelImportAccountsBadFormat:
      "The data was not entered in the correct format",
    excelImportAccountsPlaceholder: "Copier coller des comptes depuis Excel",
    excelImportAccountsRowError: (args: { row: string }) =>
      template(
        "Problème avec la ligne {{row}}. Veuillez vérifier les erreurs (l'e-mail existe déjà, etc.)",
        args,
      ),
    failedToLoadReceiverRules: "Failed to load rules",
    filterBySocialWorker: "Do not display by SW by default",
    firstLogin: "Premier login",
    generalInformation: "Informations générales",
    hospitalConfiguration: "Hospital specific configuration",
    hospitalFance: "Hôpital - France",
    hospitalInformation: "Information about the hospital",
    hospitalInterests: {
      details: "More details",
      geriatrics: "Geriatrics",
      homeCare: "Home care ",
      kisIntegration: "HIS integration",
      medicalSupplies: "Medical Supplies",
      rehab: "Reha",
      title: "The hospital is interested in the following modules:",
      transportation: "Transportation",
    },
    hospitalProfile: "Hospital profile",
    identities: "Identities",
    inactiveClinics: "Inactive clinics",
    informationSystem: "Hospital information system",
    institutionIdentifier: "Institution identifier ",
    internalAccounts: "Internal Accounts",
    internalPermissions: "Internal Permissions",
    internalRemarks: "Internal remarks",
    internetBrowser: "Browser",
    invitationEmailSent: (args: { account: string }) =>
      template("Invitation email was sent to {{account}}", args),
    ipWhitelistingForLogins: "Allowed IP ranges for login",
    kisApiActivated: "KIS API activated",
    lastLogin: "Dernier login",
    listedInbToCApp: "We want to be listed in the B2C App",
    login: {
      prefixPreprod: "Preprod",
      prefixProd: "Production",
      titlePreprod: "ACP Preprod",
      titleProd: "ACP Production ",
    },
    missingContactsData: "Missing info has to be filled",
    noOnboardingEmail: "No onboarding email",
    notes: "Notes",
    notifyActivationSent: "Activation email has been sent",
    notifyPasswordResetSent: "Forgot password email was sent",
    numberOfBeds: "Nombre de lits",
    onboardingEmail: "Onboarding email",
    onboardingRadius: "Onboarding Radius (km)",
    onboardingRequirements: "Onboarding requirements",
    organisationProviders:
      "Organisational bonus points for the following providers",
    pasteZipcodesButton: "Paste From Clipboard",
    pasteZipcodesTooltip:
      'In order to paste a list, copy (cmd+c) the postal codes in the correct format (e.g. 10999, 10559) and click on "Paste from clipboard"',
    permissions: "Permissions",
    priorityListActivationTarget: "",
    priorityProviders: "Priority Providers",
    priorityProvidersList: {
      name: "Name",
      onboarding: "Onboarding",
      patientType: "Type of patient",
      status: "Statut",
    },
    profile: "Profile",
    providerSearch: {
      enterLocation: "",
      facility: "",
      manuallyAddedProviders: "See all manually added providers",
      manuallyAddedProvidersTitle: "Manually added providers",
      numberOfProvidersWithStatus: () => "",
      patientType: "",
      rateOfAllProvidersInCertainAreawithFacility: () => "",
      rateOfAllProvidersInCertainAreawithService: () => "",
      rateOfProviders: () => "",
      services: "",
      showMoreButton: "",
    },
    recareAccounts: "Recare Accounts",
    receiverFilters: {
      careproviderId: "Receiver ID",
      careseekerId: "Careseeker ID",
      createdAt: "Created at",
      false: "false",
      filterId: "Filter ID",
      isWhitelist: "Is whitelist rule",
      productGroup: "Product group",
      searchPartialZipcode: "Search partial zipcode",
      true: "true",
    },
    receiverRules: "Receiver rules",
    resetGroups: {
      activeFiles: "",
      activePatients: "",
      activeRequests: "",
      archived: "",
      columnCount: "",
      columnLostSession: "",
      errorText:
        "Malheureusement, vous n'avez pas les droits pour réinitialiser les groupes. Veuillez contacter l'un des comptes suivants si vous souhaitez réinitialiser ce groupe :",
      menuResetGroup: "Reset encryption group",
      resetGroupsSubtitle: (args: { sessionNumber: string }) =>
        template(
          "If you reset the group, all non-shared encrypted data will be lost, so you should only do this if you have no other option. The group must regain access to {{sessionNumber}} sessions. The following table contains the number of lost sessions.",
          args,
        ),
      resetGroupsTitle: "",
      successMessage: "Seald group successfully reset.",
      understandButton: "Je comprends",
    },
    satisfactionScore: {
      dateLastScore: "Dernière actualisation :",
      submitSatisfactionScore: "Soumettre un score de satisfaction",
      title: "Satisfaction des clients",
    },
    searchAccounts: "Search accounts",
    searchClinics: "Search clinics",
    searchProviders: "Search providers",
    sendActivationMail: "Envoyer le mail d'activation",
    senderPage: {
      bankAccountOwner: "",
      bankingInformationTitle: "Information on fee settlement",
      bankName: "Name and city of the Bank",
      billingAddress: "",
      hospitalGerman: "Hôpital - Allemagne",
      hospitalNumber: "IK number of the hospital",
      iban: "IBAN",
      insurance: "Assurance santé",
    },
    senderRules: "Sender rules",
    senderType: "Sélection de la catégorie d'expéditeur",
    sendInfoEmail: "Send info email",
    sendPasswordResetEmail: "Reset Password Email",
    sendWelcomeEmail: "Activation Email",
    sessionExpirationDuration: "User session expiration (in minutes)",
    startDate: "Date de début",
    stationCode: "Station",
    stationDescription: "Description",
    stationPhoneNumber: "Numéro de téléphone",
    stations: "Stations",
    test: "test",
    title: "Title",
    transferPerMonth: "Nombre de transferts par mois",
    whitelistedHomeCareSpecializations:
      "Spécialisations en soins à domicile activées ",
    whitelistedHospitalSpecializations:
      "Spécialisations activées pour les transfers hôpitaux",
    whitelistedRehaSpecializations: "Services de réadaptation activés",
    workshopDate: "Kick Off Workshop date",
  },
  actions: {
    abort: "Abandonner",
    accept: "Accepter",
    actions: "Actions",
    add: "Ajouter",
    addMember: "Ajouter un membre",
    addMoreInformation: "Ajouter d’autres informations",
    addSearch: "Ajouter une recherche",
    applyFilters: "Appliquer",
    archiveButton: "archiver",
    back: "Précédent",
    backToAnswers: "Retour aux réponses",
    backToDashboard: "Retour au tableau de bord",
    backToSearch: "Retour à la recherche",
    book: "livre",
    bookTransport: "",
    cancel: "Annuler",
    chooseFile: "Choisissez un fichier",
    clear: "Effacer",
    clearDate: "Supprimer la date",
    close: "Fermer",
    confirm: "Confirmer",
    continueSearch: "Relancer la recherche",
    create: "Créer",
    deactivate: "Desactiver",
    decline: "Décliner",
    delete: "Supprimer",
    discard: "Jeter",
    dismiss: "Ignorer",
    download: "Télécharger",
    edit: "Modifier",
    editPatientProfile: "Modifier le profil du patient",
    expand: "Développer",
    finish: "Terminer",
    giveAccess: "Donner accès",
    handoverInsurance: "",
    import: "Importer",
    invalid: "non valable",
    invalidTimeFormat: "Format d'heure invalide",
    learnMore: "En savior plus",
    minimize: "Minimiser",
    missing: "Requis",
    moreOptions: "Plus d'options",
    next: "Suivant",
    no: "Non",
    notify: "Notifier",
    pause: "",
    print: "Imprimer",
    printDashboard: "Imprimer la liste",
    refresh: "Actualiser",
    reject: "Rejeter",
    resend: "Renvoyer",
    resendRequest: "Renvoyer la demande",
    reset: "Réinitialiser",
    review: "",
    revokeAccess: "",
    save: "Sauvegarder",
    saveAndExit: "Sauvegarder et quitter",
    saveChanges: "Sauvegarder",
    saved: "Sauvegardé",
    saving: "Sauvegarde ...",
    searchForMobile: "",
    seeDetails: "Détail des envois",
    select: "Ajouter",
    selectAnotherDate: "Autre date/heure possible",
    selectFileFromComputer: "Sélectionner à partir de l'ordinateur",
    selectPlaceholder: "Veuillez sélectionner",
    send: "Envoyer",
    shareFile: "Partager",
    shareFroms: "",
    showResults: "",
    skip: "Ignorer",
    start: "",
    stopSearch: "Annuler",
    submit: "Envoyer",
    tryAgain: "Essayer à nouveau",
    undo: "Annuler",
    undoActionCompletion:
      "Nous avons annulé l'action et la demande a repris son état précédent.",
    undoConfirm: "Les modifications ont été annulées.",
    unknownId: "Identifiant du patient inconnu",
    update: "Mettre à jour",
    uploadFile: "Télécharger",
    validate: "Valider",
    validateAndGrantAccess: "valider et donner accès",
    validationError: "Certains champs obligatoires sont manquants",
    validationErrorMissingOrInvalid:
      "Certains champs obligatoires sont manquants ou invalides",
    valueEmtpy: "non renseigné",
    yes: "Oui",
    youCanUpload: "Vous pouvez télécharger un fichier",
  },
  address: {
    address: "Adresse",
    alternativeSearchLocationCheckbox: "",
    anotherAddress: "Une autre adresse",
    city: "Ville",
    clinicAddress: "Adresse de la clinique",
    country: "Pays",
    enterAddress: "Veuillez entrer l'adresse ici",
    houseNumberBlockedIE: "",
    invalidAddress: "L'adresse entrée n'est pas valide",
    location: "Adresse",
    locationOfPatient: "Code postal du patient",
    noNeedForAddress:
      "Seul un code postal est nécessaire pour ce type de transfert",
    number: "Numéro de rue",
    patientAddress: "Adresse du patient",
    pickupAddress: "Adresse de ramassage",
    pleaseAddHouseNumber:
      "Important : veuillez indiquer l'adresse exacte, sans oublier le numéro de rue ",
    street: "Rue",
    streetNumber: "Rue et numéro de rue",
    zipcode: "Code postal",
    zipcodePlaceHolder: "Entrez le code postal",
    zipcodeSelectPlaceholder: "Veuillez taper ou sélectionner plusieurs",
  },
  assessments: {
    formValidation: {
      careDurationMissing: "",
      careDurationTitle: "",
      characterLimit: (args: { valueLength: string; characterLimit: string }) =>
        template(
          "la longueur de la valeur saisie ({{valueLength}}) est supérieure à la limite de caractères {{characterLimit}}",
          args,
        ),
      dateOfBirthMissing: "",
      dateOfBirthTitle: "",
      genderMissing: "",
      mandatoryExplanation: "",
      patientAddressMissing: "",
      patientAddressTitle: "",
      publicPensionMissing: "",
      singleSelect: "",
      singleSelectError: "",
      specialisationMissing: "",
      specializationsMissing: "",
      specializationsTitle: "",
      startDateMissing: "",
      startDateTitle: "",
      supplementaryInsuranceDetailsMissing: "",
      treatmentLocationMissing: "",
      treatmentLocationTitle: "",
      typeOfCareMissing: "",
      typeOfCareTitle: "",
    },
    htohinfobox:
      "Nous filtrons les receveurs à contacter dans le rayon de recherche autour de ce code postal.",
    medicalSupplies: {
      deliveryAnotherAddress: "",
      deliveryDetails: "",
      deliveryHospital: "",
      deliveryInfo: "",
      deliveryPatientsAddress: "",
      medicalSupplies: "",
      prescription: "",
      prescriptionDoctor: "",
      prescriptionIssued: "",
    },
    onePageAssessment: {
      activateOnePageAssessment: "",
      additionalInformation: "",
      caution: "",
      disclaimer: "",
    },
    rehab: {
      singerUploadInfo: "",
    },
    transportSearch: {
      additionalInformationPlaceholder: "",
      addTransportAfterValidatedSearchExplanation: "",
      addTransportAfterValidatedSearchTitle: "",
      endTimePlaceholder: "09:00",
      minimumTimeWindow: "Délai minimum d'une heure pour la collecte",
      monitoringHeart: "",
      monitoringNeeded: "",
      monitoringPulse: "",
      onsiteSupportRequired: "",
      oxygenNeeded: "",
      pickUpTime: "",
      searchDestination: "Destination",
      startTimePlaceholder: "08:00",
      titleTransportSearch: "Recherche de transport",
      transportStyle: "",
      transportStyleCarryingChair: "",
      transportStyleLyingDown: "",
      transportStyleSeated: "",
      transportStyleWheelchair: "",
      transportType: "",
      visualImpairement: "",
    },
  },
  auctionRequest: {
    accept: "Accepter le patient",
    acceptAnotherDateRequest: "Accepter le patient à une autre date",
    acceptInfo:
      "Vous pourrez indiquer la date et l'heure d’arrivée possible du patient après avoir cliqué sur « accepter » ",
    acceptInfoNewChat: "",
    acceptModalTitle: "Accepter le patient",
    acceptOptionalMessagePlaceholder: "",
    acceptRequest: "Accepter la prise en charge",
    acceptTestRequest: 'Écrivez ici "Test"',
    accessEncryptedDataHint:
      "L'établissement d'origine doit vous donner accès à cette information en cliquant sur \"Donner accès aux données personnelles\"",
    answerTestRequest:
      "Pour compléter votre inscription gratuite, veuillez cliquer sur ACCEPTER ou DÉCLINER. Pour ce test, peu importe l'option que vous choisissez.",
    arrivalTime: "Heure d’arrivée",
    automaticDeclineWithoutTime:
      "Vous ne recevrez plus de demandes de prise en charge qui sont censées débuter avant la date et l’heure que vous aurez indiquées. Toutes les autres demandes de prise en charge en attente avant la date et l’heure que vous aurez indiquées seront déclinées automatiquement.",
    automaticDeclineWithTime:
      "Vous ne recevrez plus de demandes de prise en charge qui sont censées débuter avant la date et l’heure que vous aurez indiquées. Toutes les autres demandes de prise en charge en attente avant la date et l’heure que vous aurez indiquées seront déclinées automatiquement.",
    backToRequests: "Retour aux demandes",
    bcpName: "",
    bcpProfileName: "",
    cancelConfirmation: "Êtes-vous sûr(e) de vouloir annuler cette action ?",
    capacityDate: "Sélectionner la date",
    capacityTime: "Sélectionner l’heure",
    capacityTimeOptional: "Sélectionner l’heure (opt.)",
    catchmentComment:
      "Aussitôt l'information sauvegardée dans notre système (cela peut prendre un jour ou deux), vos demandes seront adaptées.",
    commentWhichServicesOptional:
      "Optionnel : Quels services n'offrez-vous pas?",
    coronaOnlyAcceptHint:
      "En acceptant cette demande le patient vous sera directement attribué.",
    decline: "Décliner la demande",
    declinedReasonOther: "Autre raison",
    declinedReasonPayer: "Pas de place à cause de la situation financière",
    destination: "",
    emptyMessageWarning: "Écrivez un message",
    encryptedMessage: "Message chiffré",
    entryDate: "Date",
    entryDateTimeExplainer:
      "Veuillez indiquer la date de début et l'heure d'arrivée possible pour vous",
    entryDateTimeExplainerOptional:
      "Veuillez indiquer la date de début et l'heure d'arrivée possible pour vous",
    errorAlert: {
      text: "Impossible de répondre à la requête",
      title: "Une erreur est survenue, veuillez essayer à nouveau ",
    },
    explainOtherReason: "Veuillez expliquer",
    explainOtherReasonOptional: "Veuillez expliquer (optionnel)",
    explainWhyYouDecline:
      "Vous êtes sur le point de décliner la demande, merci d'expliquer pourquoi",
    fakeData: "FAUSSES DONNÉES",
    geographicallyUnattractive:
      "Le patient est en dehors de notre zone d'activité",
    germanyWideSearchWarning: "",
    hospitalName: "Nom de l'hôpital\n",
    lastWorkedOn: (args: { time: string; personResponsible: string }) =>
      template(
        "Dernière action effectuée : {{time}}, {{personResponsible}}",
        args,
      ),
    leaveAComment: "Veuillez laisser votre message :",
    loadingError: "Impossible de charger la page",
    mapCaption: "",
    messengerTab: "Réponse",
    mostImportantTransferDetails: "Informations essentielles",
    noAdditionalInformationProvided:
      "Aucune information supplémentaire n'a été donnée",
    noCapacity: "Nous n'avons pas de place jusqu'au ...",
    noCapacityExplainer:
      "Recare ne souhaite pas vous envoyer des demandes inutilement.\n À partir de quelle date souhaitez-vous recevoir de nouvelles demandes ?",
    noCapacityForPatient:
      "Nous ne pouvons accepter ce patient en particulier mais nous souhaitons recevoir d'autres demandes.",
    noCapacityForPatientReason: "Profil du patient inadapté à la structure",
    noCapacityHint:
      'Vous pourrez actualiser à tout moment dans vos "Paramètres".',
    noCapacityOther: "Autre",
    noCapacityReason: "Nous n'avons pas de lits disponibles pour le moment",
    noCapacityReasonExplainer:
      "Quand aurez-vous de nouveau des lits disponibles ?",
    noGermsInfections: "Pas de BMR/BHRe",
    offeringMultipleSolutions:
      "Ce receveur propose plusieurs types de prise en charge. Veuillez sélectionner celle qui correspond le mieux aux besoins du patient : ",
    onSitePatientCareEnsured: "",
    other: "Autre",
    pageUnavailable: "Cette page n'est plus disponible",
    patientAdmissionDetails: "Information d’admission",
    patientInfoTab: "Patient",
    patientNoLongerAvailable:
      "Ce patient n'est plus disponible, vous ne pouvez plus répondre à la demande de prise en charge.",
    patientUpdatedExplanation:
      "Le profil du patient a été mis à jour. Les nouvelles informations sont en vert, celles supprimées en rouge.",
    pickUpAddress: "",
    pickUpInformation: "",
    pickuptime: "",
    placeholderTimeField: "hh:mm",
    pleaseExplainOptional: "Veuillez expliquer (optionnel)",
    pleaseLogin: "Veuillez vous identifier",
    pleasePickAReason: "Veuillez sélectionner la raison",
    preferredSelection: (args: { receiverTypeSingularPreferred: string }) =>
      template(
        "Vous avez été sélectionné comme {{receiverTypeSingularPreferred}}",
        args,
      ),
    preWaitingClinicAnswer: "Écrivez ici votre message :",
    providerNotes: {
      add: "AJOUTER",
      typeHere: "Écrivez vos notes concernant le patient ici ...",
    },
    rehabSpecific: {
      acceptModalTitle: "",
      acceptRequest: "",
    },
    requestAccepted:
      'Vous avez accepté la demande. Vous pouvez la retrouver sous l\'onglet "en cours". ',
    requestAcceptedBedCapacity:
      "Vous allez accueillir le patient, votre disponibilité en lit a été diminuée de 1.",
    requestAcceptedInProgress:
      'Vous avez accepté la demande. Vous pouvez désormais la retrouver sous l\'onglet "en cours". ',
    requestAnswered: (args: { socialWorker: string }) =>
      template("Votre message a été envoyé à {{socialWorker}}.", args),
    requestAutoValidated: "",
    requestDeclinedReasonNoCapacity: (args: { date: string }) =>
      template(
        "Vous avez décliné cette demande, nous vous enverrons de nouvelles demandes à partir du {{date}}.",
        args,
      ),
    requestDeclinedReasonOther:
      'Vous avez décliné cette demande. Vous pouvez toujours les trouver sous l\'onglet "Déclinée".',
    requestDeclinedReasonOtherTab:
      'Vous avez décliné cette demande. Vous pouvez toujours les trouver sous l\'onglet "Autres".',
    requestDeclinedReasonUnattractiveLocation:
      "Vous avez décliné cette demande. Votre zone d'activité a été mise à jour.",
    reservationFeature: {
      date: "Date",
      expiredEvent: "",
      extendCTA: "",
      reservationExtended: "",
      reservedEvent: (args: { expirationDate: string }) =>
        template("Spot reserved until {{expirationDate}}", args),
      time: "Heure",
      title: "",
      tooltip: "",
    },
    reserveInfo:
      "Vous pourrez indiquer la date et l'heure d’arrivée possible du patient après avoir cliqué sur « réserver » ",
    selectDeclineReason: "Justifiez le motif ",
    selectFile: "Sélectionner le fichier",
    sendMessage: "Envoyer le message",
    testPendingRequestTitle:
      "Veuillez compléter cette demande-test. Peu importe l'option que vous choisissez, rien ne sera envoyé à quiconque.",
    testPendingRequestTitleGeriatrics: "Veuillez compléter cette demande-test",
    testRequestHeader: "Bienvenue sur le test",
    testRequestHint:
      "For real requests, you will be able exchange secured messages with the clinic before accepting or rejecting.",
    time: "",
    tooltipSentByCostpayer: "The request was sent by cost payer",
    transportDate: "",
    treatmentWithin: "",
    treatWithin: "Nous traitons les patients:",
    tryAgain:
      "Une erreur est survenue. Veuillez essayer à nouveau ou contacter Recare.",
    typeMessageHere: "Entrez votre message ici",
    typeMessageHereOptional: "Entrez votre message ici (optionnel)",
    typeMessageNotPossible: "Échange de messages impossible",
    typeOfTransfer: "Prise en charge demandée",
    undoAlert: "Etes-vous sûr(e) de vouloir annuler cette action?",
    uploadAFile: "Veuillez télécharger votre fichier :",
    uploadDelete: "Supprimer le fichier",
    uploadError: "Une erreur est survenue - veuillez essayer à nouveau.",
    uploadInProgress: "Votre fichier est en cours de téléchargement ...",
    uploadOk: "Votre fichier a bien été téléchargé.",
    validateProvider: (args: { providerName: string }) =>
      template("Vous êtes sur le point de valider {{providerName}}", args),
    validationDateChangeCare: (args: { date: string }) =>
      template(
        "Cet établissement a indiqué sa disponibilité pour le {{date}}. En validant, la date de transfert souhaitée sera actualisée.",
        args,
      ),
    validationDateChangeRehab: (args: { date: string }) =>
      template(
        "Cet établissement a indiqué sa disponibilité pour le {{date}}. En validant, la date de transfert souhaitée sera actualisée.",
        args,
      ),
    waitingClinicAnswer: "Votre message a été envoyé",
    waitingClinicAnswerCaption:
      "Si votre interrogation a été levée, vous pouvez accepter ou décliner cette demande",
    youMustLogin:
      "Vous devez vous identifier pour pouvoir lire ou envoyer des messages chiffrés.",
    youMustLoginToAccept:
      "Vous devez vous identifier pour pouvoir accepter une demande ou poser une question.",
    youPreferredSelection: {
      care: "Vous avez été sélectionné comme établissement privilégié",
      geriatrics: "Vous avez été sélectionné comme établissement privilégié",
      valdidateInfo: "",
    },
    yourComment: "Votre message",
  },
  auctionResponse: {
    accessDenied:
      "Vous n'avez pas accès à cette donnée. Veuillez contacter Recare pour plus d'information.",
    accessDeniedError:
      "Une erreur est survenue, Recare a été informé et va investiguer.",
    accessDeniedPending:
      "Actuellement, ce contenu ne peut pas être affiché. Pour voir l'historique des messages, l'une des personnes suivantes doit se connecter à Recare :",
    addressingNeeds: "Services qui correspondent aux besoins du patient ",
    answerQuestion: "Répondre",
    areYouSureNotAddInfo:
      "Êtes-vous sûr(e) de ne pas vouloir spécifier l'information ?",
    areYouSureResendRequest:
      "Êtes-vous sûr(e) de vouloir envoyer cette demande à nouveau ?",
    decline: "Rejeter cette proposition",
    declined: "La demande a été déclinée",
    declinedReasonNoCapacity: (args: { date: string }) =>
      template("Nous avons des lits disponibles à partir du {{date}}", args),
    declinedReasonNoCapacityForPatient:
      "Nous n'avons pas de place pour ce patient",
    declinedReasonNoCapacityForPatientUntilDate: (args: { date: string }) =>
      template(
        "Nous n'avons actuellement aucune disponibilité pour ce patient jusqu'au {{date}}",
        args,
      ),
    declinedReasonNoCapacityNoDate: "Pas de lit disponible pour le moment",
    declinedReasonOther: (args: { reasonDescription: string }) =>
      template("Raison du refus : {{reasonDescription}}", args),
    declinedReasonPayer: "Pas de place à cause de la situation financière",
    declinedReasonUnattractiveLocation:
      "Nous n'opérons pas là où le patient habite",
    feedbackExplainer:
      "Veuillez noter que cet avis sera uniquement transmis à Recare et ne sera pas diffusé aux personnes concernées.",
    feedbackReceived: "Merci, nous avons bien reçu votre avis !",
    fileName: "Nom du fichier",
    furtherServices: "Autres services",
    genderOfCaregiver: "Genre du personnel soignant",
    giveAccess: "Donner accès",
    giveAccessEncryptedData: "Donner accès aux données",
    giveProviderFeedback: "Donnez-nous votre avis !",
    internalNotes: "Notes internes",
    noCapacityReasons: {
      femaleRoom: "Une femme",
      maleRoom: "Un homme",
      noCapacityFor: "Pas de place pour",
      noCapacityForA: "Pas de place pour",
      reasonCancelDeclination:
        "Message automatique: La disponibilité en lit s’est réinitialisée.",
      treatmentOfPatientsWith: "",
      treatmentOfPatientsWithCarelevel: "",
      treatmentOfPatientsWithoutCarelevel: "",
      weDontTakePatientsWithCarelevel:
        "Nous ne prenons pas en charge les patients avec le GIR",
      weDontTakePatientsWithoutCarelevel:
        "Nous ne prenons pas en charge les patients sans GIR",
    },
    notEncryptedTooltip:
      "Les messages échangés avec cet établissement ne sont pas chiffrés.",
    offeringSolutions: "Vous proposez la ou les solutions suivantes :",
    otherSpecialisations: "Autres spécialisations prises en charge",
    possibleEntryDate: (args: { date: string }) =>
      template("Date d'arrivée :  {{date}}", args),
    possibleEntryDateInfo: "Date d'arrivée :  ",
    possibleEntryTime: "Heure d'arrivée :",
    preDeclined: (args: { providerName: string }) =>
      template("Vous êtes sur le point de rejeter {{providerName}}", args),
    preDeclinedDocumentationSubtitle:
      "Explication pour votre propre documentation (non partagé):",
    preDeclinedHint: "Laisser un message (optionnel)",
    preDeclinedMessageSubtitle: "Partager l’explication avec l'établissement:",
    preDeclinedPlaceholder: "Expliquez ici pourquoi (optionnel)",
    preDeclinedPlaceholderLabel: () => "",
    preDismissHint: "",
    preDismissTitle: "",
    preSendRequestHint: "",
    preSendRequestTitle: "",
    preValidate: (args: { providerName: string }) =>
      template("Vous êtes sur le point de valider {{providerName}}", args),
    preValidateHint: "Laisser un message (optionnel)",
    preWaitingProviderAnswerHint: "Entrez votre message ici",
    providerProfile: "",
    recipientHint: 'Vous écrivez à"',
    requestReservedAnotherProvider:
      "Ce patient est pour l'instant réservé par un autre établissement",
    reviewSuggestionTitle: "",
    roomType: "Type de chambre",
    send: "",
    sendEncryptedMessage: "Envoyer un message chiffré",
    sendEncryptedMessageHint:
      "Vous pouvez ici entrer des données personnelles concernant le patient (i.e nom, coordonnées, date de naissance) puisque les messages sont chiffrés.",
    sendEncryptedMessageTitle: "Envoyer un message",
    sendFaxHint: "",
    sendFaxTitle: "",
    sendFeedback: "Envoyer à Recare",
    sendFile: "Envoyer un fichier",
    sendFileTitle: "Envoyer un fichier ",
    sendMessage: "Envoyer un message",
    sendMessageHint: "Entrez votre message ici",
    sendMessageTitle:
      "Vous pouvez envoyez un message avant de valider ou rejeter",
    spokenLanguages: "Langues parlées",
    startDate: "Date d'arrivée",
    toggleShowMap: "Afficher la carte",
    toggleShowPhotos: "Afficher les photos",
    toggleShowPictures: "Afficher les photos",
    tooltipDisabledValidate: "",
    waitingClinicAnswer:
      "Des informations supplémentaires ont été demandées, veuillez-y répondre :",
    waitingForReview: "",
    warningMessage: (args: { providerName: string }) =>
      template(
        "Êtes-vous sûr(e) de vouloir donner accès aux données personnelles suivantes à {{providerName}} ?",
        args,
      ),
    writeFeedbackTitle: "Vous pouvez donner votre avis sur cet établissement :",
  },
  bToC: {
    disclaimer: "",
    emailAddress: "info@recaresolutions.com",
    emailReportProvider: "",
    filterDrawer: {
      age: "",
      ageRangePlaceholder: "",
      capacityFilterTitle: "Disponibilité",
      chipShortTermStaticCare: "",
      chipStaticCare: "",
      furtherFilters: "",
      intensiveCare: "",
      palliativeCare: "",
      psychiatricCare: "",
      searchRadius: "",
      searchRadiusPlaceholder: "",
      serviceMobile: "",
      shortTermsStaticCare: "",
      showFreeCapacity: "",
      showResults: "Afficher les résultats",
      solution: "",
      solutionMobile: "",
      solutionStatic: "",
      startDate: "",
      startDatePlaceholder: "",
      staticCare: "",
      titleFilters: "Filtres",
      treatmentLocation: "",
    },
    footer: {
      aboutRecare: "",
      imprint: "",
      privacyPolicy: "Politique de confidentialité",
      support: "",
      website: "",
    },
    getStartedButton: "",
    linkToSPN:
      "No providers with capacity? Contact your  {select:Senioren- oder Pflegestützpunkt} for further advice on how to find them!",
    navbar: {
      title: "",
    },
    noResultsWithState: (args: { state: string }) =>
      template(
        "Aucun résultat trouvé. Veuillez entrer un code postal valide ou un emplacement dans {{state}} pour votre recherche",
        args,
      ),
    onboardingScreens: {
      buttonAsap: "",
      buttonAtHome: "",
      buttonBasicCare: "",
      buttonBasicMedicalCare: "",
      buttonCareHome: "",
      buttonDailyLivingSupportServices: "",
      buttonDontKnow: "",
      buttonForMyself: "",
      buttonForSomeoneElse: "",
      buttonHelpAtHome: "",
      buttonInDays: "",
      buttonInMonths: "",
      buttonInWeeks: "",
      buttonLongterm: "",
      buttonSkip: "",
      buttonTemporary: "",
      dailyLivingSupportServices: {
        pageContent: {
          bannerInformation:
            "If so, please contact your {select:senior citizens and care support centre}, your care insurance fund or use the search function of the care insurance funds yourself.",
          bannerSubtitle: "",
          bannerTitle: "",
          bulletEight: "",
          bulletFive: "",
          bulletFour: "",
          bulletOne: "",
          bulletSeven: "",
          bulletSix: "",
          bulletThree: "",
          bulletTwo: "",
          hintText: "",
          linkAok: "",
          linkBkk: "",
          linkVdek: "vdek-Pflegelotse",
          paragraphFive:
            "For more information, please contact the {select:Lower Saxony Ministry for Social Affairs, Labour, Health and Equality}.",
          paragraphFour: "",
          paragraphOne: "",
          paragraphThree: "",
          paragraphTwo: "",
        },
        title: "",
      },
      hintZipcodeOrCity: "",
      infoMobileCare: {
        infoBasicCare: "",
        infoBasicMedicalCare: "",
        infoDailyLivingSupportServices: "",
        infoHelpAtHome: "",
        title: "",
      },
      placeholderZipcodeOrCity: "",
      subtitleWelcome: "",
      titleForWhom: "",
      titleLoadingScreen: "",
      titleTemporaryOrLongterm: "",
      titleWelcomeWeserEms: "",
      titleWhatMobileCare: "",
      titleWhenStartDate: "",
      titleWhereToSearch: "",
      titleWhichCare: "",
      yourChoice: "",
    },
    preprodLogin: "Login",
    providerDetailPage: {
      additionalMaterials: "",
      address: "",
      capacityavailable: "",
      capacityOnRequest: "",
      contactDetails: "",
      fax: "",
      hasCapacity: "",
      lastUpdated: "",
      noCapacity: "",
      noCapacityavailable: "",
      noCapacityShort: "",
      noCapacityUpdateYet: "",
      reportProvider: "",
      services: "",
      telephone: "",
      tooltipCapacity: "",
      unknownCapacity: "",
      watermarkTextExampleImage: "",
    },
    providerList: {
      away: "",
      ctaNudgeGotIt: "",
      errorBadFilters: "",
      filterChipFreeCapacity: "",
      filtersButton: "",
      hintNudge: "",
      infoNoResults: "",
      showMoreButton: "",
      solutions: {
        helpAtHome: "",
        mobileCare: "",
        shortTermStaticCare: "",
        staticCare: "",
      },
      title: "",
      titleWithCount: (args: { count: string }) =>
        template("List of providers ({{count}})", args),
    },
    reset: {
      buttonResetResults: "",
      cancelButton: "",
      resetButton: "",
      subtitleModal: "",
      titleModal: "",
    },
  },
  capacityDetailPage: {
    bedsAvailableTotal: (args: { availableBedsOfProvider: string }) =>
      template("{{availableBedsOfProvider}} lit(s) disponible(s)", args),
    unknownTooltip:
      "Cette information n’a pas été mise à jour. Cette unité reçoit néanmoins les demandes de prise en charge.",
  },
  capacitySearch: {
    clearFilters: "",
    onlyAvailableProviders: "",
    placeholderDefaultSearch: (args: {
      totalCountOfProviders: string;
      totalAmountOfBeds: string;
    }) =>
      template(
        "There are {{totalCountOfProviders}} on the platform - {{totalAmountOfBeds}} bed/s",
        args,
      ),
    placeholderModule: "",
    placeholderNoResults: "Aucun rèsultat",
    placeholderRadius: "",
    placeholderServices: "",
    placeholderZipcode: "",
    table: {
      availableBeds: (args: { availableBedsOfProvider: string }) =>
        template(
          "Lits disponibles ({select:{{availableBedsOfProvider}}})",
          args,
        ),
      bedsAvailable: (args: { availableBedsOfProvider: string }) =>
        template(
          "Lit(s) disponible(s) : {select:{{availableBedsOfProvider}}}",
          args,
        ),
      distance: "",
      lastUpdate: "Dernière mise à jour",
      module: "",
      moreDetails: "",
      provider: (args: { amountOfFilteredProviders: string }) =>
        template("Provider ({select:{{amountOfFilteredProviders}}})", args),
    },
    title: "",
    unknownBedcount: "Inconnu",
  },
  careprovider: {
    createCareprovider: "Add a careprovider in our system",
    createProviderError: "Une erreur est survenue",
    providerNotAvailable: "Établissement non disponible",
  },
  careproviderAccount: {
    centralEmail:
      "Souhaitez-vous recevoir les demandes sur une adresse email centralisée pour votre service ? Si oui, veuillez cliquer sur {select:Préférences email}.",
    changePassword: "Changer de mot de passe",
    hintPersonalNotification:
      "Souhaitez-vous recevoir les demandes à cette adresse email ? {select:Cliquez ici} pour modifier vos préférences.",
    hintWeightSetting:
      "Vous pouvez également recevoir des demandes pour des patients pesant jusqu'à 9 kg de plus que la limite de poids que vous avez choisie.",
    receiveDailyDigest: "Recevoir uniquement un résumé journalier des demandes",
    receiveRequests: "Recevoir un email pour chaque demande",
    receiveRequestsSMS: "Recevoir un SMS pour chaque demande",
    saved: "Les informations ont été sauvegardées",
    title: "Mon compte",
    titlePersonalDetails: "Informations personnelles",
  },
  careproviderApp: {
    accountMigration: {
      addMembers: "Ajouter un membre",
      addMembersBanner:
        "Invitez vos collègues pour pouvoir gérer ensemble plus facilement les demandes.",
      completeRegistration:
        "Veuillez vous rendre sur votre boîte mail et suivez les instructions pour terminer votre inscription.",
      completeRegistrationInfo:
        "Si vous n'avez rien reçu ou si l'adresse e-mail fournie est incorrecte, contactez-nous au 01 76 42 05 40.",
      createAccountBanner: "",
      createAccountModal:
        "Créez votre compte Recare gratuitement et obtenez un accès complet !",
      createAccountSubtitle: "",
      finishSignupBanner:
        "Presque terminé ! Pour terminer votre inscription, suivez les instructions dans l'e-mail. Vous n'avez pas reçu d'e-mail ? Contactez-nous au 01 76 42 05 40.",
      finishSignupModal: {
        subtitle:
          'Pour accéder aux recommandation, veuillez finaliser votre inscription en suivant les instructions du mail "Bienvenue chez Recare". Vous ne l\'avez pas reçu? Contactez-nous au 01 76 42 05 40.',
        subtitleReminder:
          "Afin d'utiliser Recare, veuillez finaliser votre inscription en suivant les instructions du mail \"Bienvenue chez Recare\". Vous ne l'avez pas reçu? Contactez-nous au 01 76 42 05 40.",
        title: "Vous y êtes presque !",
      },
      inviteContactsPopup: {
        footer:
          "Après avoir ajouté des collègues, ils recevront une invitation par e-mail pour configurer leur compte.",
        invite: "Inviter",
        skip: "Passer",
        subtitle:
          "Invitez vos collègues à gérer facilement et de manière coordonnée les demandes de transfert.",
        title: "Invitez vos collègues !",
      },
      resendSignupEmail: "Renvoyer l'e-mail",
      signUp: "S'inscrire",
      signupEmailResent: "L'e-mail a été envoyé avec succès !",
      signupPopup: {
        administration: {
          title: "Account administration:",
        },
        benefitTitle: "",
        contactUs:
          "Si vous avez des questions, n'hésitez pas à nous contacter au 01 76 42 05 40.",
        encryption: {
          text: "",
          title: "",
        },
        recommendations: {
          text: "Accédez à d'autres demandes d'admission qui correspondent à votre offre de service",
          title: "Recommandations :",
        },
        selectOrTypeEmail: "",
        upgrade: "Upgrade",
      },
    },
    archive: {
      title: "Demandes archivées",
    },
    areYouSureArchiveRequests:
      "Êtes-vous sûr(e) de vouloir archiver ces demandes ?",
    areYouSuredeclineAllRequests:
      "Êtes-vous sûr(e) de vouloir décliner ces demandes ?",
    attributed: {
      title: "Patients attribués",
    },
    bookmark: {
      removeBookmark: "Supprimer la priorité",
      removeBookmarkToast: "Cette demande n’est plus prioritaire.",
      setBookmark: "Définir comme prioritaire",
      setBookmarkToast:
        "Cette demande a été définie comme prioritaire. Elle apparait désormais en haut de la liste dans le tableau de bord.",
    },
    capacityManagement: {
      addBed: "Ajouter un lit",
      bannerCapacity: (args: { availableBedsOfProvider: string }) =>
        template(
          "Vous avez {{availableBedsOfProvider}} lit(s) disponible(s). Merci de mettre à jour vos disponibilités si nécessaire pour que le système soit efficace.",
          args,
        ),
      bedCount: (args: { numberOfBeds: string }) =>
        template("{{numberOfBeds}} lit(s) disponible(s) au total", args),
      bedCountPerSpecialization: (args: {
        amountOfBedsPerSpecialization: string;
      }) =>
        template(
          "{{amountOfBedsPerSpecialization}} lit(s) disponible(s)",
          args,
        ),
      capacityAvailable:
        "Vous avez de la disponibilité pour de nouveaux patients",
      columnNumberOfBeds: "Nombre de lits actuellement disponibles",
      columnServices: "Unité",
      declinePanelBanner:
        "Veuillez mettre vos disponibilités à 0 si vous n’avez aucune place.",
      hintTextAddAnotherBed:
        "Ajouter un nouveau lit disponible dans cette unité",
      hintTextRemoveBed: "Cliquez ici pour retirer le lit",
      infoCommentSection:
        "Aidez les établissements à en savoir plus sur vos disponibilités et modalités d'admission. Vos informations seront affichées aux établissements concernés.",
      modalBedSettingsTitle: "Veuillez préciser l'unité",
      noCapacity:
        "Vous n'avez pas de disponibilité pour de nouveaux patients (veuillez mettre à jour si vous avez des lits disponibles)",
      placeholderTextComment: "Veuillez expliquer",
      serviceIntensiveCare: "Isolation",
      serviceVentilation: "Ventilation",
      snoozeFeature: {
        countdownTimer: "Lits disponibles pendant encore",
        duration: "Durée de disponibilité",
        hours: "heure(s)",
        lastUpdatedDate: "Dernière mise à jour",
        snoozeTooltip:
          "Une fois le temps écoulé vous recevrez de nouvelles demandes. Vous pouvez mettre à jour ou ajouter/supprimer des lits.",
        toastUpdate: "Vos disponibilités ont été mises à jour.",
        validUntilDate: "Valide jusqu’au",
      },
      toastCommentSaved: "Votre information a été enregistrée",
      updateCapacity:
        "Veuillez mettre vos disponibilités à 0 si vous n’avez aucune place.",
    },
    commentOnProfile: {
      missingInformation: "Information manquante ?",
      modalCaption:
        "Vos commentaires seront envoyés directement à Recare et seront étudiés avec attention dans le but d'améliorer la plateforme.\nSi vous avez besoin de cette information pour ce patient là, veuillez écrire à l'établissement d'origine dans la messagerie à droite ou contactez-le par téléphone. ",
      modalPlaceholder: "Veuillez préciser ici",
      modalTitle: "Quelle information importante manque t-il ?",
      subtitleInfo:
        "Vos remarques seront envoyées à l’établissement adresseur. Ils devraient vous répondre via la messagerie. ",
    },
    continueToNext: "Demande suivante",
    coronaEligibilityBanner: "",
    coronaEligibilityBannerEnd: "",
    coronaEligibilityLink: "",
    dashboard: {
      all: "Tous",
      archived: "Archivées",
      archiveSelected: (args: { selectedRequests: string }) =>
        template("Archiver ({{selectedRequests}})", args),
      archiveWithCount: "Archive",
      attributed: "Attribuées",
      attributedHint:
        'Veuillez noter que les demandes attribuées depuis plus de 30 jours seront automatiquement archivées (onglet "Archivées").',
      attributedHintOtherTab:
        'Veuillez noter que les demandes attribuées depuis plus de 30 jours seront automatiquement archivées (onglet "Autres").\n',
      bcpInformation: "",
      bcptab: "",
      bulkFilter: {
        allRequests: (args: { allRequests: string }) =>
          template("Tous ({{allRequests}})", args),
        none: "Aucun",
        visibleRequests: (args: { visibleRequests: string }) =>
          template("Demandes visibles ({{visibleRequests}})", args),
      },
      capacityBanners: {
        canAccept:
          "Considérant vos disponibilités, vous pourriez accepter les patients suivants :",
        canNotAccept:
          "Considérant vos disponibilités, vous devriez décliner les demandes suivantes :",
        declineAll: (args: { qtdRequests: string }) =>
          template("Décliner ({{qtdRequests}})", args),
      },
      capacityHint: "Lits actuellement disponibles",
      capacityNotification: {
        noButton: "Non",
        subtitleAvailable:
          "Vous avez indiqué avoir des lits disponibles actuellement. Est-ce correct ?\n",
        subtitleNotAvailable:
          "Vous avez indiqué ne pas avoir de lits disponibles actuellement. Est-ce correct ? ",
        title: "Votre disponibilité en lits est-elle à jour ?",
        yesButton: "Oui",
      },
      cards: {
        attributed: "Le patient vous a été attribué",
        careLevelOnCards: "GIR :",
        declined: "Déclinée",
        lastWorkedOn: "Dernière action :",
        noRequests: "Aucune demande",
        pending: "En attente de votre réponse",
        sentByCostpayer: "Sent by cost payer",
        transitionalCare: "",
        unavailable: "Indisponible",
        youAccepted: "Vous avez accepté",
        youDeclined: "Vous avez décliné",
      },
      checkOurRecommendations: "Consultez nos recommandations",
      clearAllFilters: "Réinitialiser",
      columns: {
        patient: "Patient",
        received: "",
        referring: "Établissement d'origine",
        transfer: "Transfert",
      },
      declined: "Déclinées",
      declinedHint:
        'Veuillez noter que les demandes déclinées depuis plus de 10 jours seront automatiquement archivées (onglet "Archivées")',
      declineSelected: (args: { selectedRequests: string }) =>
        template("Décliner ({{selectedRequests}})", args),
      inProgressTab: "En cours",
      linkBcp: "Click here to open Pflegefinder Bayern in a new window",
      markAsSeen: "Marquer comme lu(s)",
      newTab: "Nouveau",
      noCapacityHint: (args: { capacityDate: string }) =>
        template(
          "Lits disponibles à nouveau à partir du {{capacityDate}}",
          args,
        ),
      noRequestFilterResults: "Aucune demande",
      noRequestsForCapacity:
        "Nous avons d'autres patients en attente que vous pourriez accepter.",
      noRequestsMessage: {
        all: "Aucune demande pour le moment",
        archived: "Aucune demande pour le moment",
        attributed: "Aucune demande pour le moment",
        clinicNameBcp: "",
        declined: "Aucune demande pour le moment.",
        direct: "",
        inProgress: "",
        new: "",
        other: "",
        pending: "Vous n'avez pas de demandes en attente pour le moment",
        unavailable: "Aucune demande pour le moment",
      },
      otherTab: "Autres",
      pending: "En attente",
      referrerDirect: "",
      toastArchiveSuccess:
        'Vous avez archiveé cette demande/s. Vous pouvez toujours les trouver sous l\'onglet "Autres".',
      toastBulkDeclineSuccess:
        "La ou les demandes ont été refusées. Vous pouvez les trouver dans l'autre onglet.",
      toastBulkSeenSuccess:
        "La ou les requêtes ont été marquées comme vues. Vous pouvez les trouver dans l'autre onglet.",
      unavailable: "Indisponibles",
      unavailableHint:
        'Veuillez noter que les demandes annulées depuis plus de 10 jours seront automatiquement archivées (onglet "Archivées").',
    },
    inactiveProfile: {
      titleBottom: "Veuillez nous appeler au 01 76 42 05 40.",
      titleTop: "n'est actuellement pas actif.",
    },
    menu: {
      archive: "Archive",
      capacityCorona: "Disponibilités",
      recommendations: "Recommandations",
      requests: "Demandes",
      search: "",
      settings: "Paramètres",
    },
    navBar: {
      dashboard: "",
      reverseSearch: "",
    },
    notifications: {
      markAllAsRead: "Tout marquer comme lu",
      noNotifications: "Aucune notification à afficher",
      notificationsDisabledHint:
        "Vous avez désactivé les notifications. Veuillez les activer dans les paramètres de votre téléphone.",
      specialization: "Spécialité\n",
      title: "Notifications récentes",
      type: "Type d'hospitalisation",
    },
    patientDataImport: {
      importModalHeadline: "Importer les données patient depuis le SIH",
    },
    patientShopping: {
      empty: "Pas de recommandations disponibles pour le moment",
      seeRecommendations: "Voir les recommandations",
    },
    requestsAfterCapacityDate: {
      plural: (args: { date: string }) =>
        template("Demandes après le  {{date}}", args),
      singular: (args: { date: string }) =>
        template("Demandes après le {{date}}", args),
    },
    requestUnavailable: "",
    requestUnavailableContinueToNext: "",
    responsiblePerson: {
      modalEncryptionWarning:
        "Attention, les messages que vous envoyez ici ne sont pas chiffrés, veuillez ne pas entrer d’informations qui permettraient d’identifier le patient.",
      modalPlaceholder: "Votre message",
      modalTitle: (args: { assignedName: string }) =>
        template(
          "Souhaitez-vous informer {{assignedName}} par email de ce changement ?",
          args,
        ),
      titleResponsiblePerson: "Personne en charge :",
      tooltipTitle: "Personne en charge :",
    },
    search: {
      noEntry: "Aucune demande pour le moment",
    },
    settings: {
      accounts: {
        activateAccount: "Activer le compte",
        activateAccountToast:
          "Vous avez modifié le statut de ce compte. Nous avons envoyé un e-mail à cette adresse avec un lien d'activation.",
        addMemberModalActiveEmailError: "Cet email est déjà actif.",
        addMemberModalCentralEmailError:
          "Cet email est déjà utilisé comme adresse email centrale.",
        addMemberModalEmailAlreadyInvited:
          "Cet email a déjà reçu une invitation. Vous pouvez en renvoyer une.",
        addMemberModalEmailDontEnterInfo:
          "L'adresse email centrale (par exemple info@ ou contact@) ne devrait pas être ajoutée comme un membre.",
        addMemberModalSend: "Envoyer",
        addMemberModalTitle: "Envoyer l'email d'invitation",
        columnMemberDetails: "Détails du compte",
        columnStatus: "Statut",
        deactivateAccount: "Désactiver le compte",
        deactivateAccountToast: "Vous venez de désactiver le compte.",
        deactivateMemberModalTitle:
          "Êtes-vous sûr(e) de vouloir desactivér cet utilisateur ?",
        deleteMemberModalTitle:
          "Êtes-vous sûr(e) de vouloir supprimer cet utilisateur ?",
        editModalTitle: "Modifier les informations associées à ce compte",
        editTooltip: "Modifier les informations associées à ce compte",
        hintTextRegisteredMembers:
          "Seuls les membres enregistrés peuvent échanger des messages sécurisés avec l'établissement adresseur. Après avoir ajouté un nouveau membre, celui-ci recevra un email pour créer son compte.",
        membersCardAddMember: "Ajouter un membre",
        membersCardCaption1:
          "Seuls les membres enregistrés peuvent échanger des messages sécurisés avec l'établissement d'origine.",
        membersCardCaption2:
          "Après avoir ajouté un nouveau membre, celui-ci recevra un email pour créer son compte.",
        membersCardEmail: "Adresse email",
        membersCardName: "Nom",
        membersCardSendAgain: "Envoyer à nouveau",
        membersCardStatus: "Statut",
        membersCardStatusActive: "Actif",
        membersCardStatusInactive: "Inactif",
        membersCardStatusSent: "Invitation envoyée",
        membersCardTitle: "Comptes enregistrés",
        receivedInvitationToast: (args: { email: string }) =>
          template(
            "{{email}} a reçu une invitation par email pour s'inscrire",
            args,
          ),
        resendActivationMail: "Renvoyer l’e-mail d’activation",
        roleClinic:
          "Afficher ce compte comme contact auprès des établissements adresseurs",
        teamTitle: "Comptes enregistrés",
        title: "Comptes",
        youMarker: "(Vous)",
      },
      browserNotifications: {
        checkboxInfo:
          "Accepter pour les demandes, messages et attributions patient ",
        completedManualSteps:
          "Notifications du navigateur accordées et souscrites à Recare",
        deviceStatusComplete:
          "Toutes les étapes sont terminées, vous devriez maintenant recevoir des notifications Recare sur votre appareil",
        explainer:
          "Pour recevoir des notifications de navigateur de Recare, veuillez suivre les étapes ci-dessous",
        notificationsDeactivatedExplanatoryText:
          "Afin de ne pas passer à côté de transferts de patients, veuillez s’il-vous-plait activer les notifications Recare (Paramètres → Confidentialité et sécurité → Notifications) ou contactez-nous au {select:01.76.42.05.40} pour que nous vous aidions. ",
        notificationsDeactivatedInfo: "Actuellement bloqué par le navigateur ",
        pleaseUseSafari:
          "Veuillez utiliser Safari pour recevoir les notifications du navigateur sous MacOS",
        previouslyEnabled:
          "Si vous avez déjà refusé l'autorisation de notification pour cette application, autoriser les autorisations dans vos paramètres, fermer et rouvrir l'application devrait réactiver les notifications.",
        pushManagerErrorStatus:
          "Pas d'abonnement push, fermez et rouvrez l'application, à défaut, veuillez contacter recare avec vos informations de débogage.",
        stepOne: "Étape 1, autorisez les notifications du navigateur",
        stepTwo: "Étape 2, autoriser les notifications de recare",
        title: "Notifications navigateur",
      },
      capacity: {
        acceptNewPatientsWhen:
          "Nous pouvons accepter de nouveaux patients à partir du :  ",
        banner: {
          button: "Oui, nous avons des capacités",
          confirmation: "Merci beaucoup pour la confirmation.",
          subtitle:
            "Actuellement, vous avez indiqué des capacités libres. Est-ce toujours correct?",
          title:
            "Cela fait deux semaines que vous avez mis à jour vos données de capacité pour la dernière fois.",
        },
        calendarWarning: "Veuillez choisir une date dans les 60 jours.",
        capacityAvailable: "Nous avons des lits disponibles",
        capacityTitle: "Nos disponibilités",
        dateInFuture: "La date et l'heure doivent être dans le futur",
        exceptions: "Exceptions :",
        explanation:
          "Si vous êtes disponible, notre plate-forme affichera vos services aux cliniques et, le cas échéant, aux patients potentiels ; si vous n'indiquez pas de capacité, vous ne serez pas affiché. Veuillez tenir vos données de capacité à jour afin de permettre une utilisation efficace de la plateforme Recare, tant pour vous que pour les cliniques qui collaborent avec vous.",
        hintCapacityAvailable:
          "Vous recevez actuellement de nouvelles demandes de patients en raison de votre disponibilité.",
        hintCapacityAvailableExcept:
          "Vous recevez actuellement de nouvelles demandes de patients qui ne répondent pas aux exceptions.",
        hintCapacityNotAvailable:
          "Vous ne recevez actuellement pas de nouvelles demandes de patients.",
        instructions:
          "Pour modifier vos données de capacité, veuillez cliquer sur l'une des options ci-dessous: ",
        lastUpdated: (args: { date: string }) =>
          template("Dernière mise à jour: {{date}}", args),
        saveButton: "Enregistrer les modifications",
        tileCapacity: {
          text: "Vous recevrez ainsi des demandes correspondant à votre gamme de prestations. ",
          title: "Nous avons de la capacité",
        },
        tileNoCapacity: {
          dateInformation:
            "Nous pourrons accueillir de nouveaux patients à partir de",
          errorDateInPast:
            "Veuillez saisir une date valide dans les 60 prochains jours.",
          errorTimeInPast: "Veuillez saisir une heure valide.",
          errorTimeInPastDateOkay:
            "Veuillez saisir une date valide dans les 60 prochains jours ou changer l'heure pour une date ultérieure.",
          text: "Indiquez la date jusqu'à laquelle vous ne souhaitez pas recevoir de nouvelles demandes. Pour les jours suivants, vous continuerez à recevoir des demandes.",
          title: "Nous n'avons pas la capacité",
        },
        title: "Disponibilités",
        titleNew: "Réglages de la capacité",
        toastMessageHasCapacitySaved:
          "L'information a été sauvegardée avec succès - vous avez la capacité",
        toastMessageHasNoCapacitySaved:
          "L'information a été sauvegardée avec succès - vous n'avez pas de capacité",
        toggleNoYes: "Non / Oui",
      },
      dPA: {
        downloadContract: "Télécharger le contrat",
        fileName: "Contrat_traitement_donnees.pdf",
        hintFileRestriction:
          'Un seul fichier est autorisé. Celui-ci ne peut être modifié après avoir cliqué sur "confirmer". ',
        hintHowTo:
          "Veuillez télécharger et imprimer le contrat sur le traitement des données, puis nous le renvoyer une fois signé. ",
        modalTitleSure: "Êtes-vous sûr(e) de vouloir supprimer ce fichier ? ",
        signScan: "Signer et scanner",
        titleDPA: "Contrat sur le traitement des données",
        titleOtherDocuments: "Autres documents",
        uploadContract: "Télécharger à nouveau sur Recare ",
      },
      email: {
        addressAlreadyLinked: "Cet email est déjà attaché à un compte.",
        advanced: "Avancé",
        centralAddress: "Adresse email centrale pour les demandes d'admission",
        centralAddressHint:
          "Si vous avez une adresse email centrale pour les réservations (comme info@ ou contact@), merci de l'activer ici.\n Attention : cette adresse est seulement une adresse de réception des demandes et ne peut être un compte personnel.",
        centralAddressToast: (args: { email: string }) =>
          template("Vous recevrez les demandes sur {{email}}", args),
        companyInbox: "Boite e-mail générique",
        companyMailAddress: (args: { companyMail: string }) =>
          template("Adresse email de l'unité {{companyMail}}", args),
        deactivateAll: "Je ne veux pas du tout recevoir d'e-mails",
        hideAdvancedSettings: "Cacher les paramètres avancés",
        hintSetUpCompanyMail:
          "{select:Cliquez ici} afin de configurer une adresse e-mail centrale pour votre service.",
        modalDeactivateEmailSubtitle:
          "Cela pourrait réduire considérablement l'efficacité de la communication entre vous  et les établissements adresseurs. Le seul moyen de prendre connaissance des nouvelles demandes sera de vous rendre sur l'application.",
        modalDeactivateMailTitle:
          "Êtes-vous sûr(e) de ne plus vouloir recevoir d'e-mails?",
        noCentralAddress: "Nous n'avons pas d'adresse email centrale",
        notificationsTitle: "Recevoir les notifications sur",
        personalInbox: "Boîte e-mail personnelle",
        personalMailaddress: (args: { userAddress: string }) =>
          template("Adresse email personnelle {{userAddress}}", args),
        receiveOnePerDay:
          "Je veux recevoir uniquement un résumé journalier des demandes",
        saveAndSend: "Sauvegarder et envoyer l’invitation",
        showAdvancedSettings: "Afficher les paramètres avancés",
        title: "Préférences email",
        toggleOffOn: "Désactivé / Activé",
        withCentralAddress:
          "Nous avons une adresse email centralisée et souhaitons y recevoir les demandes",
      },
      extensions: {
        connected: "",
        connectionStatusActive: "",
        connectionStatusInactive: "",
        connext: {
          additionalInfo: "",
          title: "Connext Vivendi",
        },
        ctaConnect: "",
        infoExtensions: "",
        korian: {
          additionalInfo: "",
          title: "",
        },
        title: "",
      },
      facility: {
        additionalCosts: "Nous facturons certains coûts supplémentaires",
        additionalCostsPlaceholder: "Veuillez préciser",
        availableEquipmentTitle: "",
        cardiovascularExplorationTitle: "Explorations cardiovasculaires",
        coronaService:
          "Nous prenons en charge les patients Coronavirus (SARS-CoV-2)",
        coronaTitle: "Coronavirus (SARS-CoV-2)",
        ctaAddAnotherDay: "",
        digestiveEndoscopies: "Endoscopies digestives",
        endTime: "à",
        furtherInformationCardTitle: "Autres informations",
        healthInsuranceFrance: "Sécurité sociale des patients",
        healthInsuranceMandatoryFrance: "Obligatoire",
        healthInsuranceOptionalFrance: "Optionnelle",
        hintCompanyMailNotification:
          "Souhaitez-vous recevoir les demandes à cette adresse email ? {select:Cliquez ici} pour modifier vos préférences.",
        hintWorkingHours:
          "Si vous ne précisez pas d’heures spécifiques, nous vous enverrons les demandes entre 7h et 17h. Vos heures de réception sont affichées côté adresseur.",
        imageryTitle: "Imagerie",
        institutionsNotPartOfSearch:
          "Pour information, pour le moment les patients ne peuvent demander expressément les types de soins suivants :",
        notSetYet: "non renseigné",
        organization: "Organisation",
        patientCharacteristicsCardTitle: "Patients pris en charge",
        rangeServicesCardTitle: "Description du service",
        receiverPartOfOrganization: (args: { organizationName: string }) =>
          template(
            "Vous faites partie de l'organisation {select:{{organizationName}}}",
            args,
          ),
        receiversOfSameOrganization:
          "Les établissements suivants font partie de la même organisation :",
        socialWorkerOnSite: "Présence d'un(e) assistant(e) social(e)",
        startTime: "De",
        technicalEquipmentTitle:
          "Plateau technique disponible sur la structure",
        title: "Services",
        titleCompanyInformation: "Informations sur votre service",
        titleWorkingHours: "Heures de réception des demandes",
        updateInformationModalTitle: "Mettre à jour vos informations",
      },
      language: {
        en: "Anglais",
        fr: "Français",
      },
      payers: {
        explanationSubtitle: "",
        infofieldRehabShortInsurances: "",
        titlePayers: "",
      },
      relatedCareseekers: {
        activeSince: "Actif depuis",
        distanceToClinic: (args: { distance: string }) =>
          template("à {{distance}}", args),
        name: "Nom",
        sectionTitle: "Établissements adresseurs à proximité",
        soonAvailable: "Bientôt disponible",
        title: "Établissements adresseurs",
      },
      reversePatientSearch: {
        addSearch: "",
        betaBanner: "",
        chipBasedOnSearch: "",
        deleteSearch: "Supprimer",
        editingTitle: "",
        editSearch: "Modifier",
        filtersTitle: "",
        hintNoCapacity: "",
        hintTextCapacityMode: "",
        hintTextInformation: "",
        loadingError: "",
        pauseSearch: "",
        searchAdded: "",
        searchDeleted: "",
        searchEdited: "",
        searchPaused: "",
        searchStarted: "",
        searchStatus: "",
        searchStatusActive: "",
        searchStatusPaused: "",
        showResults: "",
        startSearch: "",
        subtitle: "",
        subtitleInformationText: "",
        subtitleSearchesAvailable: "",
        subtitleSearchesNotAvailable: "",
        title: "",
        toastError: "",
        toastErrorEmptyFilters: "",
        toastShowResultsError: "",
        toastShowResultsSuccess: "",
      },
      searchCareprovider: "Rechercher un service",
      sidebar: {
        ariaLabelChangePassword: "",
        ariaLabelLogout: "",
        ariaLabelReportContent: "",
        avContract: "Contrat de traitement des données",
        avContractAddendum: "",
        companyProfile: "Profil du service\n",
        faq: "FAQ",
        findPatients: "",
        legal: "Légal",
        myAccount: "Mon compte",
        notifications: "Notifications",
        ourCapacity: "Lits disponibles",
        ourServices: "",
        partnerClinics: "Établissements adresseurs",
        payers: "",
        personalDetails: "",
        privacyPolicy: "Politique de confidentialité",
        reportContent: "",
        search: "",
        services: "Services",
        team: "Équipe",
        teamManagement: "",
        title: "",
      },
      switchCareprovider: "Établissement receveur",
      uploadFiles: {
        confirmImageRights:
          "Je confirme que l'image peut être téléchargée et utilisée par moi et qu'elle est exempte de droits contraires de tiers et qu'elle n'enfreint pas les conditions d'utilisation. En cas d'infraction, l'image peut être supprimée.",
        errorFileNameExistsAlready:
          "Le nom du fichier existe déjà - veuillez changer le nom du fichier et l’importer à nouveau",
        errorMaxFiles: (args: { maxCount: string }) =>
          template(
            "Vous ne pouvez importer plus que {{maxCount}} fichiers.",
            args,
          ),
        errorMaximumFiles: "Vous ne pouvez importer plus que 5 fichiers.",
        errorPermittedFormat: "Ce format de fichier n’est pas supporté.",
        imageDeletionDisclaimer:
          "Recare se réserve le droit de supprimer à tout moment les images qui violent les droits d'utilisation ou d'autres droits.",
        images: {
          imageMaxResolution: "résolution maximale: 4000 x 3000",
          imagesMaxSize: "taille d’image maximale : 15 MB",
          permittedFormat: "formats autorisés : .png, .jpg, .jpeg",
          subtitle:
            "Veuillez compléter votre profil par des photos de votre établissement afin de donner une impression visuelle de votre gamme de services. Ces images seront affichées aux personnes à la recherche de soins.",
          title: "Photos (max. 5 photos)",
          uploadImages: "Importer des photos",
        },
        marketing: {
          documentsMaxSize: "taille maximale du fichier : 15 MB",
          infoUploadFiles:
            "Importez des photos et documents qui présentent votre établissement. Ces documents seront visibles par les établissements adresseurs, et seront affichés en fonction de l’ordre de l’import.",
          permittedFormat: "formats autorisés : .pdf, .doc, .docx",
          title: "Documents (max. 5 fichiers) ",
          uploadFiles: "Importer des fichiers",
        },
        orderSuccessfullyChanged: "L'ordre a été modifié avec succès.",
        toastConfirmCheckbox:
          "Veuillez confirmer la case à cocher ci-dessus avant de télécharger.",
        toastFileDeletedSuccess: "Votre fichier a été supprimé.",
      },
    },
    showElectivePatient: "",
    status: {
      requestSent: "Répondre",
    },
    topLevelNavBar: {
      faq: "",
      newsletter: "",
    },
    warningAcceptedRequestsSelected:
      "Vous avez accepté certaines des demandes sélectionnées.",
  },
  careproviderCard: {
    age: "Âge du patient",
    contactInformation: "Coordonnées",
    costCoverage: {
      applied: "Demandé",
      approved: "",
      declined: "Refusé",
      declineHint: "(ne sera pas partagé)",
      declineReason: "Motif de refus",
      noAppliedYet: "Pas encore demandé",
      sendNotification: "Envoyer une notification à la clinique",
      title: "État de la couverture",
      updateStatus: "Veuillez mettre à jour le statut",
    },
    distanceFromPatient: (args: { distance: string }) =>
      template("à {{distance}} du patient", args),
    healthInsurance: "Assurance maladie",
    otherServices:
      "En plus de cela, nous offrons également les services suivants :",
    ourServices: "Notre offre de services",
    patientCharacteristics: "Patients pris en charge",
    patientServices: "En réponse à la demande du patient, nous offrons :",
    weight: "Poids",
  },
  careproviderProfile: {
    acceptedWeight: "Poids des patients",
    addMoreLanguages: "Langues parlées par le personnel",
    capacity: {
      declineExpirationDate: "Sélectionner la date",
      declineExpirationDateInfo:
        "Veuillez noter que le fait de ne pas sélectionner de date entraînera la réception d'autres demandes de patients présentant des caractéristiques sélectionnées.",
      declineExpirationOptional: "Sélectionner la date (opt.)",
      noCapacityForGivenReasonUntil: (args: {
        reasons: string;
        date: string;
      }) => template("{{reasons}} jusqu'au {{date}}", args),
      weCouldReceiveNewPatients: "Nous pouvons recevoir de nouveaux patients",
      weCurrentlyHaveNoCapacity:
        "Nous n'avons pas de disponibilité actuellement",
      youAreNotTakingPatients: "Vous n'avez pas de disponibilité actuellement",
      youAreReceivingNewRequests:
        "Vous recevez de nouvelles demandes par email",
      youChangedYourCapacitySettings: "Vous avez modifié vos disponibilités.",
      youCouldReceiveNewPatients: "Vous pouvez recevoir de nouveaux patients",
      youCouldReceiveNewPatientsExceptFor:
        "Nous pouvons recevoir de nouveaux patients à l'exception de: ",
      youHavePendingRequests: {
        plural: (args: { number: string }) =>
          template("Vous avez {select:{{number}} demandes en attente}", args),
        singular: (args: { number: string }) =>
          template("Vous avez {select:{{number}} demande en attente}", args),
      },
      youHavePendingRequestsBefore: {
        plural: (args: { number: string; date: string }) =>
          template(
            "Vous avez {select:{{number}} demandes en attente} qui commencent avant le {{date}}",
            args,
          ),
        singular: (args: { number: string; date: string }) =>
          template(
            "Vous avez {select:{{number}} demande en attente} qui commence avant le {{date}}",
            args,
          ),
      },
      yourCapacity: "Vos disponibilités",
      youWillNotReceiveRequestsThatContain:
        "Vous ne recevrez pas de nouvelles demandes qui contiennent",
      youWillNotReceiveRequestsTill: (args: { date: string }) =>
        template(
          "Vous ne recevrez pas de nouvelles demandes avant le {{date}}",
          args,
        ),
      youWillNotReceiveRequestsWithFollowingCharacteristics:
        "Vous ne recevrez pas de demandes de patients présentant les caractéristiques suivantes jusqu'à la date choisie : ",
    },
    clinicContactInformation: (args: { careseekerName: string }) =>
      template("Contact de {{careseekerName}}", args),
    companyName: {
      care: "Nom",
      geriatrics: "Nom",
    },
    contact: "Contact",
    contactDetails: "Coordonnées",
    contactPersonInHospital: "Personne en charge",
    contactSendEmails:
      "Veuillez cocher la case si vous souhaitez recevoir des demandes par email. Tous les contacts seront affichés à l'établissement d'origine.",
    duplicateIkNumber: "",
    errorMissingMandatoryFields:
      "Veuillez remplir tous les champs obligatoires.",
    extraNotes: {
      hint: "Notez vos remarques…",
      info: "Vos remarques ne seront pas transmises au receveur. Vous pouvez rajouter ici des informations manquantes telles que le portable du médecin, la disponibilité de l'établissement etc. Vous pourrez vous référer à vos notes à tout moment.",
      title: "Ajouter d’autres informations sur le receveur",
      titleEdit: "Modifier les informations sur le receveur",
    },
    generalInformation: "Informations générales",
    healthInsurance: "Assurance santé des patients",
    hintAdditionalInformation:
      "Veuillez compléter votre profil par une description de votre établissement et de l'éventail de vos prestations. Cette information sera affichée aux personnes à la recherche de soins.",
    ikNumberAlreadyTaken: (args: { ikNumber: string }) =>
      template("IK number {{ikNumber}} is not available anymore", args),
    ikNumberEmpty: "",
    ikNumberErrorDigits: "",
    ikNumberHint: "",
    ikNumberIntegration: "",
    ikNumberLabel: "",
    ikNumberSuccess: "",
    isNewCareProvider: "NOUVEAU chez Recare",
    languagesSpoken: "Langues parlées",
    letUsKnowAnyRemarks: "Avez-vous des remarques particulières ?",
    loadingError: "Impossible de charger la page",
    loadingErrorRedacted: "",
    locationOfPatient: "Localisation des patients",
    max: "Maximum",
    min: "Minimum",
    myAccount: "Mon compte",
    otherRemarks: "Informations supplémentaires sur votre unité ",
    patientTypeLabel: "Type de patient",
    placeholderAdditionalInformation:
      "i.e. nombre de lits dédiés à une sous-spécialité, prise en charge spécifique etc.",
    prerequisites:
      "Est-ce un prérequis pour prendre en charge le patient ? Si oui, vous recevrez uniquement les patients qui ont ce besoin.",
    profileCardTitle:
      "Cette information est nécessaire afin d'être contacté par les établissements et Recare.",
    providerNotAlreadyAdded: "Fournisseur déjà ajouté à la recherche",
    receiverName: (args: { receiverTypeSingular: string }) =>
      template("Nom de l'{{receiverTypeSingular}}", args),
    servicesPsychiatricSpecialisation: "Spécialisation en psychiatrie",
    sexOfNurses: "Genre du personnel soignant",
    toTreatPatientRadius:
      "Prendre en charge les patients dans un rayon spécifique (km)",
    toTreatPatientZipcodes:
      "Prendre en charge les patients dans des codes postaux spécifiques",
    weTreatPatientFederalStateWide: "",
    weTreatPatientGermanyWide: "",
    weTreatPatientRadius: "dans une distance de (km)",
    weTreatPatientZipcodes: "au sein des codes postaux suivants",
    weTreatPatientZipcodesStartingWith: "",
    years: "ans",
    yourProfile: "Informations générales",
    yourProfileLink: "Votre profil",
  },
  careseeker: {
    billingEmail: "",
    billingEndDate: "End of billed phase",
    billingName: "Billing name",
    billingPeriod: "Billing period",
    billingStartDate: "Beginning of billed phase",
    billingTitle: "Billing information",
    checkProtectedDescription: "...",
    checkProtectedTitle: "...",
    contractInformation: "Information contrat",
    contractNotes: "Notes sur le contrat",
    contractStartDate: "Date de début contrat",
    customerId: "Customer ID",
    extensionContractLink: "Extension contract",
    financeOffice: "Finance office address",
    financeOfficeName: "Finance office name",
    flatRate: "Cout mensuel",
    generateInvoice: "Generate Invoice",
    generateInvoiceForClinic: (args: { careseekerName: string }) =>
      template("Generate invoice for {{careseekerName}}", args),
    informationCostPayerValidation: "...",
    invoiceComment: "Further invoice information",
    invoiceDelivery: "Invoice delivery",
    invoicesTitle: "Invoices",
    invoiceType: "Type",
    invoiceViaEmail: "",
    invoiceViaPost: "via post",
    linkToPdf: "Link to PDF",
    longMobileAsssessment: "",
    maintenancePAge:
      "L'application est actuellement en cours de maintenance, veuillez réessayer ultérieurement.",
    paymentTitle: "Payment information",
    pricePerPatient: "",
    protectedCheckQuestion: "...",
    protectedPatients: "",
    remoteWorkshopFee: "Remote workshop fee",
    serviceDate: "Service date",
    setupFee: "Setup fee",
    shortMobileAssessment: "Courte évaluation mobile",
    signedContractReceived: "Contrat signé reçu le ",
    totalAmount: "Total (€)",
    trialEndDate: "End of test phase",
    trialStartDate: "Beginning of test phase",
    workshopFee: "Workshop fee",
  },
  coronaTesting: {
    additionalComments: "Commentaires additionnels sur le covid",
    additionalCommentsNo: "Commentaires additionnels",
    additionalCommentsYes: "Commentaires additionnels",
    auctionLabel: "Coronavirus (SARS-CoV-2)",
    coronaBannerAddMobile:
      "En raison de la propagation actuelle du virus corona, la plupart des centres de soins de courte durée ou hospitalisés ne sont plus disponibles. Nous vous recommandons donc fortement de rechercher en parallèle une structure ambulatoire afin de freiner l'expansion. {select:cliquez ici} pour ajouter des services de consultations externes à votre recherche.",
    hasBeenVaccinated: "Le patient a été vacciné",
    hasPatientBeenTested: "PCR Covid réalisé dans les dernières 48h ?",
    negative: "Réalisé, négatif",
    patientNotTested: "Le patient n'a pas été testé",
    pending: "En attente du résultat",
    pleaseSpecifyVaccination: "Veuillez spécifier",
    positiveCured: "Réalisé, autre",
    positiveUncured: "Réalisé, positif",
    results: "Résultats",
    testResultLabel: "Résultat du test",
    unknown: "Ne sait pas",
    vaccinationUnknown: "Le schéma vaccinal n'a pas été renseigné",
  },
  crisp: {
    support: "Aide",
  },
  csat: {
    csatCommentPlaceholder: "Votre commentaire",
    csatCommentQuestion: "Comment pouvons-nous nous améliorer ? ",
    csatQuestion: "Êtes-vous satisfait(e) de Recare ?",
    csatThanksSubtitle:
      "Vos commentaires sont très importants pour nous, nous développons la plateforme principalement grâce à vos retours.",
    csatThanksTitle: "Merci pour votre commentaire !",
    dissatisfied: "",
    neutral: "Neutre",
    satisfied: "",
    scaleNotAtAll: "Pas du tout",
    scaleVeryMuch: "Très satisfait",
    veryDissatisfied: "",
    verySatisfied: "",
  },
  faxRequest: {
    coverPage: {
      dearSirOrMadam: "",
      disclaimer: {
        explainationLineTwo: "",
        explanation:
          "{select:register for free} {select:www.recaresolutions.com} ",
        explanationLineOne: "",
        title: "",
      },
      hospitalInformation: {
        contactPerson: "",
        email: "",
        telephoneNumber: "",
        title: "",
      },
      infoBodyTextRequest: "",
      infoBodyTextValidation: "",
      infoTextPatientRequest:
        "If you have capacity to take in the patient or need more information, please contact the contact person of the hospital and state the Recare-identification number {select:patientId}.",
      infoTextPatientRequestLineOne:
        "You have received a patient request. Please find the pseudomized patient profile attached.",
      infoTextPatientValidation:
        "For more information, please contact the contact person of the hospital and state the Recare-identification number {select:patientId}.",
      infoTextPatientValidationLineOne:
        "A patient was attributed to you. Please find the pseudomized patient profile attached.",
      subtitleHospitalName: (args: { senderName: string }) =>
        template("from {{senderName}}", args),
      titlePatientRequest: "",
      titlePatientValidation: "",
    },
    faxEvents: {
      faxBounced: "Le fax n'a pas été remis avec succès",
      faxDelivered: (args: { receivername: string }) =>
        template("Télécopie envoyée avec succès à {{receivername}}", args),
      faxEventTitle: "",
      faxNumberNotAvailable: (args: { receivername: string }) =>
        template(
          "Numéro de télécopie de {{receivername}} non disponible",
          args,
        ),
      faxSent: (args: { receivername: string }) =>
        template("Fax envoyé à {{receivername}}", args),
    },
    faxIntroBodyOne: (args: { patientId: string }) =>
      template(
        "la clinique cherche une prise en charge adaptée pour un patient nécessitant un suivi - et vous demande de manière ciblée si des capacités sont disponibles. Veuillez donner votre réponse à la clinique en mentionnant le numéro d'identification du patient {{patientId}} de la plate-forme Recare.",
        args,
      ),
    faxIntroBodyTwo:
      "Recevez à l'avenir davantage de demandes de cliniques gratuites et correspondant à votre gamme de prestations grâce à la plate-forme Recare. Inscrivez-vous directement :\nhttps://recaresolutions.com/nachversorger-registrierung/.",
    faxIntroSalutation: "Mesdames et Messieurs",
    fromSender: (args: { clinicName: string }) =>
      template("de {{clinicName}}", args),
    optOutOfReceivingFax: "",
    optOutOfSendingFax: "",
    recareId: (args: { patientId: string }) =>
      template("Recare-ID: {{patientId}}", args),
    titleNew: "Demande pour un(e) patient(e) ayant besoin d'un suivi",
  },
  fileHisUploadComponent: {
    title:
      "Veuillez sélectionner les fichiers que vous souhaitez télécharger et partager",
  },
  fileSection: {
    cannotDelete:
      "Ce fichier ne peut pas être supprimé car il n'a pas été téléchargé par vous.",
    dragAndDrop: {
      disclaimerFileSize: "Taille maximale du fichier : 20 Mo",
      disclaimerFileType: "Types de fichiers pris en charge : PDF",
      instructionDragAndDrop: "Faites glisser et déposez le fichier ici",
      selectBetweenDragAndDropOptions: "Ou",
      title: "Télécharger depuis l'ordinateur",
    },
    eligibilityPage: {
      sendFileLabel: "",
      sendFileToolTip: "",
    },
    events: {
      documentIsBeingUploaded:
        "Votre fichier est en cours de téléchargement...",
      fileWasSharedWith: (args: { fileName: string }) =>
        template("{{fileName}} a été partagé avec :", args),
    },
    explanationIcon:
      "Ce symbole indique que le fichier est chiffré de bout en bout.",
    explanationText:
      'Téléchargez ici des fichiers d\'un maximum de 20 Mo en cliquant sur le bouton bleu "Télécharger". \nPour partager le fichier avec tous les receveurs potentiels simultanément, cliquez sur les trois points situés à droite du fichier et sélectionnez l\'option "Partager", puis "Tous les receveurs potentiels". \nVous pouvez également voir ici tous les fichiers reçus de la part des receveurs.',
    fileDeleteModal: {
      title: "Souhaitez-vous vraiment supprimer ce fichier ? ",
      toastSuccess: "Fichier supprimé avec succès",
    },
    fileFaxComponent: "",
    fileIntegration: {
      fileNotYetImported: "Pas encore importé",
      import: "Import du DPI",
      importedFromHis: "Importé depuis le DPI",
      title:
        "Veuillez sélectionner le(s) fichier(s) que vous souhaitez importer",
      toastSuccess:
        'Import du/des fichier(s) réussi.  Vous le(s) trouverez dans l\'onglet "fichiers" après avoir terminé la création du patient. ',
    },
    fileListComponent: {
      category: "Catégorie",
      dateSourceColumn: "",
      descriptionFilesAvailable:
        "Ces fichiers peuvent être partagés avec les receveurs",
      descriptionFilesUnsupported:
        "Ces fichiers ne peuvent pas être téléchargés car le format n'est pas conforme ",
      descriptionNewFileVersionAvailable: "",
      employeeColumn: "Le collaborateur",
      fileErrorReason: "Motif de l'erreur",
      fileName: "Nom du fichier",
      placeholderNothingReceived: "Vous n'avez pas encore reçu de fichiers.",
      placeholderNothingSent: "Vous n'avez pas encore envoyé de fichiers.",
      placeholderNothingUploaded:
        "Vous n'avez pas encore téléchargé de fichiers.",
      reason: "Motif",
      reloadButton: "",
      shareDate: "Date",
      sharedBy: "",
      sharedWith: "Partagé avec",
      shareOptions: {
        allReceivers: "Tous les receveurs potentiels",
        allReceiversHospital: "Tous les receveurs + hôpital",
        allReceiversInsurance: "Tous les récepteurs + payeur de coûts",
        clinic: "Hôpital",
        insurance: "Payeur de coûts",
        noOne: "Aucun receveur",
        selectedReceivers: "Receveurs sélectionnés ",
        selectedReceiversHospital: "Sélectionnés les récepteurs +hôpital",
        selectedReceiversInsurance:
          "Sélectionnés les récepteurs + payeur de coûts",
      },
      source: "Par",
      sourceComputerInfo: "",
      sourceHIS: "-",
      sourceHISInfo: "",
      status: "Statut",
      statusFormatError: "Erreur de format",
      statusSizeError: "Taille du fichier",
      titleFileReceived: "",
      titleFilesAvailable: "Fichiers téléchargés",
      titleFilesReceived: "Fichiers reçus ",
      titleFilesSent: "Fichiers envoyés",
      titleFilesUnavailable: "Fichiers non supportés ",
      titleFilesUnsupported: "Fichiers non supportés",
      titleFilesUploaded: "Fichier(s) téléchargé(s)",
      uploadDate: "Téléchargé le",
      uploadedBy: "Téléchargé par",
      validation: {
        tooltipHisFileNoDeletion: "",
      },
    },
    fileShareComponent: {
      shareOptions: {
        allPotentialReceivers: "Tous les receveurs potentiels",
        noone: "Aucun receveur",
        noResults:
          "Aucune demande n'a encore été envoyée - veuillez sélectionner une autre option pour l'instant",
        selectedReceivers: "Les receveurs sélectionnés",
        shareOptionsLegalDisclaimer:
          "Les fichiers peuvent contenir des données personnelles qui n'ont pas été partagées via la recherche Recare. Veuillez vous assurer, avant de partager le document, que tous les receveurs potentiels sont autorisés à y avoir accès. Une fois qu'un receveur a été attribué au patient, les fichiers ne pourront plus être téléchargés par les autres receveurs. Le nom du fichier sera également chiffré.",
      },
      shareWithCostpayerTitle: "",
      shareWithHospitalTitle: "",
      shareWithReceiverTitle: "Avec qui souhaitez-vous partager ce fichier ?",
      successPage: {
        explanationText: "Vous avez partagé vos fichiers avec :",
        title: "Partage réussi !",
      },
      toastSuccess:
        "La modification de votre fichier a été enregistrée avec succès",
    },
    fileUploadComponent: {
      descriptionFilesReceived: "",
      encryptedFieldSecure: "Ces informations sont cryptées",
      fileCategory: "Sélectionner la catégorie",
      fileName: "Nom du fichier",
      infoSectionOneText:
        "Vous pouvez utiliser cette fonctionnalité pour partager simultanément des fichiers avec tous les receveurs potentiels.",
      infoSectionOneTitle: "Partager avec tous les receveurs potentiels ",
      infoSectionThreeText:
        "Le fichier téléchargé peut être supprimé à tout moment en cliquant sur les trois points situés à côté de celui-ci. Le fichier sera automatiquement supprimé pour tous les destinataires du fichier. Vous pouvez également modifier le mode de partage en cliquant sur les trois points situés à côté du fichier.",
      infoSectionThreeTitle:
        "Suppression de fichiers et modification des droits d'accès",
      infoSectionTwoText:
        'Si vous souhaitez échanger des fichiers avec qu\'un seul receveur, vous pouvez directement utiliser l\'espace de messagerie. Vous pouvez y accéder en allant dans l\'onglet "Recherche" puis en cliquant sur le receveur souhaité. Les fichiers téléchargés à partir de la messagerie sont enregistrés ici comme étant partagés avec les "receveurs sélectionnés".',
      infoSectionTwoTitle: "Partager avec un seul receveur",
      selectFile: "Télécharger",
      titleModal:
        "Veuillez vérifier le nom du fichier puis sélectionner une catégorie",
      toastSuccess: "Votre fichier a bien été téléchargé.",
      uploadFile: "Télécharger",
    },
    fileUploadStates: {
      cancelled: (args: { numberOfFiles: string }) =>
        template(
          "Le téléchargement de {{numberOfFiles}} fichier(s) a été annulé\n",
          args,
        ),
      cancelUpload: (args: { numberOfFiles: string }) =>
        template(
          " Annuler le téléchargement de {{numberOfFiles}} fichier(s)",
          args,
        ),
      descriptionFilesAvailable: "",
      error: (args: { numberOfFiles: string }) =>
        template("Échec des téléchargements - {{numberOfFiles}} ", args),
      errorRetryPrompt: "Le téléchargement a échoué",
      loading: (args: { numberOfFiles: string }) =>
        template(
          "Téléchargement de {{numberOfFiles}} fichier(s) en cours",
          args,
        ),
      statusNotShared: "Non partagé",
      statusShared: "Partagé",
      success: (args: { numberOfFiles: string }) =>
        template("{{numberOfFiles}} téléchargé(s) avec succès  ", args),
      uploadStopped: "Téléchargement annulé",
    },
    headline: "Aperçu des fichiers",
    seeDocument: "",
    tabTitle: "Fichiers",
    toastError:
      "Une erreur est survenue. Veuillez essayer à nouveau ou contacter Recare.",
    toastErrorTitle: "",
  },
  fileUploader: {
    emptyFileError:
      "Le fichier contient 0 octet et n'a donc pas pu être téléchargé.",
    fileCouldNotFetch: "Le téléchargement a échoué. Veuillez réessayer",
    fileError: "Sélectionnez un fichier",
    fileSizeError: "Le fichier dépasse la limite autorisée de 20 MB",
    fileTypeError:
      "Cette fonctionnalité permet uniquement de télécharger et partager des documents PDF.",
    fileUploadError: "Le fichier n'a pas pu être téléchargé",
    titleError: "Donnez un titre à votre fichier",
  },
  filterBar: {
    checkboxTransitionalCare: "",
    chipTransitionalCare: "",
    clearAll: "réinitialiser",
    filterBy: "Filtrer par",
    saveAsDefault: "Enregistrer les filtres par défaut",
    senderClinic: "",
    showLess: "Afficher moins",
    showMore: "Afficher plus",
    showOnlyElective: "",
    showPendingEligibility: "Only show patients with pending eligibility",
    specialization: "",
    titleEligibility: "Eligibility",
    titleTransitionalCare: "",
  },
  general: {
    and: "et",
    asap: "Dès que possible",
    aUser: "un utilisateur",
    banner: {
      cta: {
        noEncryptionAccessAlert:
          "Nous n'avons pas pu connecter votre compte à notre environnement sécurisé et chiffré. Cela vous empêchera d'accéder aux données chiffrées et de créer des patients. Veuillez vous déconnecter et vous reconnecter. Si le problème persiste, veuillez contacter notre service clientèle.",
        noEncryptionAccessAlertAction: "Se déconnecter",
        noEncryptionAccessAlertTitle: "Le cryptage n'est pas disponible",
        noSealdConnectionAlert:
          "Votre connexion à seald.io n'est pas disponible, ce qui empêche le cryptage de bout en bout. Veuillez vérifier votre connexion {select:ici} ou contacter notre service clientèle pour résoudre le problème.",
        partialEncryptionAccessInfo:
          "Les données cryptées sont actuellement indisponibles pendant une courte période. Veuillez faire preuve de patience et contacter votre personne de contact recare si le problème persiste.",
        sunsetInternetExplorer: "Changer de navigateur",
      },
      noAccessPatientDataInternetExplorer:
        "Nous ne pouvons pas traiter les données personnelles de manière sécurisée sur Internet Explorer. Pour visualiser les données, veuillez changer de navigateur.",
      noAccessPatientDataInternetExplorerTitle:
        "Les données personnelles du patient sont verrouillées à cause de votre navigateur",
      searchCategoryMoved: "",
      sunsetInternetExplorer:
        "Recare cessera bientôt de fonctionner sur Internet Explorer, ce navigateur n'étant plus maintenu par Microsoft. Pour plus d'informations et d'aide, cliquez sur le bouton suivant",
      sunsetInternetExplorerSenders:
        "Recare cessera de fonctionner sur Internet Explorer à partir du 1er septembre 2021, ce navigateur n'étant plus maintenu par Microsoft. ",
    },
    captionDateNotInPast:
      "Note : les deux dates ne peuvent pas être dans le passé.",
    characterCounter: (args: { count: string; maxLength: string }) =>
      template(
        "{{count}} de caractères {{maxLength}} caractères restants",
        args,
      ),
    colon: " :",
    columnHeader: "En-tête de colonne",
    confirmation: "Confirmation",
    containsLink:
      "Pour des raisons de sécurité, vous ne pouvez insérer de lien ici.",
    date: "Date",
    disablePatientCreateButton: "",
    error: "Désolé, il y a eu une erreur. Veuillez réessayer plus tard.",
    errorDateInPast: "La date ne peut être dans le passé",
    errorIELink:
      "{select:Vous trouverez ici} comment télécharger un autre navigateur internet ou changer votre navigateur par défaut. Veuillez contacter votre département informatique si cela n'est pas possible ou nous mettre directement en contact avec lui.",
    errorNotSupportIE:
      "Veuillez utiliser un autre navigateur afin de continuer (tels que Firefox, Edge ou Chrome)",
    errorNotSupprtIEPatient:
      "Nous vous prions de nous excuser, mais le profil patient ne peut être ouvert. Vous utilisez actuellement Internet Explorer et la protection des données personnelles ne peut pas être assurée sur ce navigateur.",
    errorRequestLimit:
      "Vous avez atteint votre limite de demande pour cette action. Veuillez réessayer plus tard.",
    fax: "Fax",
    fieldsMissing: "Certaines infos manquent",
    for: "pour",
    formValidationError:
      "Veuillez vérifier que tous les champs sont remplis correctement",
    formValidationErrorOnlyNumbers: "Veuillez entrer uniquement des chiffres",
    from: "Du",
    fromAge: "à partir de",
    fromDate: "à partir du",
    group: "groupe",
    helloName: (args: { name: string }) =>
      template("Bonjour, {{name}} !", args),
    lessThan: "moins de",
    loading: "Chargement",
    loginPostIdentityReset:
      "La sécurité de votre compte a été mise à jour. Veuillez vous reconnecter et saisir le nouveau code de vérification qui vous a été envoyé par email.",
    logOut: "Déconnexion",
    missing: "Manquant",
    moreThan: "plus de",
    never: "jamais",
    new: "Nouveau",
    no: "Non",
    none: "Non",
    noneR: "Non",
    noPreference: "Aucune préférence",
    noResult: "Pas de résultats",
    notAuthorizedToAccessThisPage:
      "Vous n'avez actuellement pas les droits pour accéder à cette page.",
    notAuthorizedToPerformAction:
      "Vous n'êtes pas autorisé(e) à effectuer cette action",
    notSpecified: "Non spécifié",
    oneMoment: "Veuillez patienter",
    oops: "Oups",
    or: "ou",
    other: "Autre",
    printingPleaseWait:
      "L'impression est en train d'être préparée. Veuillez patienter...",
    printModal: {
      receiver: {
        chatHistory: "Messagerie sécurisée (partie de droite)",
        patientProfile: "Fiche patient",
      },
      sender: {
        chatHistory: "Messagerie sécurisée (partie de droite)",
        informationAboutProvider: (args: { providerName: string }) =>
          template("Informations concernant {{providerName}}", args),
      },
      title: "Que souhaitez-vous imprimer ? ",
    },
    promptFilterSet: "Les filtres ont été appliqués. ",
    redactEncryptedData: {
      info: "Les données chiffrées ont été supprimées pour des raisons de protection des données.",
      placeholder: "supprimé ",
    },
    refreshToSeeChanges: "Actualiser pour voir les changements",
    reportContent: "Signaler du contenu",
    required: "Requis",
    retry: "Essayer à nouveau",
    secure: "Sécurisé",
    selectDefaultPlaceholder: "Entrer un ou plusieurs choix",
    selectDefaultPlaceholderShort: "Veuillez taper ou sélectionner",
    smallerThan: "plus petit(e) que",
    solutionsMissing: "[solutions manquantes]",
    sort: "Trier",
    status: {
      error: "Quelque chose s'est mal passé",
      loading: "Chargement, veuillez patienter",
      new: "Nouvelle",
    },
    success: "",
    switchToReceiver: "Plateforme Receveur",
    switchToSender: "Plateforme adresseur ",
    tallerThan: "plus grand(e) que",
    to: "au",
    toAge: "jusqu'à",
    today: "Aujourd'hui",
    toEditPleaseContactRecare:
      "Veuillez contacter Recare pour modifier ce champ.",
    tomorrow: "Demain",
    toRecipient: "À",
    toWeight: "à",
    transferTo: "Transférer à",
    tryAgain: "Essayer à nouveau",
    unknown: "Ne sait pas",
    untilDate: "jusqu'au",
    upload: "TÉlÉCHARGER",
    uploadFailed:
      "Votre téléversement a échoué, veuillez réessayer ou contacter un représentant recare",
    websiteUrl: "https://www.recaresolutions.fr",
    whatsNew: "Nouveautés",
    yesterday: "Hier",
    you: "Vous",
  },
  hintImportIE:
    "Il n'est pas possible d'importer les données du patient avec Internet Explorer ou une version de Microsoft Edge inférieure à 80. Veuillez considérer l'utilisation d'un navigateur plus moderne tel que Google Chrome, Mozilla Firefox ou Microsoft Edge (version supérieure à 80)",
  insuranceApp: {
    approveEligibility: "",
    banners: {
      bannerStartPrereservation: "",
      bannerUploadForms:
        "Cost payer needs the AHB forms to make a decision about patient eligibility. Please fill the forms after the surgery to speed the process up.",
      completeProfile: "",
      patientEligibilityPending: "",
      patientIsEligible: "",
      patientNotEligible: "",
    },
    captionAssessment: "",
    captionHandOverToInsurance: "",
    captionInsuranceEditSearch:
      "You can edit some of the above search criteria here:",
    confirmDeleteModal: "Voulez-vous vraiment supprimer le fichier?",
    confirmDeleteModalText: "",
    deleteForm: "Supprimer le formulaire",
    eligibility: "",
    eligibilityEvents: {
      transferFailedCodeMissing: "",
      transferFailedErrorUnknown: "",
      transferSuccessful: "",
    },
    eligibilityHeaderToBeProcessed:
      "Après avoir fourni toutes les informations nécessaires, cliquez sur le bouton PARTAGER LES FICHIERS pour les envoyer au payeur.",
    eligibilityTab: {
      eligibilityCard: {
        approve: "",
        captionTextProcessed:
          "Once the forms are uploaded and shared with the cost payer they will make a decision about patient’s eligibility.",
        clinicDidntSendYet: "",
        pleaseShareFormsInfo: "",
        reject: "",
        rejectModalTitle: "",
        subtitleEligibilityNotSet: "Patient eligibility has not been set yet",
        titleApproved: "",
        titleAwaitsCostPayerPartOne: "",
        titleAwaitsCostPayerPartTwo: "",
        titleClinicInformation: "",
        titleCostPayerInformation: "",
        titleHasBeen: "",
        titleInProcessPartOne: "",
        titleInProcessPartThree: "",
        titleInProcessPartTwo: "",
        titleRejected: "",
        titleToBeProcessed: "",
      },
      hintModalShareFroms: "",
      medicalFormName: "",
      requestFormName: "",
      sunsetEligibilityMessengerText: "",
      sunsetEligibilityMessengerTitle: "",
      titleEligibility: "Patient eligibility for Rehab",
      titleModalShareFroms: "",
      tooltipEncryption:
        "The information in the forms is encrypted and are securely sent.",
    },
    formDeleteFailure:
      "Échec de la suppression du formulaire, veuillez réessayer ou contacter un représentant Recare.",
    guidanceToNextSteps: "",
    infoModalEditSearchCriteria:
      "Please edit these search criteria if it’s necessary. Clinic will be notified about the changes you have made.",
    rejectEligibilityReasons: {
      otherReason: "Autre raison",
      otherReasonLabel: "Veuillez spécifier",
    },
    requestCancelled: "",
    searchWasStopped: "",
    sunsetCostpayerFlow: {
      body: "",
      title: "",
    },
    titleModalEditSearchCriteria: "Edit search criteria",
    undoEligibility: "",
    yourPartner: "",
  },
  landmarks: {
    aside: "Contenu complémentaire",
    footer: "Bas de page",
    header: "Entête",
    main: "Contenu principal",
    section: (args: { sectionName: string }) =>
      template("Section {{sectionName}}", args),
  },
  legal: {
    agreedDpa: "J'ai lu et j'accepte le contrat sur le traitement des données",
    agreedDpaLink:
      "J'ai lu et j'accepte le {select:contrat sur le traitement des données}",
    agreedTermsAndConditions:
      "J'ai lu et j'accepte les conditions générales d'utilisation",
    bannerCtaLegalSection: "Confirmer les documents",
    bannerDocumentsToSign:
      "Veuillez noter que certains documents contractuels nécessitent encore votre confirmation.",
    bannerDocumentsToSignBcp: "",
    bannerDocumentsToSignOnlyBToB:
      "Veuillez noter que certains documents contractuels nécessitent encore votre confirmation.",
    bannerDocumentsToSignWeserEms:
      "Veuillez noter que certains documents contractuels nécessitent encore votre confirmation.",
    cannotBeChanged:
      "Rétractation possible uniquement via notre service client ",
    cannotBeChangedWithLink:
      "Rétractation possible uniquement via notre {select:service client}",
    churchOwnedQuestion: "",
    clickOnLinkToAgree:
      "Cliquez ci-dessus pour télécharger le contrat de traitement des données",
    clickOnLinkToAgreeTC:
      "Cliquez sur le lien ci-dessus pour lire et accepter les conditions générales d'utilisation ",
    differencesInTCVersions: "",
    isChurchOwned: "",
    isNotChruchOwned: "",
    legalAgreements: "Mentions légales",
    printableVersion: "Version imprimable",
    readOnly: "Lire seulement ",
    toViewClickOnLink:
      "Veuillez cliquer sur le lien suivant pour ouvrir le fichier",
    uploadedDateDPA: (args: { date: string }) =>
      template("Téléchargé le {{date}}", args),
    uploadedOn: (args: { date: string }) =>
      template(
        "Téléchargé le {{date}}. Pour télécharger un fichier différent, veuillez contacter notre service client. ",
        args,
      ),
    viewFileInNewTab: "Ouvrir le fichier dans un nouvel onglet",
  },
  login: {
    biometricfailedAlert: {
      failed: "Erreur. Veuillez utiliser le nom d'utilisateur/mot de passe.",
      falsePasswordLimitReached: (args: { timer: string }) =>
        template(
          "Votre compte a été temporairement bloqué en raison d'un trop grand nombre de tentatives de connexion incorrectes. Veuillez réessayer dans {{timer}} minutes.",
          args,
        ),
      noCredentials:
        "Aucune information d'identification enregistrée, veuillez d'abord vous connecter une fois.",
      resetPasswordLimitReached: (args: { timer: string }) =>
        template(
          "You have reached the maximum number of password resets. Please try again in {{timer}}. ",
          args,
        ),
    },
    buttonSetNewPassword: "Définir un nouveau mot de passe",
    care: {
      changeToSender: "Si vous êtes un établissement adresseur,",
      loginTitle: "Identification receveurs",
    },
    challengeScreen: {
      infoText:
        "Veuillez s'il vous plaît vérifier votre compte. Nous vous avons envoyé un e-mail avec un code, veuillez entrer ce code ci-dessous pour continuer.\n",
      infoTextTimestamp: (args: { datetimestring: string }) =>
        template("Votre code a été envoyé le {{datetimestring}}.", args),
      placeholder: "Votre code de vérification",
      title: "Vérification du compte",
      verificationInvalid:
        "Code invalide - veuillez entrer le code reçu dans l'e-mail.",
      verificationInvalidTimestamp: (args: { datetimestring: string }) =>
        template(
          "Code invalide - veuillez entrer le code reçu dans l'e-mail envoyé le {{datetimestring}}.",
          args,
        ),
    },
    changeEnv: "Changer d'env",
    changePassword: "Modifier le mot de passe",
    clinic: {
      bannerDemo:
        "Vous êtes actuellement connecté(e) à l'environnement de test. Pour accéder à l'environnement de production, veuillez {select:cliquer ici}.",
      changeToReceiver: "Si vous êtes un établissement receveur,",
      clickHere: "cliquez ici.",
      loginTitle: "Identification adresseurs",
      loginTitleDemo: "Identification adresseurs - Demo",
      loginTitlePreprod: "Identification adresseurs - Preprod",
    },
    confirmNewPasswordPlaceholder: "Saisissez à nouveau votre mot de passe",
    confirmYourPasswordTitle: "Confirmez votre nouveau mot de passe",
    contactUrl: "https://recaresolutions.fr/nous-contacter",
    contactUs: "Contactez-nous",
    currentPassword: "Mot de passe actuel",
    emailAddress: "Votre adresse email",
    encryptionAccountWarning: "",
    encryptionGeneralWarning: "",
    enterNewPasswordPlaceholder: "Saisissez votre mot de passe",
    enterNewPasswordTitle: "Votre nouveau mot de passe",
    errorNewPasswordInvalid: "Veuillez saisir un mot de passe valide.",
    errorPasswordMissing: "Veuillez saisir votre mot de passe",
    failedAlert: {
      text: "Veuillez vérifier l'adresse email et le mot de passe que vous avez entré",
      title: "Impossible de se connecter",
    },
    forgotPassword: "Mot de passe oublié ?",
    forgotPasswordDetails:
      "Veuillez entrer votre adresse email. Vous allez recevoir un email pour réinitialiser votre mot de passe.",
    forgotYourPassword: "Mot de passe oublié ?",
    haveAnAccountYet: "Vous n'avez pas encore de compte?",
    hintTemporaryLossOfEncryptedData:
      "Veuillez noter que les données chiffrées pourront être indisponibles pendant un certain temps suite à cette opération. ",
    linkExpired: {
      activationExpiredReceiver:
        "Malheureusement, le lien pour activer votre compte Recare n'est plus valable, car il ne reste actif que 5 jours pour des raisons de sécurité. Veuillez vous adresser à la personne de contact de Recare que vous connaissez afin d'obtenir un nouveau lien.",
      activationExpiredSender:
        "Malheureusement, le lien pour activer votre compte Recare n'est plus valable, car il ne reste actif que 5 jours pour des raisons de sécurité. Veuillez vous adresser à l'administrateur des utilisateurs de votre clinique ou à la personne de contact de Recare que vous connaissez afin d'obtenir un nouveau lien.",
      buttonToLogin: "Aller à la page de connexion",
      linkExpiredTitle: "Ce lien a expiré",
      passwordLinkExpiredReceiver:
        "Malheureusement, le lien pour réinitialiser votre mot de passe n'est plus valable, car il ne reste actif que 5 jours pour des raisons de sécurité. Veuillez cliquer sur « Mot de passe oublié ? » sur la page de connexion pour obtenir un nouveau lien. Vous pouvez également demander de l'aide à la personne de contact Recare que vous connaissez.",
      passwordLinkExpiredSender:
        "Malheureusement, le lien pour réinitialiser votre mot de passe n'est plus valable, car il ne reste actif que 5 jours pour des raisons de sécurité. Veuillez cliquer sur « Mot de passe oublié ? » sur la page de connexion pour obtenir un nouveau lien. Vous pouvez également demander de l'aide à l'administrateur des utilisateurs de votre clinique ou à la personne de contact Recare que vous connaissez.",
    },
    linkExpiredBackToLogin: "Retour au login",
    linkExpiredExplanation:
      "Toutes les excuses, il semble que le lien d'activation ait expiré car il n'est valable que pour 24 heures. Veuillez introduire une nouvelle demande d'accès à votre compte en contactant votre gestionnaire d'utilisateurs ou la personne de contact de Recare.",
    linkExpiredTitle: "Le lien d'activation a expiré !",
    loading: {
      dontLeavePage:
        "Cela peut prendre quelques secondes. Veuillez ne pas quitter ou actualiser cette page.",
      settingEnvironment:
        "Nous mettons en place votre environnement sécurisé !",
    },
    loggedAs: "Établissement",
    login: "S'identifier",
    loginPage: {
      accountVerificationInfo: "",
      challengeCodeValidation:
        "Veuillez saisir le code de confirmation qui vous a été envoyé par e-mail. Dans l'intervalle, ne fermez pas cette fenêtre.",
      clickHere: "Cliquez ici",
      clinicInfo: "Pas de fournisseur ?",
      clinicLink: "S'inscrire en tant que clinique",
      confirmNewPasswordValidation:
        "Veuillez saisir à nouveau votre nouveau mot de passe pour confirmer.",
      contactInfo: "Vous n'avez pas encore de compte?",
      contactInfoLink: "Contactez-nous",
      currentPasswordValidation: "Veuillez saisir votre mot de passe actuel.",
      demoInfoText:
        "Vous vous connectez à l'environnement de démonstration. Pour passer à l'environnement de production, utilisez:",
      email: "Adresse e-mail",
      emailPlaceholder: "Saisissez votre adresse e-mail",
      emailValidation: "Veuillez saisir une adresse e-mail valide",
      encryptionWarning: "",
      environmentInfo: "",
      errorInactiveAccount:
        "Le mot de passe ne peut pas être réinitialisé car le compte est inactif. Veuillez contacter votre administrateur utilisateur ou votre personne de contact Recare.",
      errorInvalidMail: (args: { email: string }) =>
        template(
          "L'adresse e-mail {{email}} que vous avez saisie n'est pas valide. Veuillez vérifier votre saisie et réessayer.",
          args,
        ),
      forgotPassword: "Mot de passe oublié ?",
      hidePassword: "Masquer le mot de passe",
      loginButton: "Se connecter",
      logInNow: "Connectez-vous maintenant",
      newPasswordMatchValidation:
        "Les nouveaux mots de passe saisis ne correspondent pas.",
      newPasswordValidation: "Veuillez saisir un nouveau mot de passe.",
      password: "Mot de passe",
      passwordAttemptError:
        "Vous avez saisi trop souvent un mauvais mot de passe. Réessayez plus tard.",
      passwordChangedInfo:
        "Votre nouveau mot de passe est maintenant actif. Veuillez vous connecter avec vos informations d'identification.",
      passwordChangedTitle: "Le mot de passe a été modifié avec succès",
      passwordChangeError:
        "Votre compte a été temporairement bloqué en raison d'un trop grand nombre de changements de mot de passe. Veuillez réessayer plus tard.",
      passwordChangeInfo: "",
      passwordIndicatorAriaLabel: "Indicateur de la force du mot de passe",
      passwordMedium: "Moyen",
      passwordPlaceholder: "Saisissez votre mot de passe",
      passwordResetInfo: "",
      passwordResetWrongEmailReceiver: "",
      passwordResetWrongEmailSender: "",
      passwordStrong: "Fort",
      passwordValidation: "Veuillez saisir votre mot de passe actuel.",
      passwordVeryStrong: "Très forte",
      passwordVeryWeak: "Très faible",
      passwordWeak: "Faible",
      privacyPolicyInfo: "Nous ne faisons aucun compromis:",
      productionLink: "Passage à la production",
      receiverInfo: "Pas de clinique?",
      receiverInfoLink: "S'inscrire comme fournisseur ultérieur",
      saveNewPasswordButton: "Enregistrer le nouveau mot de passe",
      sendResetEmailButton: "Envoyer un courriel",
      setNewPasswordButton: "Définir un nouveau mot de passe",
      showPassword: "Afficher le mot de passe",
      ssoErrorEmailMismatch: "",
      ssoErrorGeneric: "",
      ssoInfo: "Authentification unique activée",
      titleAccountVerification: "Vérification du compte",
      titleAcp: "Login ACP",
      titleChangePassword: "Modifier le mot de passe",
      titleChangePasswordFromEmail: "Définir un nouveau mot de passe",
      titleDemo: "demo",
      titleReceiver: "Récepteur de connexion",
      titleResetPassword: "Vous avez oublié votre mot de passe ?",
      titleSender: "Login clinique",
      toDashboardLink: "Vers la page d'accueil",
      toggleIconAriaLabel:
        "Cliquez sur cette icône pour afficher le mot de passe",
      verificationCode: "Votre code de vérification",
      verificationCodePlaceholder: "",
      verificationCodeValidation: "",
      verificationInfo: "",
      weakPasswordError: "",
    },
    loginSender: {
      contactInfo: "Vous n'avez pas encore de compte? Contactez-nous",
      email: "Adresse e-mail:",
      emailPlaceholder: "Saisissez votre adresse e-mail",
      emailValidation: "Veuillez saisir une adresse e-mail valide",
      loginButton: "Se connecter",
      passwordValidation: "Veuillez saisir votre mot de passe actuel",
      privacyPolicyInfo:
        "Nous ne faisons aucun compromis: Politique de confidentialité",
      ssoInfo: "Authentification unique activée",
      title: "Login clinique",
    },
    loginTitle: "S'IDENTIFIER",
    loginWithTouchId: "ou connectez-vous via biometric ID",
    mfa: {
      deviceVerified:
        "Votre appareil a été vérifié. Vous pouvez maintenant vous connecter. ",
      invalidToken:
        "La tentative de vérification a expiré. Veuillez vous connecter à nouveau pour relancer le processus de vérification. ",
      newDevice:
        "Vous êtes en train de vous connecter à partir d'un nouvel appareil. Nous vous avons envoyé un e-mail pour vérifier votre connexion.",
    },
    newPassword: "Votre nouveau mot de passe",
    newPasswordConfirmation: "Confirmez votre nouveau mot de passe",
    password: "Votre mot de passe",
    passwordAlert:
      "Pour réinitialiser votre mot de passe et accéder à l'application, veuillez vous rendre sur l'application web",
    passwordChanged: "Le mot de passe a déjà été modifié",
    passwordChangeFailure: "Le mot de passe actuel n'est pas valide",
    passwordChangeSuccess: "Votre mot de passe a bien été changé",
    passwordChangeSuccessLogin:
      "Votre mot de passe a bien été changé, veuillez vous reconnecter.",
    passwordConfirmation: "Confirmez votre nouveau mot de passe",
    passwordForgottenSuccess:
      "Un email vous a été envoyé pour pouvoir réinitialiser votre mot de passe.",
    passwordForgottenTitle: "MOT DE PASSE OUBLIÉ",
    passwordRequirements: "Le mot de passe doit :",
    passwordResetError: "L'adresse email indiquée n'est pas enregistrée.",
    passwordResetSuccessfully:
      "Votre nouveau mot de passe est désormais actif. Veuillez vous reconnecter avec vos nouveaux identifiants.",
    passwordsMustMatch: "Les deux mots de passe doivent être similaires",
    passwordValidationLength: "contenir au moins 8 caractères",
    passwordValidationLowerCase: "contenir des lettres minuscules (a-z)",
    passwordValidationNumeric: "contenir des chiffres (0-9)",
    passwordValidationUpperCase: "contenir des lettres majuscules (A-Z)",
    passwordVisibility: {
      hide: "Masquer le mot de passe",
      show: "Afficher le mot de passe",
      ssoErrorGeneric: "",
    },
    privacyPolicy: "Politique de confidentialité",
    sendResetEmail: "Envoyer l'email",
    setNewPassword: (args: { email: string }) =>
      template("Entrez votre nouveau mot de passe pour {{email}}", args),
    setNewPasswordEncryptionInformation:
      "Les données cryptées des patients peuvent être indisponibles pendant une courte période. Veuillez faire preuve de patience.",
    setNewPasswordInstructions:
      "Utilisez un minimum de 8 caractères, y compris des chiffres (0-9), des lettres minuscules (a-z) et des lettres majuscules (A-Z). Assurez-vous que les deux mots de passe correspondent.",
    setNewPasswordLoading: "Définition du nouveau mot de passe",
    setNewPasswordNoMatch: "Les mots de passe ne correspondent pas.",
    setNewPasswordTitle: "Définir un nouveau mot de passe",
    ssoEnabled: "Authentification unique (SSO) activée",
    toastInactiveProvider:
      "Votre compte est associé à un hôpital adresseur inactif - Veuillez contacter Recare.",
    tokenExpired: "Le lien a expiré",
    tokenInvalid: "Le lien n'est pas valide",
    tokenInvalidContactRecare:
      "Ce lien n'est plus valide. Veuillez contacter Recare pour en recevoir un nouveau : 01 76 42 05 40",
    tooManyAttempts: (args: { time: string }) =>
      template("Trop de tentatives, essayez a nouveau dans {{time}}.", args),
    welcome: "Bienvenue sur Recare !",
    wrongEmailPassword: "Mauvais email ou mot de passe",
  },
  menu: {
    addPatientButton: "Nouveau patient",
    faq: "FAQ",
    more: "Plus",
    navBarSender: {
      ariaLabelMainNavigation: "",
      ariaLabelTopLevelNavigation: "",
      subtitleGateway: "",
      subtitleReport: "",
      subtitleSearches: "",
      subtitleUserManagement: "",
      titleGateway: "",
      titleReport: "",
      titleSearches: "",
      titleUserManagement: "",
    },
    newsletterSubscription: "Newsletter",
    onPremise: "Gateway Administration",
    planning: "Calendrier",
    privacyPolicy: "Politique de confidentialité",
    providerTraining: "",
    rehabproviderTraining: "",
    report: "Reporting",
    searchBar: "Rechercher un patient",
    termsAndConditions: "Conditions Générales d’Utilisation",
    users: "",
  },
  messenger: {
    accessDeniedNeedAccess: (args: { name: string }) =>
      template(
        "Ce contenu ne peut pour l’instant être affiché, un autre utilisateur ({{name}}) doit se connecter pour que l’accès soit activé. Pour plus d’informations veuillez contacter Recare.",
        args,
      ),
    accessibility: {
      emojiButtonLabel: "",
      messageInputFieldLabel: "",
      messageLabel: "",
      messageSeenNotification: "",
      rejectionInputFieldLabel: "",
      responseRejected: "bouton annuler",
    },
    careStartDateIsInThePastBannerMessage:
      "Dans le cas où vous pouvez prendre en charge le patient à une date ultérieure, veuillez s'il-vous-plaît cliquer sur ACCEPTER et indiquer cette date.",
    fileReceiverHint: (args: { facility: string }) =>
      template("Votre fichier sera envoyé à {select:{{facility}}}", args),
    fileReceiversHint: (args: { facility: string; secondFacility: string }) =>
      template(
        "Votre fichier sera envoyé à {select:{{facility}}} et {select:{{secondFacility}}}",
        args,
      ),
    fileTransferEncrypted: "Les fichiers envoyés ici seront chiffrés",
    messageReceiverHint: (args: { facility: string }) =>
      template("Votre message sera envoyé à {select:{{facility}}}", args),
    messageReceiversHint: (args: {
      facility: string;
      secondFacility: string;
    }) =>
      template(
        "Your message will be sent to {select:{{facility}}} and {select:{{secondFacility}}}",
        args,
      ),
    needToRequestAccess:
      "Ce contenu ne peut être affiché actuellement. Afin d'accéder à l'historique des messages, veuillez demander l'accès ici :",
    notAvailableForTransport: "",
    notEncryptedHint:
      "Attention, les messages que vous envoyez ici ne sont pas chiffrés, veuillez ne pas entrer d’informations qui permettraient d’identifier le patient.",
    notSeen: "envoyé",
    receiver: {
      commissionFee: (args: { price: string }) =>
        template("Taxe aux usagers: {select:{{price}}} EUR", args),
      commissionInfo:
        "Profitez de notre phase d'essai gratuite : jusqu'au 31.12.2022, nous ne facturons pas de frais d'utilisation. ",
      commissionTooltip:
        "Période d'essai gratuite : nous ne facturons pas de frais d'utilisation jusqu'au 31.12.2022 ! ",
      fileRestrictionHint: "",
      solutionsOffered: "",
    },
    receiverHint: "Votre message sera envoyé à",
    requestAccess: "Demander l'accès",
    seen: "vu",
    sender: {
      chooseSolution: (args: { careproviderName: string }) =>
        template(
          "{{careproviderName}} vous offre les propositions suivantes pour ce patient. Veuillez sélectionner la proposition la plus adaptée : ",
          args,
        ),
      clinic: "",
      costPayer: "",
      fileRestrictionHint: "",
      geriatrics: "",
      provider: "",
      rehabClinic: "",
    },
    solutionsLabel: "",
    yourChoice: "Votre choix (obligatoire) *",
  },
  nativeApp: {
    contactUs: "Contacter Recare",
    errorCode: "Code de l’erreur",
    menu: {
      archived: "Archivées",
      attributed: "Attribuées",
      dashboard: "Demandes",
      details: "Détails",
      messages: "Messages",
      pending: "En attente",
      settings: "Paramètres",
    },
    messages: {
      dateAlert: "Veuillez sélectionner une date",
      downloadAlert:
        "Pour télécharger ce fichier, veuillez vous rendre sur l'application web",
      inputPlaceholder: "Ecrivez ici...",
      messageAlert: "Veuillez entrer un message",
      timeAlert: "Veuillez sélectionner une heure",
    },
    pushNotifications: {
      attribution: {
        body: (args: { message: string }) => template("{{message}}", args),
        title: (args: { patientId: string }) =>
          template("Le patient {{patientId}} vous a été attribué", args),
      },
      attributionCancelled: {
        body: (args: { message: string }) => template("{{message}}", args),
        title: (args: { patientId: string }) =>
          template("L'attribution a été annulée pour {{patientId}}", args),
      },
      availableAgain: {
        title: (args: { patientId: string }) =>
          template("Le patient {{patientId}} est à nouveau disponible", args),
      },
      newFile: {
        body: (args: { file: string }) =>
          template("Nom du document : {{file}}", args),
        title: (args: { patientId: string }) =>
          template("Nouveau fichier pour {{patientId}}", args),
      },
      newMessage: {
        body: (args: { message: string }) => template("{{message}}", args),
        title: (args: { patientId: string }) =>
          template("Nouvelle réponse pour {{patientId}}", args),
      },
      newMessageForClinic: {
        body: (args: { careprovider: string }) =>
          template("{{careprovider}} vous a envoyé un nouveau message", args),
      },
      newRequest: {
        body: (args: { transferDate: string; diagnosis: string }) =>
          template(
            "Date de transfert : {{transferDate}}\nDiagnostic : {{diagnosis}}",
            args,
          ),
        title: (args: { patientId: string; clinic: string }) =>
          template("Nouvelle demande {{patientId}} de {{clinic}}", args),
      },
      notAvailableAnymore: {
        title: (args: { patientId: string }) =>
          template("Le patient {{patientId}} n'est plus disponible", args),
      },
      notAvailableAttribution: {
        body: "Un autre établissement a été trouvé",
        title: (args: { patientId: string }) =>
          template("Le patient {{patientId}} n'est plus disponible", args),
      },
      notAvailableSearchStopped: {
        body: (args: { reasonOrOther: string }) =>
          template("La recherche a été arrêtée : {{reasonOrOther}}", args),
        title: (args: { patientId: string }) =>
          template("Le patient {{patientId}} n'est plus disponible", args),
      },
      profileEdited: {
        title: (args: { patientId: string }) =>
          template("Le profil du patient {{patientId}} a été modifié.", args),
      },
    },
    settings: {
      capacity: {
        capacityAvailable: "Lits actuellement disponibles",
        capacityNotAvailable: (args: { capacityDate: string }) =>
          template("Lit(s) disponible(s) à partir du {{capacityDate}}", args),
        detailPage: {
          ableToAcceptPatients:
            "Nous pouvons accepter de nouveaux patients à partir du :",
          capacityAvailableInfo: "Lits actuellement disponibles\n",
          hintText: {
            capacityAvailable:
              "Vous recevez actuellement de nouvelles demandes de patients en raison de votre disponibilité.",
            noCapacityWithoutTimeSelect:
              "Vous ne recevrez plus de demandes de prise en charge qui sont censées débuter avant la date et l’heure que vous aurez indiquées. Toutes les autres demandes de prise en charge en attente avant la date et l’heure que vous aurez indiquées seront déclinées automatiquement.\n",
            noCapacityWithTimeSelect:
              "Vous ne recevrez plus de demandes de prise en charge qui sont censées débuter avant la date et l’heure que vous aurez indiquées. Toutes les autres demandes de prise en charge en attente avant la date et l’heure que vous aurez indiquées seront déclinées automatiquement.",
          },
          title: "Vos disponibilités",
          toggle: "Oui / Non",
          toggleNoYes: "Non / Oui",
        },
        title: "Disponibilités",
        toastSuccess: "Sauvegardé avec succès",
      },
    },
    somethingWentWrong: "Une erreur s'est produite",
    updateNeeded: {
      pleaseUpdate: "Veuillez mettre à jour l’application",
      skipVersion: "Passez cette version",
      updateInfo:
        "Nous avons amélioré la sécurité de notre application et la protection des données. Pour continuer à utiliser Recare sur cet appareil, veuillez mettre à jour l'application via l’App Store en téléchargeant la dernière version. ",
    },
  },
  navigations: {
    patient: "Navigation des patients",
    primary: "Navigation primaire",
  },
  networkChecker: {
    retry: "Réessayer",
    retryingMessage: (args: { count: string }) =>
      template(
        "Problème de connexion. Nouvelle tentative de connexion dans {{count}}...",
        args,
      ),
  },
  newOnboarding: {
    account: {
      caption: "Votre compte",
      dontEnterCentralEmailCaption: "",
      errorEmailDoesntExist: "",
      errorEmailExists: "",
      loginCaption:
        "Contactez-nous au 01 76 42 05 40 si vous rencontrez des difficultés pour vous connecter.",
      title: "Vos coordonnées",
      titleNoAccount:
        "Si vous n'avez pas de compte, veuillez entrer vos informations ici :",
      titleWithAccount:
        " Do you already have a Recare account? If yes, please log in here:",
    },
    activateAccountTitle: "Activez votre compte Recare",
    company: {
      caption: "Votre unité",
      title: "Informations générales",
    },
    congratulations: {
      benefits:
        "You can now enjoy all the benefits of being a registered member of Recare. We are looking forward to supporting you in your everyday life!",
      contactUs:
        "Contactez-nous au 01 76 42 05 40 si vous avez d'autres questions.",
      goToProfile: "Go to my profile",
      thankYou:
        "Thank you, we are looking forward to a successful collaboration!",
      title: "Congratulations!",
      yourTeam: "Your Recare team",
    },
    password: {
      caption: "Your password",
      cardTitle: "Veuillez choisir votre mot de passe",
      checkInbox: "",
      didOpenSetPassword:
        "Thank you for opening the link for registration. You can now close this window.",
      emailSent: () => "",
      goBack: "previous page",
      title: "",
      wrongEmail: "",
    },
    personalOnboarding: {
      newsletterSubscription: "",
      title: "Créer votre compte Recare pour",
    },
    services: {
      caption: "Your services",
    },
    testrequest: {
      caption: "Test request",
    },
    welcome: {
      getStarted: "Get started",
      recareIsFreeEnd: "or call us at",
      recareIsFreeHere: "here",
      recareIsFreeNumber: "01 76 42 05 40.",
      recareIsFreeStart:
        "Recare is completely free for you. You can find more information about us",
      thanksToRecare: "Thanks to Recare you will:",
      title: "Welcome!",
      updateInformation:
        "You will be able to update your information any time in your settings.",
      willAccept: "accept or decline patients requests in 1 click",
      willCommunicate:
        "communicate via secured messages with hospital staff to discuss patients requests",
      willReceive:
        "receive targeted patients requests via email from clinics near you",
    },
    welcomeToRecareTitle: "Bienvenue chez Recare",
    welcomeToTestTitle:
      "Welcome to the test request: in order to complete your registration, please click on ACCEPT or DECLINE.",
  },
  newOnboardingTwo: {
    account: {
      alreadyHaveAccount:
        "Vous avez déjà un compte ? {select:Cliquez ici} pour vous connecter",
      dontEnterCentralEmailCaption:
        "L'adresse email centrale pour les admissions (par ex. secrétariat@ ...) doit être renseignée ici seulement si vous souhaitez vous connecter à la plateforme avec cette adresse.",
      dontHaveAccount:
        "Vous n'avez pas encore de compte ? {select:Cliquez ici} pour vous enregistrer.",
      errorEmailDoesntExist:
        "Cette adresse email n'existe pas dans notre système. Veuillez créer un nouveau compte ci-dessous. ",
      errorEmailExists:
        "Cette adresse électronique est déjà liée à un compte. Veuillez vous connecter en haut de la page, choisir une autre adresse électronique ou {select:réinitialiser le mot de passe de ce compte}.",
      genericError: "",
      goToLogin: "Connexion",
      goToRegister: "S'enregistrer",
      hintAlreadyHaveAnAccount: "Vous avez déjà un compte ? ",
      hintDontHaveAccount: "Vous n'avez pas encore de compte ?",
      loginToAccount: "Connectez-vous à votre compte",
      title: "Informations personnelles",
    },
    additionalnformation: {
      facilitySize: {
        employees: "1 à 5 employés",
        facilitySizeTitle: "Quelle est la taille de votre établissement ?",
        large: "20+ employés",
        medium: "5 à 20 employés",
      },
      informationSystem: "Avec quel système d'information travaillez-vous ?",
      introductionText:
        "Afin d'optimiser l'utilisation de la plate-forme pour votre établissement, vous avez ici la possibilité d'indiquer des informations supplémentaires sur votre établissement. Toutes les informations sont facultatives. Elles n'auront aucune influence sur vos possibilités de soins et ne seront visibles ni pour les cliniques, ni pour les autres établissements. Aidez-nous en nous communiquant vos données dans le but d'une évaluation générale, afin que nous puissions rendre la plate-forme aussi conviviale que possible, malgré la diversité des établissements.",
      newFacilityOpened:
        "Votre établissement a-t-il ouvert ses portes au cours des 6 derniers mois ?",
      responsiblePerson: {
        lessThanTwo: "Moins de 2",
        moreThanTwo: "Plus de 2",
        none: "aucun",
        responsiblePersonTitle:
          "Combien de personnes sont responsables des admissions de patients dans votre entreprise ?",
      },
      useOfWaitlists:
        "Travaillez-vous avec des listes d'attente dans votre entreprise ?",
    },
    companyDetails: {
      disclaimerLegalDocuments: "",
      errorRequiredDataProcessingAgreement:
        "Veuillez accepter le contrat sur le traitement des données",
      title: "Informations sur votre unité",
    },
    congratulations: {
      benefits:
        "Vous pouvez désormais profiter de tous les avantages de la plateforme Recare. Nous sommes heureux de commencer à travailler avec vous ! ",
      contactUs:
        "N'hésitez pas à nous contacter au 01.76.42.05.40 pour toute question ou demande d'information.",
      getStarted: "Accéder à votre profil",
      thankYou:
        "Nous vous remercions et sommes heureux de travailler avec vous ! ",
      title: "Félicitations !",
      yourTeam: "Votre équipe Recare",
    },
    errors: {
      invalidToken: "Erreur : jeton non valide",
      sealdError: "Erreur : erreur du serveur Seald externe",
      unknown: "Erreur : inconnue",
    },
    password: {
      errorRequiredTermsAndConditions:
        "Veuillez accepter les conditions générales.",
      termsAndConditions:
        "J'ai lu et accepté les {select:Conditions générales d'utilisation}",
      title: "Choisissez votre mot de passe",
    },
    receivedMail: {
      checkInbox:
        "Veuillez consulter votre boîte de réception et suivre les instructions pour finaliser votre enregistrement.",
      didOpenSetPassword:
        "Merci d'avoir ouvert le lien pour finaliser votre enregistrement. Vous pouvez désormais fermer cette fenêtre.",
      emailSent: (args: { email: string }) =>
        template("Un email a été envoyé à {select:{{email}}}", args),
      goBack: "page précédente",
      title: "Vous avez reçu un email !",
      wrongEmail: "Mauvaise adresse email ? Retournez sur la",
    },
    selfOnboardingEmployee: {
      account: {
        providerInfo:
          "Vous êtes sur le point de créer un compte pour {select: providerName}",
        title: "Créer votre compte Recare",
      },
    },
    services: {
      title: "Offre de soins",
    },
    stepper: {
      account: "Votre compte",
      additionalInformation: "Informations complémentaires",
      additionalinformation: "Informations complémentaires",
      company: "Informations sur votre unité",
      password: "Votre mot de passe",
      services: "Offre de soins",
      step: "",
      testrequest: "Test request",
      yourcompany: "Votre unité",
      yourteam: "Votre équipe",
    },
    welcome: {
      getStarted: "Démarrer",
      recareIsFree:
        "Recare est complètement gratuit pour vous. Vous pouvez trouver plus d'informations {select:ici} ou nous appeler au 01.76.42.05.40.",
      thanksToRecare: "Grâce à Recare vous pouvez :",
      title: "Bienvenue ! ",
      updateInformation:
        "Vous pourrez également modifier vos informations plus tard dans vos paramètres.",
      willAccept: "accepter ou refuser les prises en charge en 1 click ",
      willCommunicate:
        "communiquer via notre messagerie sécurisée avec l'établissement adresseur",
      willReceive:
        "recevoir des demandes ciblées par rapport à votre offre de soins des hôpitaux autour de vous",
    },
  },
  notification: {
    error: "Erreur",
    info: "Info",
    success: "Succès",
  },
  npsSurvey: {
    npsCommentPlaceholder: "Veuillez spécifier",
    npsCommentSend: "Envoyer",
    npsCommentTitleDetractors: "Comment pouvons-nous nous améliorer ?",
    npsCommentTitlePromoters: "Qu’aimez-vous le plus à propos de Recare ?",
    npsGoal: "",
    npsSurveyScoreHigh: "Très probable",
    npsSurveyScoreLow: "Pas du tout probable",
    npsSurveyTitle:
      "Quelle est la probabilité que vous recommandiez Recare à vos collègues ?",
    npsThankYou: "Nous vous remercions pour votre retour.",
  },
  onpremSetup: {
    connectionSuccessfull: "",
    currentVersion: "",
    installation: {
      dockerVersion: "",
      documentation: "",
      downloadBinary: "",
      downloadCompose: "",
      gatewayInstallation: "",
      latestVersion: "",
      windowsVersion: "",
    },
    newVersionAvailable: "",
    pastImports: {
      globalStats: (args: { num: string }) =>
        template(
          "Statistiques mondiales ({{num}} dernières importations)",
          args,
        ),
      imports: "",
    },
    testImport: {
      explanationGetFiles: "",
      explanationImportPatient: "",
      explanationLastMessages: "",
      explanationSendFiles: "",
      filesSection: "",
      getFiles: "",
      guardians: "",
      importPatient: "",
      insuranceMapping: "",
      lastMessages: "",
      mapping: "",
      mappingFailed: "",
      missingData: "",
      noDataProvided: "",
      noMappingConfig: "",
      patientContact: "",
      relatives: "",
      sendFile: "",
      testHisImport: "",
    },
  },
  ontologies: {
    academicTitle: {
      key: "Titre",
      values: {
        doctor: "Dr.",
        doctorDoctorMedicine: "Dr. Dr. med.",
        doctorMedicine: "Dr. med.",
        doctorMultiplex: "Dr. mult.",
        professor: "Prof.",
        professorDoctor: "Prof. Dr.",
        professorOrdinarius: "Univ. Prof.",
      },
    },
    accountRole: {
      key: "Rôle",
      values: {
        accountManager: "ACCOUNT MANAGER",
        admin: "ADMIN",
        adminEditCareprovider: "EDIT PROVIDER",
        adminEditCareseeker: "EDIT HOSPITAL",
        adminEditConsultants: "",
        adminReadOnly: "READ ONLY",
        adminTranslations: "TRADUCTIONS",
        care: "CARE",
        careseekerIT: "IT",
        careseekerSSOUserManagement: "",
        careseekerUserManagement: "USER MANAGEMENT",
        costApprover: "Cost Approver",
        edminEditConsultants: "",
        homeCare: "Homecare ",
        hospital: "HÔPITAL",
        it: "IT CONTACT",
        jourFixe: "JOUR FIXE CONTACT",
        management: "MANAGEMENT",
        medicalSupplies: "MEDICAL SUPPLIES + HOMECARE",
        projectManager: "PROJECT MANAGER",
        protectedPatient: "Patient protégé",
        provider: "ACCESS",
        providerContact: "CONTACT",
        rehab: "SSR",
        report: "REPORT",
        sales: "SALES",
        senderApi: "Sender API",
        transport: "TRANSPORT",
      },
    },
    accountRoleShort: {
      key: "RoleShort",
      values: {
        accountManager: "ACCOUNT MANAGER",
        admin: "ADMIN",
        adminEditCareprovider: "EDIT PROVIDER",
        adminEditCareseeker: "EDIT HOSPITAL",
        adminReadOnly: "READ ONLY",
        adminTranslations: "TRADUCTIONS",
        care: "EHPAD",
        careseekerSSOUserManagement: "",
        careseekerUserManagement: "",
        costApprover: "Cost Approver",
        edminEditConsultants: "EDIT CONSULTANTS",
        homeCare: "Home care",
        hospital: "HÔPITAL",
        it: "IT",
        jourFixe: "JF",
        management: "Mgmt",
        medicalSupplies: "MEDICAL SUPPLIES + HOMECARE",
        projectManager: "PM",
        protectedPatient: "Patient protégé",
        provider: "ACCESS",
        providerContact: "CONTACT",
        rehab: "RÉÉDUCATION",
        report: "REPORT",
        sales: "SALES",
        senderApi: "",
        transport: "TRANSPORT",
      },
    },
    accountStatus: {
      key: "Statut",
      values: {
        active: "Active",
        created: "Created",
        inactive: "Inactive",
      },
    },
    accountType: {
      key: "Account Type",
      values: {
        api: "External",
        bToC: "B2C",
        callcenter: "Callcenter",
        careprovider: "Careprovider",
        clinic: "Clinique",
        external: "External",
        staff: "Recare staff",
        tool: "Tool",
      },
    },
    ageOfPatient: {
      key: "Âge des patients",
      values: {
        children: "Enfants et adolescents",
        patientsBelow70: "Patients < 70 ans",
        patientsOver70: "Patients > 70 ans",
      },
    },
    blacklistReceiverRules: {
      key: "Sender Receiver Rules",
      values: {
        age: "Age",
        blacklistedInsurance: "Blacklisted Insurance",
        capacityBackoff: "Capacity Backoff",
        capacityStartDate: "Capacity Start Date",
        contacted: "Contacted",
        distance: "Distance",
        filterContacted: "Contacted",
        noBedAvailable: "No Bed Available",
        prerequisite: "Prerequisite",
        receiverBlacklisted: "Receiver Blacklisted",
        removedSolution: "Removed Solution",
        senderBlacklisted: "Sender Blacklisted",
        service: "Service",
        solution: "Solution",
        specialization: "Specialization",
        status: "Status",
        throttle: "Throttle",
        zipcodes: "Zipcodes",
      },
    },
    candidatesProgress: {
      key: "Avancement du calcul du candidat",
      values: {
        failed: "Le calcul du candidat a échoué",
        inProgress: "Recherche de receveurs en cours",
        notStarted: "Le calcul des candidats n'a pas démarré",
        success: "Le calcul du candidat a réussi",
      },
    },
    cardiovascularExploration: {
      key: "Explorations cardiovasculaires",
      values: {
        cardiacMri: "IRM cardiaque",
        coronarography: "Coronarographie",
        coronaryScanner: "Coroscanner",
        exerciseTest: "Epreuve d’effort",
        holterEcg: "Holter-ECG",
        rhythmology: "Rythmologie",
        toe: "ETO",
        tte: "ETT",
      },
    },
    careDurations: {
      key: "Care durations",
      values: {
        fiveMonths: "5 mois",
        fourMonths: "4 mois",
        oneDay: "1 jour",
        oneMonth: "1 mois",
        oneWeek: "1 semaine",
        sixMonthsPlus: "6+ mois",
        threeMonths: "3 mois",
        threeWeeks: "3 semaines",
        twoMonths: "2 mois",
        twoWeeks: "2 semaines",
        unknown: "Inconnu",
      },
    },
    carelevel: {
      key: "Carelevel",
      values: {
        five: "5",
        four: "4",
        none: "none",
        one: "1",
        three: "3",
        two: "2",
        unknown: "unknown",
      },
    },
    careproviderStatus: {
      key: "Statut",
      values: {
        active: "Active",
        created: "Created",
        duplicate: "Duplicate",
        filledProfile: "Filled profile",
        inactive: "Inactive",
        invalid: "Invalid",
        notAnswered: "non répondu",
        pendingAccountActivation: "Pending password activation",
        test: "test",
        testNotAnswered: "Test not answered",
        testSent: "Test sent",
        testtest: "",
        trash: "Trash",
      },
    },
    careseekerModuleStatus: {
      key: "Careseeker - Module Status",
      values: {
        active: "Actif",
        created: "Created",
        inactive: "Inactif",
        onboarding: "Onboarding",
        test: "Test phase",
      },
    },
    careseekerPriority: {
      key: "Priority",
      values: {
        high: "",
        low: "",
        medium: "",
      },
    },
    careseekerStatus: {
      key: "Status",
      values: {
        active: "Actif",
        created: "Created",
        duplicate: "Duplicate",
        inactive: "Inactive",
        lost: "Lost",
        notAnswered: "Non répondu",
        onboarding: "Onboarding",
        profileFilled: "Profil rempli",
        salesa: "Sales",
        test: "Test phase",
        trash: "Trash",
      },
    },
    communication: {
      key: "Communication",
      values: {
        bad: "Faible",
        good: "Bonne",
        moderate: "Modérée",
      },
    },
    companyStatus: {
      key: "Company status",
      values: {
        created: "Created",
        duplicate: "Duplicate",
        inactive: "Inactive",
        inProgress: "In Progress",
        missingInformation: "Missing Information",
        no: "Invalid",
        notReached: "Not reached",
        yes: "Yes/Go",
      },
    },
    companyType: {
      key: "Company Status",
      values: {
        facility: "Facility",
        organization: "Organization",
      },
    },
    compressionType: {
      key: "Bas de contension",
      values: {
        bandage: "Bandage",
        socks: "Socks",
      },
    },
    confession: {
      key: "Religion",
      values: {
        catholic: "Catholique",
        jewish: "Juif",
        muslim: "Musulman",
        protestant: "Protestant",
      },
    },
    consultantStatus: {
      key: "Consultant status",
      values: {
        active: "Active",
        created: "Created",
        inactive: "Inactive",
      },
    },
    declinedReason: {
      key: "Raison du refus",
      values: {
        geographicallyUnattractive: "En dehors de la zone d'activité",
        noCapacity: "Pas de disponibilité",
        noCapacityForPatient:
          "Pas de disponibilité pour ce patient en particulier",
        other: "Autre",
        payer: "Pas de place à cause de la situation financière",
      },
    },
    dependencyLevel: {
      key: "Aide aux activités quotidiennes",
      values: {
        aLot: "Élevé",
        none: "Faible",
        some: "Moyen",
      },
    },
    digestiveEndoscopy: {
      key: "Endoscopies digestives",
      values: {
        colonoscopy: "Coloscopie",
        endoscopicUltrasound: "Echo-endoscopie",
        ercp: "CPRE",
        gastroscopy: "Gastroscopie",
      },
    },
    eligibilityRejectReason: {
      key: "Raison",
      values: {
        anotherFormRehaSought: "",
        differentCostPayerResponsible: "",
        otherReason: "Autre raison",
        patientNotEligible: "",
      },
    },
    emailType: {
      key: "Mail type",
      values: {
        accountAdded: "Receiver: Account added",
        accountInvited: "Receiver: Account invited",
        assigneeChanged: "Patient assignee changed",
        attributionCancellation: "Attribution cancellation",
        attributionConfirmation: "Attribution confirmation",
        auctionRequest: "Auction Request",
        encryptedMessage: "Encrypted message sent",
        feedbackSurvey: "Feedback survey",
        newAnswer: "Sender: Accept notification",
        newMessageFromClinic: "Receiver: Message from clinic",
        onboarding: "Self-onboarding email",
        passwordChanged: "Password changed",
        profileEdited: "Patient profiled edited",
        receiverAccepted: "Provider: Accept confirmation",
        receiverRejected: "Receiver: Rejection notification",
        resetPassword: "Passwort reset",
        searchReminder: "Search reminder",
        senderRejectionConfirmation: "Sender: Rejection confirmation",
        testRequest: "Test request",
        testValidation: "Test validation",
        welcomeEmail: "Welcome email",
      },
    },
    employmentStatus: {
      key: "Statut professionnel",
      values: {
        civilServant: "Fonctionnaire",
        employee: "Employé",
        masterForeman: "Maître contremaître",
        notEmployed: "Sans emploi",
        selfEmployed: "Travailleur indépendant",
        semiSkilledWorker: "Employé semi-qualifié",
        skilledWorker: "Ouvrier spécialisé",
        trainee: "Apprenti",
        unskilledEmployee: "Employé non qualifié",
      },
    },
    facility: {
      key: "Type d'hospitalisation",
      values: {
        ambulance: "Ambulance",
        ambulanceCarryChair: "",
        ambulanceLyingDown: "",
        ambulanceWheelchair: "",
        assistedLiving: "",
        companionship: "HAD",
        dailyLivingSupportServices: "",
        dayCare: "Hospitalisation de jour",
        dayHospitalization: "Hospitalisation de jour",
        fullHospitalization: "Hospitalisation complète",
        fullTimeCompanionship: "Présence à domicile 24/7",
        fullTimeMobileCare: "Foyer logement",
        hospice: "",
        hospiceMobile: "",
        hospicePartiallyMobile: "",
        hospiceStatic: "",
        medicalSupplies: "Medical Supplies",
        medicalSuppliesHomeCare: "Homecare",
        mobileCare: "SSIAD/SAD",
        nightCareMobile: "",
        nightCareStatic: "",
        pureIntensiveCare: "Soins intensifs",
        rehabDayHospitalization: "Hospitalisation de jour",
        rehabDayHospitalizationWithTransport:
          "Day Hospitalization with transport",
        rehabFullHospitalization: "Hospitalisation complète",
        rehabMobile: "Hospitalisation à domicile",
        sharedFlat: "Appartements partagés",
        shortTermStaticCare: "ULSD",
        staticCare: "EHPAD",
        taxi: "Taxi",
        taxiCarryChair: "Taxi - Carry chair",
        taxiLyingDown: "Taxi - Lying down",
        taxiWheelchair: "Taxi - Wheelchair",
      },
    },
    fileCategory: {
      key: "Type of file category",
      shortValues: {
        ad: "DA",
        and: "AUT",
        apf: "LDR",
        bix: "",
        btr: "BIO",
        cc: "CFS",
        ctb: "",
        ecg: "ECG",
        err: "CRU",
        fso: "FCS",
        gi: "IB",
        img: "IMG",
        iso: "ICS",
        mdl: "",
        mp: "",
        mr: "",
        msr: "",
        pcr: "PCR",
        pr: "CRA",
        pre: "TRP",
        rba: "",
        rer: "",
        sn: "SIN",
        wp: "DOP",
      },
      values: {
        ad: "Document administratif",
        and: "Autre",
        apf: "Lettre de motivation maison de retraite",
        bix: "",
        btr: "Biologie",
        cc: "Couverture financière des soins",
        ctb: "",
        ecg: "ECG",
        err: "Compte Rendu des Urgences",
        fso: "Formulaire centre social",
        gi: "Infection bactérienne",
        img: "Imagerie/radio",
        iso: "Information centre social",
        mdl: "",
        mp: "",
        mr: "",
        msr: "",
        pcr: "PCR COVID",
        pr: "Compte Rendu antérieur",
        pre: "Traitements habituels",
        rba: "",
        rer: "",
        sn: "SINGER",
        wp: "Documentation sur le soin des plaies",
      },
    },
    fileError: {
      key: "File Error",
      values: {
        fileSize: "",
        fileType: "",
      },
    },
    fileShareMode: {
      key: "File share mode",
      values: {
        all: "Tous les receveurs potentiels",
        none: "Aucun receveur",
        selected: "Les receveurs sélectionnés",
      },
    },
    foodHelp: {
      key: "Type d'aide",
      values: {
        gastrointestinalProbe: "Gastrointestinal probe",
        helpWithEating: "Aide à l'alimentation",
        mealPreparation: "Préparation des repas",
      },
    },
    formYesNo: {
      key: "Choose if true",
      values: {
        no: "non",
        yes: "oui",
      },
    },
    gender: {
      key: "Sexe",
      values: {
        diverse: "Neutre",
        female: "Femme",
        male: "Homme",
      },
    },
    genderExtended: {
      key: "Gender",
      values: {
        diverse: "",
        female: "",
        male: "",
        withoutEntry: "",
      },
    },
    genderLetter: {
      key: "GenderLetter",
      values: {
        female: "F",
        male: "M",
        other: "*",
      },
    },
    grantStatus: {
      key: "Grant status",
      unselectedItems: "Non sélectionnés :",
      values: {
        applied: "Applied for",
        exists: "Exists",
        notApplied: "Not applied for yet",
      },
    },
    grantsType: {
      key: "Payout",
      values: {
        combinationOfBoth: "Combination of both",
        fixedPayout: "Fixed payout",
        serviceBasedPayout: "Service-based payout",
      },
    },
    healthInsuranceType: {
      key: "Assurance santé",
      values: {
        private: "Privée",
        public: "Publique",
      },
    },
    imagery: {
      key: "Imagerie",
      values: {
        ctScan: "TDM",
        dopplers: "Dopplers",
        interventionalRadiology: "Radio interventionnelle",
        mri: "IRM",
        ultrasound: "Echographie",
        xRay: "Radiographie",
      },
    },
    incontinenceHelpType: {
      key: "Type",
      values: {
        diapers: "Couches",
        katheter: "Cathéter",
        stoma: "Stoma",
      },
    },
    infectionAndGerms: {
      key: "Infections",
      values: {
        clostridien: "Clostridien",
        four_mrgn: "EPC (Enterobactérie Productrice de Carbapénémase)\n",
        mrsa: "SARM",
        requires_isolation: "Chambre d'isolement nécessaire",
        three_mrgn: "BLSE",
        vre: "ERV (Entérocoque Résistant à la Vancomycine)\n",
      },
    },
    informationSystems: {
      key: "Information system",
      values: {
        connextVivendi: "Connext Vivendi",
        curasoft: "Curasoft",
        danProdukte: "Dan Produkte",
        godoSystems: "GODO Systems",
        medifox: "Medifox",
        myneva: "myneva",
        noventiCare: "NOVENTIcare",
        other: "Autre",
        senso: "SENSO",
        sinfonie: "Sinfonie",
        snap: "snap",
      },
    },
    injectionType: {
      key: "Injection type",
      values: {
        muscular: "muscular",
        subcutunae: "subcutunae",
      },
    },
    invoiceStatus: {
      key: "Status",
      values: {
        invoiceCreated: "Created",
        invoiceInvalidated: "Invalidated",
        invoicePaid: "Paid",
        invoiceSent: "Sent",
      },
    },
    invoiceType: {
      key: "Type",
      values: {
        remoteWorkshop: "Remote workshop",
        services: "Services",
        servicesAdvance: "Services Advance",
        setupFee: "Setup",
        workshop: "Workshop",
      },
    },
    language: {
      key: "Langue",
      values: {
        afghan: "Afghan",
        albanian: "Albanian",
        amharic: "Amharic",
        arabic: "Arabe",
        azerbaijani: "Azerbaijani",
        bengal: "Bengal",
        bosnian: "Bosnian",
        bulgarian: "Bulgarian",
        cantonese: "Cantonese",
        croatian: "Croate",
        czech: "Czech",
        danish: "Danois",
        dutch: "Hollandais",
        english: "Anglais",
        finnish: "Finlandais",
        french: "Français",
        georgian: "Georgian",
        german: "Allemand",
        greek: "Grec",
        hebrew: "Hebrew",
        hindi: "Hindi",
        hungarian: "Hungarian",
        indonesian: "Indonesian",
        italian: "Italien",
        japanese: "Japonais",
        korean: "Korean",
        kurdish: "Kurdish",
        lithuanian: "Lithuanian",
        mandarin: "Mandarin",
        norwegian: "Norvégien",
        persian: "Persian/Farsi",
        polish: "Polonais",
        portuguese: "Portuguais",
        romanian: "Roumain",
        russian: "Russe",
        serbian: "Serbe",
        sign: "Language des signes",
        slovenian: "Slovenian",
        spanish: "Espagnol",
        swahili: "Swahili",
        swedish: "Suédois",
        thai: "Thai",
        tigrinya: "Tigrinya",
        turkish: "Turc",
        ukrainian: "Ukrainian",
        urdu: "Urdu",
        vietnamese: "Vietnamese",
      },
    },
    livingSituation: {
      key: "Lieu de vie",
      values: {
        livingSituationAlone: "Seul(e) à domicile",
        livingSituationCarehome: "EHPAD",
        livingSituationInElderlyHome: "EHPAD",
        livingSituationOther: "Autre",
        livingSituationSharedFlat: "Foyer logement",
        livingSituationWithFamily: "Accompagné(e) à domicile",
      },
    },
    maritalStatus: {
      key: "Statut matrimonial",
      values: {
        divorced: "Divorcé",
        married: "Mariés",
        single: "Célibataire",
        widowed: "Veuf",
      },
    },
    meals: {
      key: "meals",
      values: {
        breakfast: "breakfast",
        dinner: "dinner",
        lunch: "lunch",
      },
    },
    mentalHealthCommunication: {
      key: "Communication",
      value: {
        bad: "Faible",
        good: "Bonne",
        moderate: "Modérée",
      },
      values: {
        bad: "bad",
        placeholder: "placeholder",
      },
    },
    mobility: {
      key: "Mobilité du patient",
      values: {
        crutch: "Béquilles",
        medicalisedBed: "Lit médicalisé",
        rollator: "Fauteuil roulant",
        walkingFrame: "Déambulateur",
        walkingStick: "Canne(s) anglaise(s)",
        walkWithHelp: "Marche avec aide",
        walkWithoutHelp: "Marche sans aide",
        wheelChair: "Déambulateur à roulettes",
      },
    },
    motivation: {
      key: "Motivation du patient",
      values: {
        bad: "Faible",
        good: "Bonne",
        moderate: "Modérée",
      },
    },
    onboardingStatus: {
      key: "Onboarding Status",
      values: {
        administrationPhase: "Proposal Sent",
        callBackLater: "Call back later",
        chain: "Part of a chain",
        complicated: "Complicated",
        considerationPhase: "Consideration Phase",
        infoPhase: "To Call",
        later: "Later",
        manuallyAdded: "Manually added by sender",
        missingInformation: "Missing information",
        noActionFromCallcenter: "No action from callcenter",
        noComment: "No comment",
        noInterest: "No interest",
        notReached: "Not reached",
        notReachedCallCenter: "Not reached - CC",
        other: "Other",
        preOnboarding: "Leads",
        profileFilled: "Form filled",
        resendOnboardingEmail: "",
        selfOnboarding: "Self-onboarding",
        technicalIssues: "IT issues",
        toCall: "To Call",
      },
    },
    orientation: {
      key: "Orientation du patient",
      values: {
        disoriented: "Ne s'oriente pas",
        limited: "Limitée",
        orientated: "S'oriente",
      },
    },
    otherTechnicalEquipment: {
      key: "Disponible",
      values: {
        dialysis: "",
        monitoringBreathing: "",
        monitoringCirculation: "",
        monitoringMetabolism: "",
      },
    },
    paginationResultCount: {
      key: "Pagination count",
      values: {
        fifty: "",
        ten: "",
        thirty: "",
        twenty: "",
      },
    },
    patientType: {
      key: "PatientType",
      values: {
        care: "EHPAD",
        homeCare: "HAD",
        hospital: "HÔPITAUX",
        medicalSupplies: "Matériel médical",
        rehab: "SMR",
        transport: "TRANSPORT",
      },
    },
    position: {
      key: "position",
      values: {
        accountManagement: "",
        aufnahmemanagement: "",
        belegungsmanagement: "",
        casemanagement: "",
        chefarztIn: "",
        chefarztsekretariat: "",
        controlling: "",
        direktorIn: "",
        disposition: "",
        einrichtungsleitung: "",
        einsatzleitung: "",
        einzugsberatung: "",
        fachbereichsleitungOrRegionalleitung: "",
        geschaftsfuhrung: "",
        gesellschafter: "",
        inhaber: "",
        it: "",
        keyAccountManagement: "",
        oberarztIn: "",
        patientenaufnahme: "",
        pflegedienstleitung: "",
        pflegefachkraft: "",
        projektManager: "",
        qualitatsmanagement: "",
        rehaplanung: "",
        sachbearbeiterIn: "",
        sozialdienst: "",
        stellvPflegedienstleitung: "",
        teamleitung: "",
        vertriebOrVertriebsleitungOrMitarbeiterVertrieb: "",
        verwaltung: "",
        wohnbereichsleitung: "",
        zentraleAufnahme: "",
      },
    },
    priorEmploymentStatus: {
      key: "Statut d'emploi antérieur",
      values: {
        fullTimeNoShifts: "Temps plein sans quart",
        fullTimeWithShifts: "Temps plein avec changements",
        householdCare: "Soins ménagers",
        notEmployedRegistered: "Non employé enregistré",
        notWorking: "Ne fonctionne pas",
        partTimeLessThanHalfDay:
          "Travail à temps partiel, moins de la moitié de la journée de travail normale",
        partTimeMoreThanHalfDay:
          "Travail à temps partiel, plus de la moitié de la journée de travail normale",
        wihtNightShifts: "Travailler avec des équipes de nuit",
        workAtHome: "Travail à la maison",
        workshopForDisabledPeople: "Atelier pour personnes handicapées",
      },
    },
    providerSearchNavTitle: {
      key: "providerSearchNavTitle",
      values: {
        bavaria: "Pflegefinder Bayern",
      },
    },
    providerSearchRequestStatus: {
      key: "Provider search request status",
      values: {
        accepted: "",
        attributed: "",
        created: "",
        declined: "",
        rejected: "",
        sent: "",
        unavailable: "",
      },
    },
    receiverInactiveReason: {
      key: "Inactive Reason",
      values: {
        asklepiosInhouseSolution: "Asklepios Inhouse Solution",
        churned: "Churned",
        closed: "Closed",
        higherLevelDecision: "",
        medianInhouseSolution: "Median Inhouse Solution",
        neverReachedAgain: "Never reached",
        noAccount: "No Account",
        noInterestDoNotContact: "No interest - Do not contact",
        noInterestMaybeLater: "No interest - Maybe later",
        temporaryClosed: "Temporary closed",
        unneededChainCenter: "headquarters inactive - subsidiary active",
        unneededChainSubsidiary: "headquarters active - subsidiary inactive",
        unsupportedFacility: "Unsupported facility",
      },
    },
    receiverTypePlural: {
      key: "Receiver Type (Plural)",
      values: {
        care: "établissements receveurs",
        homeCare: "Fournisseurs",
        hospital: "établissements receveurs",
        medicalSupplies: "Fournisseurs",
        rehab: "établissements receveurs",
        transport: "transporteurs",
      },
    },
    receiverTypePluralPreferred: {
      key: "Receiver Type (Plural)",
      values: {
        care: "établissements privilégiés",
        homeCare: "fournisseurs préférés",
        hospital: "établissements privilégiés",
        medicalSupplies: "fournisseurs préférés",
        rehab: "établissements privilégiés",
        transport: "transporteurs privilégiés",
      },
    },
    receiverTypeSingular: {
      key: "Receiver Type (Plural)",
      values: {
        care: "établissement receveur",
        homeCare: "Fournisseur",
        hospital: "établissement receveur",
        medicalSupplies: "Fournisseur",
        rehab: "établissement receveur",
        transport: "transporteur",
      },
    },
    receiverTypeSingularPreferred: {
      key: "Receiver Type (Plural)",
      values: {
        care: "établissement privilégié",
        careAb: "",
        homeCare: "fournisseur préféré",
        hospital: "établissement privilégié",
        medicalSupplies: "fournisseur préféré",
        rehab: "établissement privilégié",
        transport: "transporteur privilégié",
      },
    },
    rehabilitationPotential: {
      key: "Potentiel de récupération",
      values: {
        evaluationExtremelyNegative: "Très négatif",
        evaluationExtremelyPositive: "Très positif",
        evaluationModerate: "Modéré",
        evaluationNegative: "Négatif",
        evaluationPositive: "Positif",
      },
    },
    reliefServices: {
      key: "Allocation logement (APL/ALS) ",
      values: {
        paragraphA: "According to §45a SGB XI",
        paragraphB: "According to §45b SGB XI",
      },
    },
    roomType: {
      key: "Type de chambre",
      values: {
        accompanyingChild: "Avec un enfant",
        accompanyingPerson: "Avec un accompagnant",
        accompanyingSibling: "Avec frere ou soeur",
        apartment: "Appartement",
        barrierFree: "Chambres sans barriere",
        double: "Chambre double",
        no_preference: "Aucune préférence",
        single: "Chambre simple",
      },
    },
    rowsPerPage: {
      key: "Rows per page",
      values: {
        fifteenRows: "15 per page",
        fiftyRows: "50 par page",
        tenRows: "10 par page",
        thirtyRows: "30 par page",
      },
    },
    salutation: {
      key: "Civilité",
      values: {
        madame: "Madame",
        monsieur: "Monsieur",
        other: "*",
      },
    },
    searchPageTableTitle: {
      key: "Table titles",
      values: {
        accepted: "Accepté",
        contacted: "Contacté",
        declined: "Treatment not possible",
        filtered: "Filtered",
        potential: "Potential",
        rejected: "Rejected",
        validated: "Vous avez sélectionné cet établissement d'accueil",
      },
    },
    searchTableStatusChips: {
      key: "searchTableStatusChips",
      values: {
        accepted: "Accepté",
        faxSent: "Fax envoyé",
        newFile: "Nouveau fichier",
        newMessage: "Nouveau message",
        notSeen: "Non vu",
        ongoingChat: "Conversation en cours",
        providerInactive: "Inactif",
        requestNotSeen: "Demande non vue",
        requestSeen: "Demande vue",
        seen: "Vu",
        sentMessageNotSeen: "Message non vu",
        sentMessageSeen: "Message vu",
      },
    },
    service: {
      key: "Services",
      values: {
        basicCare: "Aide à la personne",
        basicMedicalCare: "Soins infirmiers",
        children: "Enfants",
        closedArea: "Zone sécurisée",
        companionship: "Soutien social et psychologique",
        corona: "Corona",
        dementia: "Alzheimer",
        dialysis: "Patients avec dialyse",
        enteralNutrition: "Enteral nutrition",
        hearingImpaired: "Convient au handicap auditif",
        helpToBreathe: "Trachéotomie",
        helpWithEating: "Help with eating",
        invasiveVentilation: "Invasive ventilation",
        monitoringBreathing: "Monitoring: Breathing",
        monitoringCirculation: "Monitoring: Circulation",
        monitoringMetabolism: "Monitoring: Metabolism",
        noneOfTheFollowingServices: "None of the following services",
        nonInvasiveVentilation: "Non-invasive ventilation",
        obesity: "Obésité",
        oxygenTherapy: "Oxygen therapy",
        palliativeCare: "Soins palliatifs",
        palliativeCertificate: "Certificat en soins palliatifs",
        parenteralNutrition: "Alimentation parenterale",
        patientsBelow70: "Patients < 70 ans",
        patientsOver70: "Patients > 70 ans",
        peopleWhoAreDangerous:
          "Suitable for people who are dangerous to self or others",
        peopleWithAddictions: "Suitable for people with addictions",
        peopleWithOrientationDisorder:
          "Suitable for people with orientation disorder",
        protectedArea: "Protected area",
        psychiatricCare: "Soins psychiatriques",
        specialisedIntensiveNurse: "Appareillage ventilatoire (CPAP, VNI)",
        staticCareDisabledPatients: "",
        takingOverBodyCare: "Taking over body care",
        visuallyImpaired: "Convient au handicap visuel",
      },
    },
    serviceExplanations: {
      key: "Service Explanations",
      values: {
        basicCare: "",
        basicMedicalCare: "",
        companionship: "",
        corona: "",
        dementiaCare: "",
        intensiveCare: "",
        palliativeCare: "",
        protectedArea: "",
        psychiatricCare: "",
        sapv: "",
        staticCareForDisabledPatients: "",
        tracheostomy: "",
      },
    },
    shareModes: {
      key: "Share mode",
      values: {
        all: "",
        none: "",
        selected: "",
      },
    },
    shortTermGrant: {
      key: "Short Term Grants",
      values: {
        combination:
          "Other: combination of preventative care and short term care",
        paragraph39: "According to §39c SGB V",
        paragraph42: "According to §42 SGB XI",
      },
    },
    solution: {
      key: "Type d'hospitalisation",
      shortValues: {
        ambulance: "",
        ambulanceCarryChair: "",
        ambulanceLyingDown: "",
        ambulanceWheelchair: "",
        assistedLiving: "",
        dailyLivingSupportServices: "",
        dayCare: "Hospitalisation de jour",
        dayHospitalization: "Hospitalisation de jour",
        fullHospitalization: "Hospitalisation complète",
        fullTimeMobileCare: "Foyer logement",
        helpAtHome: "SSIAD/SAD",
        homeCare: "Home care",
        hospice: "",
        hospiceMobile: "",
        hospicePartiallyMobile: "",
        hospiceStatic: "",
        medicalSupplies: "Medical supplies",
        mobileCare: "HAD",
        nightCareMobile: "",
        nightCareStatic: "",
        rehabDayHospitalization: "Hospitalisation de jour",
        rehabDayHospitalizationWithTransport: "",
        rehabFullHospitalization: "Hospitalisation complète",
        rehabMobile: "Hospitalisation à domicile",
        sharedFlat: "Shared Flat",
        shortTermStaticCare: "ULSD",
        staticCare: "EHPAD",
        taxi: "Taxi",
        taxiCarryChair: "Carrying chair",
        taxiLyingDown: "Lying down",
        taxiWheelchair: "Wheelchair",
      },
      values: {
        ambulance: "Ambulance",
        ambulanceCarryChair: "",
        ambulanceLyingDown: "",
        ambulanceWheelchair: "",
        assistedLiving: "",
        dailyLivingSupportServices: "",
        dayCare: "Hospitalisation de jour",
        dayHospitalization: "Hospitalisation de jour",
        fullHospitalization: "Hospitalisation complète",
        fullTimeMobileCare: "Foyer logement",
        helpAtHome: "UVP",
        homeCare: "",
        hospice: "",
        hospiceMobile: "",
        hospicePartiallyMobile: "",
        hospiceStatic: "",
        medicalSupplies: "",
        mobileCare: "Foyer logement",
        nightCareMobile: "",
        nightCareStatic: "",
        rehabDayHospitalization: "Hospitalisation de jour",
        rehabDayHospitalizationWithTransport: "",
        rehabFullHospitalization: "Hospitalisation complète",
        rehabMobile: "Hospitalisation à domicile",
        sharedFlat: "Colocation",
        shortTermStaticCare: "ULSD",
        staticCare: "EHPAD médicalisé",
        taxi: "Taxi",
        taxiCarryChair: "Taxi - Carrying chair",
        taxiLyingDown: "Taxi - Lying down",
        taxiWheelchair: "Taxi - Wheelchair",
      },
    },
    solutionBToC: {
      key: "Solutions",
      values: {
        helpAtHome: "",
        mobileCare: "",
        shortTermStaticCare: "",
        staticCare: "",
      },
    },
    specializations: {
      key: "Spécialité(s) ",
      values: {
        acuteGeriatrics: "Gériatrie aigüe ",
        addiction: "Addiction",
        addictology: "Addictologie",
        adipositas: "Adipositas",
        allergology: "Allergologie",
        anesthesiology: "Anesthésiologie",
        angiology: "Médecine vasculaire",
        cardiologicalEarlyRehabilitation: "Cardiological Early Rehabilitation",
        cardiology: "Cardiologie",
        cognitiveBehavioralUnit: "Psychogériatrie/UCC",
        cognitiveBehavioural: "Psychogériatrie/UCC",
        comaVigil: "Coma vigil",
        compressionTherapy: "Compression therapy",
        decubitusProphylaxis: "Decubitus prophylaxis",
        dermatology: "Dermatologie",
        diabeticFoot: "Pied Diabétique",
        diabetology: "Diabétologie",
        dialysis: "Dialyse",
        digestiveSurgery: "Chirurgie digestive",
        earlyRehab: "Covid",
        endocrinology: "Endocrinologie",
        ent: "ORL",
        enteralNutrition: "Enteral nutrition",
        gastroenterology: "Gastro-entérologie",
        generalSurgery: "Chirurgie générale",
        geriatrics: "Gériatrie aigüe",
        gynaecology: "Gynécologie et obstétrique",
        haematology: "Hématologie",
        hepatology: "Hépatologie",
        homecareOncology: "Oncology",
        icuCardiology: "USI Cardiologie",
        icuCorona: "Réanimation médicale",
        icuECMOCorona: "Unité de surveillance continue (USC)",
        icuGastrology: "USI Gastro",
        icuHighCorona: "Covid (réanimation)",
        icuNeurovascular: "USI Neurovasculaire / Neurovasculaire",
        incontinence: "Incontinence absorbing",
        incontinenceDischarging: "Incontinence discharging",
        infectiousDisease: "Maladies infectieuses",
        infusionTherapy: "Infusion therapy",
        internalMedicalEarlyRehabilitation:
          "Internal Medical Early Rehabilitation",
        internalMedicine: "Médecine interne/polyvalente ",
        laryngectomyTherapy: "Laryngectomy therapy",
        maxillofacialSurgery: "Chirurgie maxillo-faciale",
        medicalReanimation: "Réanimation médicale",
        monitoring: "Monitoring",
        nephrology: "Néphrologie",
        neurology: "Neurologie",
        neuroPediatry: "Neuropédiatrie",
        neurosurgery: "Neurochirurgie",
        normalStation: "Covid (non réanimatoire)",
        nuclearMedicine: "Médecine nucléaire",
        obesity: "Obesity",
        oncology: "Oncologie",
        oncologyBreastGlands: "Oncology - breast gland",
        oncologyCentralNervousSystem: "Oncology - central Nervous System",
        oncologyDigestiveOrgans: "Oncology - digestive organs",
        oncologyFemaleGenital: "Oncology - female Genital",
        oncologyLocomotorOrgans: "Oncology - locomotor organs",
        oncologyMalignantSystemicDiseases:
          "Oncology - malignant systemic diseases",
        oncologyPharync: "Oncology - pharynx, larynx",
        oncologyRespiratoryOrgans: "Oncology - respiratory organs",
        oncologySkin: "Oncology - skin",
        oncologyThyroid: "Oncology - thyroid gland",
        oncologyUrinaryKidneyMaleGenitals:
          "Oncology - kidney, urinary tract and male genitals",
        ophthalmology: "Ophtalmologie",
        orthogeriatrics: "Orthogériatrie (UPOG)",
        orthopedics: "Orthopédie",
        orthopedicsLowerLimbs: "Orthopédie - Membres inférieurs",
        orthopedicsSosMains: "Orthopédie - SOS Mains",
        orthopedicsSpine: "Orthopédie - Rachis",
        orthopedicsUpperLimbs: "Orthopédie - Membres supérieurs",
        oxygen: "Oxygen",
        paediatrics: "Paediatrics",
        painMedicine: "Pain Medicine",
        painTherapy: "Pain therapy",
        palliativeCare: "Soins palliatifs",
        parenteralNutrition: "Parenteral nutrition",
        parentsAndChildRehab: "Enfants et adolescents",
        pediatrics: "Pédiatrie",
        phaseB: "Phase B",
        phaseC: "Phase C",
        phaseCplus: "Neurology Phase C+",
        phaseD: "Phase D",
        physicalMedicine: "Médecine physique et de réadaptation",
        plasticSurgery: "Chirurgie plastique",
        pneumologicalEarlyRehabilitation: "Pneumological Early Rehabilitation",
        pneumology: "Pneumologie",
        psychiatry: "Psychiatrie",
        psychosomatic: "Psychosomatique",
        radiology: "Radiologie",
        respiratoryTherapy: "Respiratory therapy",
        rheumatism: "Rhumatologie",
        rheumatology: "Rhumatologie",
        rhythmology: "Rythmologie",
        stoma: "Stoma",
        surgicalResuscitation: "Réanimation chirurgicale",
        thoracicSurgery: "Chirurgie thoracique",
        tracheostomyTherapy: "Thérapie de trachéotomie",
        traumaSurgery: "Chirurgie traumatique",
        urology: "Urologie",
        vascularSurgery: "Chirurgie vasculaire",
        ventilationOxygen: "Ventilation",
        weaning: "Weaning",
        woundCare: "Wound care",
      },
    },
    tableSorting: {
      key: "tableSorting",
      values: {
        sortByDefault: "Défaut",
        sortByDistance: "Distance",
        sortByName: "Nom",
        sortByStatus: "",
      },
    },
    technicalEquipment: {
      cardiovascularExploration: {
        cardiacMri: "IRM cardiaque",
        coronaryScanner: "Coroscanner",
        exerciseTest: "Epreuve d’effort",
        holterEcg: "Holter-ECG",
        rhythmology: "Rythmologie",
        toe: "ETO",
        tte: "ETT",
      },
      digestiveEndoscopy: {
        colonoscopy: "Coloscopie",
        endoscopicUltrasound: "Echo-endoscopie",
        ercp: "CPRE",
        gastroscopy: "Gastroscopie",
      },
      imagery: {
        ctScan: "TDM",
        dopplers: "Dopplers",
        interventionalRadiology: "Radio interventionnelle",
        mri: "IRM",
        ultrasound: "Echographie",
        xRay: "Radiographie standard",
      },
      key: "key",
      otherAvailableEquipment: {
        dialysis: "",
        monitoringBreathing: "",
        monitoringCirculation: "",
        monitoringMetabolism: "",
      },
      values: {
        test: "-1",
      },
    },
    trafficLight: {
      key: "Traffic Light",
      values: {
        green: "",
        orange: "",
        red: "",
      },
    },
    transitionalCareRequestStatus: {
      key: "Statut de la demande",
      values: {
        accepted: "Acceptation",
        declined: "Reject",
        rejected: "Rejet",
        sent: "Pas de réponse",
      },
    },
    transportType: {
      key: "Type de transport",
      values: {
        ambulanceLyingDown: "Ambulance légère",
        ambulanceSitting: "Ambulance de soins intensifs",
        emergencyVehicle: "Véhicule d'urgence",
        helicopter: "Hélicoptère",
        personalCar: "Véhicule privé",
        publicTransport: "VSL",
        taxi: "Taxi",
      },
    },
    universalSearch: {
      key: "Ressources",
      values: {
        account: "Account",
        company: "Company",
        consultant: "Consultant",
        receiver: "Receiver",
        sender: "Sender",
      },
    },
    vigilance: {
      key: "Vigilance",
      values: {
        agitated: "Agité",
        awake: "Éveillé",
        calm: "Calme",
        comatose: "État comateux",
        responsive: "Réactif",
        somnolent: "Somnolant",
        soporific: "Endormi",
      },
    },
  },
  patient: {
    abortCreation: {
      actionButton: "Abandonner la création du nouveau patient ",
      modalSubtitle:
        "En archivant le profil, ce dernier ne sera plus accessible depuis le tableau de bord, mais vous pourrez le retrouver grâce à la barre de recherche.",
      modalTitle: (args: { patientId: string }) =>
        template(
          "Êtes-vous sûr(e) de vouloir quitter le profil patient {{patientId}} ?",
          args,
        ),
      quitAndArchive: "Oui, archiver",
      saveAsDraft: "Sauvegarder comme brouillon",
      test: "",
    },
    accompanyingPerson: "Personne accompagnante",
    addCareprovider: {
      addTitle: (args: { receiverTypeSingular: string }) =>
        template("Ajouter {{receiverTypeSingular}}", args),
      contactAll: "contacter tous",
      contactingByFax: "",
      distance: "Distance",
      enterNamePlaceholder: {
        care: "Entrez le nom de l'établissement",
        geriatrics: "Entrez le nom ou l'adresse de l'établissement",
      },
      errorContactRecare: "Veuillez contacter Recare",
      errorNoSolution: (args: { solution: string }) =>
        template("N'offre pas : {{solution}}", args),
      errorProviderCannotBeContacted: "",
      errorProviderCreated: "Établissement en cours d'enregistrement",
      errorProviderNotActive: "Établissement non enregistré sur Recare",
      errorRequestAccepted: "Cette demande a été acceptée.",
      errorRequestDeclined: (args: { message: string }) =>
        template('Cette demande a été déclinée : "{{message}}"', args),
      errorRequestRejected:
        "Vous avez rejeté cet établissement\nVeuillez retourner sur la page de recherche et annuler votre décision",
      errorRequestSent: (args: { date: string }) =>
        template(
          "Une demande a été envoyée le {{date}}, mais vous n'avez pas encore reçu de réponse",
          args,
        ),
      errorRequestSentAutomaticSearch:
        "Une demande a été envoyée, mais vous n'avez pas encore reçu de réponse.",
      errorRequestWaitingResponse: "Vous avez reçu un message",
      errorWhitelisted: "",
      explanation: "Taper le nom de l'établissement ou l'adresse ci-dessous. ",
      explanationSearchStopped: (args: { receiverTypeSingular: string }) =>
        template(
          "L'{{receiverTypeSingular}} peut être ajouté ici. Taper le nom de l'établissement ou l'adresse ci-dessous. ",
          args,
        ),
      form: {
        explanation: (args: { receiverTypeSingular: string }) =>
          template(
            "Malheureusement nous ne trouvons pas cet {{receiverTypeSingular}} dans notre système. Veuillez nous donner les informations nécessaires afin que l'on puisse l'enregistrer au plus vite. ",
            args,
          ),
        title: (args: { receiverTypeSingular: string }) =>
          template(
            "Les informations pour cet {{receiverTypeSingular}} sont nécessaires. Veuillez l'indiquer ",
            args,
          ),
      },
      formExplanation: {
        care: "Malheureusement nous n'avons pu trouver cet établissement dans notre système.\nMerci de nous donner les informations nécessaires afin que nous puissions l'enregistrer au plus vite dans notre système.",
        geriatrics:
          "Malheureusement nous n'avons pu trouver cet établissement dans notre système.\nMerci de nous donner les informations nécessaires afin que nous puissions l'enregistrer au plus vite dans notre système.",
      },
      formTitle: {
        care: "Veuillez entrer les informations",
        geriatrics: "Veuillez entrer les informations de l'établissement",
      },
      goBack: "Retour à la recherche",
      goToAnswer: "Voir la demande",
      insuranceBlacklisted:
        "Ce récepteur ne prend pas en charge les patients avec le payeur sélectionné",
      lookForAdditionalSpecialsations: "Look for additional specialisations",
      medicalCare: "",
      noBedsAvailable: "Pas de lits disponibles",
      notFound: {
        care: "Pas de résultats ?",
        geriatrics: "Pas de résultats ?",
      },
      placeholderEnterName: "Entrez le nom ou l'adresse:",
      pleaseGoToAnswer:
        "Veuillez accepter cet établissement depuis la page de réponse",
      providerAddedExplanation:
        "Nous avons reçu les informations concernant cet établissement.",
      providerAddedExplanation1: {
        care: "Nous avons reçu les informations concernant votre établissement d'accueil privilégié.",
        geriatrics:
          "Nous avons reçu les informations concernant votre établissement privilégié.",
      },
      providerAddedExplanation2: {
        care: "Nous allons ajouter cet établissement d'accueil dans notre système au plus vite. Une fois enregistré, nous pourrons lui envoyer les futures demandes.",
        geriatrics:
          "Nous allons ajouter cet établissement dans notre système au plus vite. Une fois enregistré, nous pourrons lui envoyer les futures demandes.",
      },
      providerAddedExplanationBis: (args: { receiverTypeSingular: string }) =>
        template(
          "Recare va ajouter cet {{receiverTypeSingular}} dans le système au plus vite. Cependant, tant que le receveur n'est pas enregistré chez nous, les demandes ne lui sont pas envoyées. ",
          args,
        ),
      providerAddedTitle: {
        care: "Les informations ont été sauvegardées",
        geriatrics: "L'établissement a été sauvegardé",
      },
      providerNotFound: (args: { receiverTypeSingular: string }) =>
        template("Vous ne trouvez pas l'{{receiverTypeSingular}} ?", args),
      providerSavedTitle: (args: { receiverTypeSingular: string }) =>
        template("L'{{receiverTypeSingular}} a été sauvegardé", args),
      providersFiltered: "Receveur(s) filtré(s)",
      providerThrottled: "Cet établissement a reçu trop de demandes",
      reason: "Raison",
      rejectedByCostPayer: "",
      requestDate: "Date",
      requestsSent: "Demande(s) envoyée(s)",
      searchBarPlaceholder: "Entrez le nom ou l'adresse de l'établissement",
      searchCareproviderExplanation: {
        care: "Recare envoie les demandes selon la pertinence de chaque établissement. Si vous souhaitez ajouter un établissement privilégié, vous pouvez entrer son nom ci-dessous :",
        geriatrics:
          "Si vous souhaitez envoyer une demande manuellement, entrez le nom de l'établissement ici :",
      },
      searchCareproviderExplanationSearchStopped: {
        care: "Si vous souhaitez envoyer une demande manuellement, entrez le nom de l'établissement ici :",
        geriatrics:
          "Si vous souhaitez envoyer une demande manuellement, entrez le nom de l'établissement ici :",
      },
      searchNotStartedHint:
        "La recherche n'a pas encore été lancée, si vous souhaitez contacter tous les prestataires disponibles, cliquez sur contacter tous ou uniquement sur ce prestataire, cliquez sur Ajouter",
      selectProviderNotStartedSearch:
        'La recherche n\'a pas encore été lancée. Cliquez sur "Ajouter" pour envoyer la demande à cette institution et lancer la recherche.',
      selectProviderToSendRequest: "",
      selectSolutionExplanation:
        "Veuillez sélectionner le type de soin souhaité.",
      sendRequest: "Envoyer la demande",
      smartSuggestionsWarningHint:
        'Si vous souhaitez tout de même ajouter cet établissement à votre recherche, cliquez sur "Ajouter" ci-dessous. Veuillez cependant noter qu\'aucune demande ne sera envoyée à ce receveur via Recare. ',
      stopSearchWarningHint:
        'Si vous souhaitez attribuer manuellement le patient à ce receveur, cliquez sur "Confirmer" ci-dessous.',
      title: {
        care: "Ajouter une préférence",
        geriatrics: "Ajouter une préférence",
        warningWeight: (args: { maxWeight: string }) =>
          template(
            "Le receveur ne prend que des patients dont le poids ne dépasse pas {{maxWeight}} kg.",
            args,
          ),
      },
      warningAge: (args: { minAge: string; maxAge: string }) =>
        template(
          "Le receveur ne prend que des patients entre {{minAge}} et {{maxAge}} ans",
          args,
        ),
      warningBlacklist: "Établissement non contacté",
      warningDistance: "L'établissement est en dehors du rayon de recherche",
      warningDistanceCareproviderRadius:
        "Recherche en dehors du rayon de traitement du receveur ",
      warningNoCapacity: (args: { startDate: string }) =>
        template("Pas de place avant le {{startDate}}", args),
      warningNoService: (args: { service: string }) =>
        template(
          "Les services suivants ne peuvent être offerts : {{service}}",
          args,
        ),
      warningNotCatchment:
        "L'adresse du patient n'est pas dans la zone d'activité",
      warningPrerequisite: (args: { service: string }) =>
        template("Les services suivant sont requis: {{service}}", args),
      warningSpecialisation:
        "This receiver only takes in patients of another specialisation",
      warningWhitelist: "Établissement ne fait pas partie de la plate-forme",
      willRestartSearchHint: "L'envoi à ce fournisseur relancera la recherche.",
      willStartSearchHint: "L'envoi à ce fournisseur lancera la recherche.",
    },
    additionalExaminations: "Examens complémentaires à prevoir",
    additionalInformation: "",
    admissionDate: "Date de début de séjour",
    age: "Âge",
    agitationOrientation: "Agitation / Agressivité / Déambulation",
    alertTitle: "Impossible de charger le patient",
    autonomy: "Autonomie habituelle",
    backfillStationNumber: {
      hint: "Veuillez indiquer le numéro de votre service adresseur",
      link: "Numéro manquant ou incorrect ?",
      placeholder: "Numéro de votre service adresseur",
      title: (args: { station: string }) =>
        template(
          "Indiquer le numéro de votre service adresseur {{station}}",
          args,
        ),
    },
    barrierFreeRoom: "Chambre sécurisée",
    candidates: "Établissements potentiels",
    careDuration: "Durée des soins",
    carelevel: "GIR",
    careNeeds: {
      accessDrainages: {
        accessAndDrainages: "Soins techniques",
        centralVenousCatheter: "Cathéter veineux central (CVC)",
        drainage: "Drainage",
        gastricTube: "Sonde naso-gastrique ",
        inputPlaceholder: "Préciser l’indication ",
        other: "Autre",
        otherAccessDrainages: "Autre",
        otherPlaceholder: "Veuillez spécifier",
        peripheralVenousCatheter: "Cathéter veineux périphérique (PVC, Viggo)",
        permanentCatheter: "Cathéter permanent",
        suprapubicCatheter: "Cathéter suprapubique (SPC)",
        trachealCannula: "Canule trachéale",
      },
      baseCare: "Soins basiques",
      baseCarePlaceholder:
        "i.e. fréquence, aide à la toilette, à l'habillage, etc.",
      breathing: "Assistance respiratoire",
      carePaidByHealthInsurance: "",
      circulation: {
        label: "Circulation sanguine",
        placeholder: "i.e. pression artérielle, etc",
      },
      companionship: "Contact social",
      companionshipGrant: "",
      companionshipPlaceholder: "i.e. promenade, discussion, jeux, etc.",
      compression: "Bas de contention",
      compressionPlaceholder: "i.e. type etc.",
      dialysis: "Dialyse",
      dysphagia: {
        label: "Dysphagie",
        placeholder: "i.e gel épaississant, aide à l'alimentation, etc.",
      },
      enteralNutrition: {
        label: "Nutrition entérale",
        placeholder: "i.e. type de sonde, voie, etc.",
      },
      fecalIncontinence: "Incontinence fécale",
      fecalIncontinencePlaceholder: "i.e. stomie etc.",
      helpAtHome: "Service d'aide à domicile",
      helpAtHomePlaceholder: "i.e. repas, courses, etc.",
      hemodialysis: {
        label: "Hémodialyse",
        placeholder: "i.e. fréquence",
      },
      incontinence: "Incontinence",
      injection: "Injections",
      injectionPlaceholder:
        "i.e. fréquence, intraveineuse, sous-cutanée, intramusculaire etc.",
      medicalCare: "",
      medicalCarePlaceholder: "",
      medication: "Médicaments particuliers",
      medicationPlaceholder: "i.e. Tazocilline, Chimiothérapie, Anti-VIH etc",
      metabolism: {
        label: "Métabolisme",
        placeholder: "i.e. glycémie",
      },
      monitoring: "Monitoring",
      needSupport: "Le patient a besoin d'aide",
      nutrition: "Alimentation",
      oxygenTherapy: {
        label: "Oxygène (L/mn)",
        placeholder: "L/mn ",
      },
      parenteralNutrition: {
        label: "Nutrition parentérale",
        placeholder: "i.e. voie centrale, voie périphérique, etc.",
      },
      peritonealDialysis: {
        label: "Dialyse péritonéale ",
        placeholder: "i.e. fréquence",
      },
      placeholderImportantInformationCare: "e.g. Frequency of care, tasks",
      psychiatric_care: {
        label: "Soins psychiatriques",
        placeholder: "eg. schizophrénie, borderline, bipolarité, etc.",
      },
      remarks: "Autres remarques",
      respiration: {
        label: "Respiration",
        placeholder: "",
      },
      stepperTitle: "Thérapeutique",
      stepperTitleCare: "Thérapeutique",
      takesChemoTherapy: "",
      takesExpensiveMedication: "",
      titleImportantInformationCare: "Important information for care",
      tracheostomy: {
        label: "Trachéotomie",
        placeholder: "i.e. veuillez spécifier",
      },
      urinaryIncontinence: "Incontinence urinaire",
      urinaryIncontinencePlaceholder:
        "i.e. protection, sonde à demeure, sondages intermittents etc.",
      withBodyCare: "",
      withDressing: "Pour s'habiller",
      withEating: "Pour l'alimentation",
      woundCare: "Escarre(s)",
      woundCarePlaceholder: "i.e. localisation etc.",
    },
    careNeedsRequired: "Thérapeutique",
    caseIdTitle: "Bitte geben Sie die Krankenhausfallnummer des Patienten an",
    closedAreaNeeded: "Zone sécurisée nécessaire",
    complexityPatient: "",
    comprehensiveRehabPatientProfile: "Long patient profile necessary",
    coronaWarning: "",
    costPayerPartnerInfoBox: {
      bodyAvailable: "",
      bodyNotAvailable: "",
      linkToFaq:
        "For more information about our partner payers who are already on board, {select:visit our FAQs}.",
      titleAvailable: "",
      titleNotAvailable: "",
    },
    costpayerSunsetInfoBox: {
      body: "",
      title: "",
    },
    countrySearch: "Recherche à l'échelle régionale",
    covidPositive: "Test Covid-19 positif",
    created: "a été créé",
    createPatient: "Créer un nouveau patient",
    createPatientConfirmation:
      "Veuillez noter l'identifiant Recare sur vos documents ou dans votre système.",
    createPatientDatesError:
      "La date d’entrée ne peut être après la date de sortie prévue",
    createPatientError: "Impossible de créer le patient",
    createPatientLoading:
      "Nous vous remercions de votre patience pendant la création du patient",
    dateOfBirth: "Date de naissance",
    dateOfBirthFormat: "JJ/MM/AAAA",
    declarationOfConsent:
      "L’information du patient ou de la famille sur le traitement de ses données personnelles par Recare, ou le recueil de son consentement le cas échéant, a bien été réalisée ",
    deleteAuction: {
      stopSearch: "Annuler",
    },
    deletePatient: {
      confirmText: (args: { patientName: string }) =>
        template("Souhaitez-vous archiver le profil {{patientName}} ?", args),
      title: "Archiver le profil",
    },
    dementia: "Alzheimer",
    dichargeDate: "Date de sortie",
    diffFields: {
      careNeeds: {
        care: "Soins spécifiques",
        geriatrics: "Soins spécifiques",
      },
      diagnosis: "Diagnostic médical",
      mentalHealth: "Fonctions supérieures",
      mobility: "Mobilité",
      patientCondition: "Soins techniques ",
      profile: "Information Patient",
      searchRadius: "Rayon de recherche",
      socialWorker: "Personne en charge du transfert",
      solutions: "Prise en charge",
      station: "Service",
      transport: "Transport",
    },
    directTransfer: "Transfert direct",
    directTransferNecessary: "Transfert direct nécessaire",
    dischargeConfirmation: {
      centralEmail: "Adresse email centrale",
      contactInformation: "Coordonnées",
      footer: {
        confirmation: "",
        confirmationOther: "",
        title: "",
        titleOther: "",
      },
      startDate: "Date de début",
      transferConfirmation: (args: {
        patientId: string;
        providerName: string;
      }) =>
        template(
          "Confirmation de la prise en charge du {select:Patient {{patientId}}} par {select:{{providerName}}}",
          args,
        ),
    },
    distanceFromYou: (args: { distance: string }) =>
      template("situé à {{distance}} de vous", args),
    doctorInCharge: "Docteur en charge ",
    eatAlone: "Aide pour s'alimenter",
    editAssessmentMessage: "Les changements ont bien été sauvegardés",
    editAssessmentMessageGeneric:
      "Les changements ont été pris en compte. Veuillez noter que des changements peuvent affecter les établissements qui peuvent être contactés.",
    editSearchProviderListUpdateBody:
      "Veuillez noter que ces changements peuvent affecter les receveurs qui peuvent être contactés.",
    editSearchProviderListUpdateTitle: "Modifications du profil du patient",
    editSearchWarning:
      "Suite aux changements que vous venez d'effectuer sur le profil patient, les personnes suivantes seront informées par email que la demande n'est plus disponible :",
    electivePatientNotInHospitalYet: "",
    eligibilityTitle: {
      eligible: "",
      inProcess: "",
      rejected: "",
      toBeProcessed: "",
    },
    encryptedFieldSecure:
      'Pour communiquer ces données sensibles aux établissements receveurs, cliquez sur "Donner accès aux données personnelles" dans la messagerie.',
    encryptionWithIE:
      "Le chiffrement des données n'est pas disponible pour Internet Explorer. Veuillez utiliser un autre browser comme Google Chrome ou Mozilla Firefox.",
    enterGermanPensionInsuranceName: "Nom de l'assurance pension allemande",
    enterHealthInsuranceName: "Précisez la mutuelle",
    errorLoading: "Impossible de charger la page",
    errorLoadingUrlChanges: "",
    errorSaveAuction: "Impossible de sauvegarder",
    expectedAdmitDate: "Date de début prévue",
    expectedDischargeDate: "Date de sortie prévue",
    expectedStartDate: "Date de transfert souhaitée",
    expectedStartDateBeforeDischargeDateError:
      "La date de sortie prévue ne peut être après la date de transfert souhaitée",
    expectedStartDateBeforeDischargeDateErrorForStartDate:
      "La date de transfert souhaitée ne peut être avant la date de sortie prévue.",
    expeditedRequest: "En procédure d'urgence",
    externalId: "Identifiant interne",
    facilityType: "Type d'établissement",
    firstName: "Prénom",
    flexible: "(flexible)",
    flexibleDate: "Date de transfert souhaitée flexible",
    formGeneration: {
      alreadySent: "",
      alreadySentModal: {
        hint: "",
        title: "",
      },
      bedOverlength: "",
      beforeRetirementPension: "",
      beforeRetirementPensionTypeOfService: "",
      birthDate: "",
      birthDateDateFormat: "(JJ.MM.AAAA)",
      cancelModal: {
        areYouSure: "",
        changesWontBeSaved: "",
        confirmNo: "",
        confirmYes: "",
        content: "",
        title: "",
      },
      catheter: "",
      chemiotherapy: "",
      complicationCauseCardiovascular: "cardiovascular complications",
      complicationCauseCardiovascularLabel: "",
      complicationCauseMaindiagnosis: "",
      complicationCauseMaindiagnosisLabel: "",
      complicationCauseOther: "",
      complicationCauseOtherLabel: "",
      complications: "",
      complicationsAndRiskFactors: "",
      contributionsToForeignInsurance: "",
      contributionsToForeignInsuranceInfo: "",
      contributionsToForeignInsuranceInfoFromTo: "",
      contributionsToForeignInsuranceInfoState: "",
      currentTherapy: "",
      currentTherapyDkg: "",
      degree: "",
      degreeDoctor: "",
      downloadForm: "",
      editForm: "Modifier le formulaire",
      fieldsNotFromAssessmentModalTitle: "",
      formGenerationErrorMessage:
        "An error occurred, form could not be generated. Please try again.",
      furtherTherapeuticDiagnosticMeasures:
        "Further therapeutic or diagnostic measures",
      furtherTherapeuticDiagnosticMeasuresDescription: "",
      furtherTherapeuticDiagnosticMeasuresFinishedOn: "Finished on",
      furtherTherapeuticDiagnosticMeasuresFrom: "From",
      furtherTherapeuticDiagnosticMeasuresTo: "To",
      generateFormButton: "Générer le formulaire",
      generateFormTitle: "",
      generateMedicalFormButton: "",
      generateMedicalFormTitle: "",
      guardianIsAurhorizedRepresentative: "",
      guardianIsBetreuer: "",
      guardianIsCustodian: "Guardian",
      guardianIsLegalRepresentative: "",
      guardianPhoneNumber: "",
      guardianPostalCode: "Code Postal",
      guardianStreetHouseNumber: "Street, house number",
      guardianTelefaxEmail: "",
      guardianTown: "Town",
      height: "Taille",
      indicationCardiology: "Cardiologie",
      indicationGeriatrics: "Geriatrics",
      indicationMusculoskeletalDiseases: "Musculoskeletal diseases",
      indicationNeurology: "Neurology",
      indicationOther: "Other indications",
      indicationOtherLabel: "Please specify",
      interimProsthesisFitted: "",
      jobCareRelatives: "",
      jobChildRaising: "Child-raising periods",
      jobMinijob: "Mini-job",
      jobPensionAdjustment: "Pension rights adjustment",
      jobStatutoryPensionFarmersFrom: "If applicable, due to",
      kisConnection: {
        addFromRecare: "",
        addManually: "",
        buttonCancel: "",
        buttonImport: "",
        importError: "",
        importFormData: "",
        infoAssessmentNotUpdated: "",
        modalTitleNotPossibleToConnect: "",
        placeholderExternalID: "",
        subtitleImportFormData: "",
        titleImportFormData: "",
        titleImportForms: "",
        toastErrorImport: "",
        tooltipConnected: "",
        tooltipNotConnected: "",
      },
      lastEmployment: "Last employment",
      latestAssessmentTitle: "",
      legProthesis: "Thigh/lower leg prosthesis",
      maxLettersValidation: (args: { max: string }) =>
        template("Be max {{max}} letters long", args),
      namePhoneFax: "",
      nationality: "Nationality",
      needsPegTube: "PEG",
      patientBankAccountHolder: "Account holder",
      patientBankIban: "IBAN",
      patientBankIbanInvalid:
        "This IBAN is inadmissible: please enter a german IBAN that starts with DE and is followed by 20 digits.",
      patientBankIbanPlaceholder:
        "German IBAN (22 digits) ie. DE09 0909 0909 0909 9900 00",
      patientBankName: "Bank name",
      patientFirstName: "First name",
      patientHouseNumber: "Street and house number",
      patientInsuranceNoResults: (args: { emailMessage: string }) =>
        template(
          "No results? If the insurance is missing, {select:{{emailMessage}}}",
          args,
        ),
      patientInsuranceNoResultsLink: "",
      patientInsuranceNumber: "Insurance number",
      patientLastName: "Last name",
      patientName: "",
      patientPhoneNumber: "Phone",
      patientStatutoryPensionInsuranceNumber: "Pension insurance number",
      patientUnableToWorkBeforeHospitalization:
        "Already unable to work before the hospital treatment",
      patientUnableToWorkBeforeHospitalizationSince: "Since:",
      postalCodeDoctor: "Postal code of the treating doctor",
      readyForm: "Prêt à être partagé",
      recommendedRehaTypeAmbulant: "day hospitalization",
      recommendedRehaTypeAmbulantMobile: "mobile",
      recommendedRehaTypeAmbulantMobileLabel:
        "Reason, e.g. severe orientation disorder, running away",
      recommendedRehaTypeStationary: "Hospitalisation complète",
      recommendedRehaTypeStationaryLabel:
        "Reason, e.g. immobility, deficits in self-sufficiency",
      rehabReasoLastAntineoplasticTherapy: "Last antineoplastic therapy",
      rehabReason: "Reasons for conducting rehab",
      rehabReasonCausedByThirdParty: "Possible damage by third parties",
      rehabReasonEventDate: "Incident date",
      rehabReasonLastRadiation: "Last irradiation",
      rehabReasonNone: "Not applicable",
      rehabReasonOtherIncident: "Other incident",
      rehabReasonWorkAccident: "Work accident, occupational disease",
      rehaCapacityFull: "fully capable",
      rehaCapacityOther: "further particularities with regard to the capacity",
      rehaCapacityPartial: "partially capable",
      rehaCapacityPartialLabel: "partially capable with",
      relativeCare: "Additional admission of relatives in need of care",
      requirementArtificialRespiration: "Artificial respiration",
      requirementImmunosuppressants: "Immunosuppressants",
      requirementOther: "Other requirements",
      requirementOtherLabel: "Veuillez spécifier",
      requirementRehaClinic:
        "Special requirements regarding the rehabilitation clinic are needed",
      requirementRehaClinicLabel: "because of:",
      retirementEarningCapacityCompany:
        "Pension insurance institute (of the reduction in earning capacity pension)",
      retirementPensionApplied: "Retirement pension has been applied",
      retirementPensionAppliedDkg: "Retirement pension has been applied for",
      retirementPensionCarrierName: "Name of pension insurance carriers",
      retirementPensionEarningCapacity:
        "Pension because of a reduction in earning capacity is being received",
      retirementPensionEarningCapacityApplied:
        "Pension because of a reduction in earning capacity has been applied for",
      retirementPensionInsuranceCompanyName: "Name and address of the company",
      retirementPensionInsuranceCompanyNameDKG: "Pension insurance institute",
      retirementPensionReceived: "Retirement pension is being received",
      retirementPensionReceivedDkg: "Retirement pension is being received",
      selectForm: "Select form",
      severeSenseImpairment: "Severe hearing, visual and speech impairment",
      shareWithInsurance: "",
      stableDuringExercise: "stable during exercise",
      streetHouseNumberDoctor: "Address of the treating doctor",
      threats:
        "There is a risk of or long-term (>6 months) impairments relevant to everyday life (assessment at the time of diagnosis)",
      threatsAreasLife: "Important areas of life",
      threatsCommunication: "Communication",
      threatsCommunicationLabel: "e.g. when speaking, etc.",
      threatsCommunityLife: "Community life",
      threatsCommunityLifeLabel:
        "e.g. participation in sporting, cultural and religious activities, etc.",
      threatsDomesticLife: "Domestic life",
      threatsDomesticLifeLabel:
        "i.e. shopping, préparer les repas, aide au ménage, etc.",
      threatsGeneralTasks: "Tasks of daily life",
      threatsGeneralTasksLabel: "e.g. in the daily routine, etc.",
      threatsLearning: "Learning and application of knowledge",
      threatsLearningLabel:
        "e.g. impairments in listening/viewing, acquisition of skills, etc.",
      threatsMobility: "Mobility",
      threatsMobilityLabel:
        "e.g. when running longer distances or when using hands and arms, etc.",
      threatsRelationships: "Interpersonal interactions and relationships",
      threatsRelationshipsLabel:
        "e.g. building and maintaining family relationships/intimate/informal and formal social relationships, etc.",
      threatsSelfSufficiency: "Self-sufficiency",
      threatsSelfSufficiencyLabel:
        "e.g. washing, dressing and undressing, etc.",
      townDoctor: "City of the treating doctor",
      tracheostoma: "Tracheostomie",
      transferReason: "Reason for the desired transfer date",
      vtwo: {
        alreadySentTitle: {
          medicalForm: "",
          requestForm: "",
        },
        complete: "",
        formsCanBeUpdatedInfo: "",
        formsNotReceived: "",
        formsReceived: {
          medicalForm: "",
          requestForm: "",
        },
        generateButtons: {
          medicalForm: "",
          requestForm: "",
        },
        herebyConfirm: {
          formValidationConfirm: "",
          medicalForm: "",
          requestForm: "",
        },
        lockIcon: {
          costPayer: "",
          dataProtection:
            "Vous pouvez échanger des données personnelles concernant le patient avec cet établissement. Tous les messages sont chiffrés.",
          patientCreator: "",
        },
        markAsSent: "",
        pleaseCompleteTitle: {
          medicalForm: "",
          requestForm: "",
        },
        postShareTooltip: "",
        readyTitle: {
          medicalForm: "",
          requestForm: "",
        },
        saveAsDraft: "",
        shareWithInsuranceDisabledTooltip: "",
        shareWithInsuranceDisabledTooltipBeforeHandedover: "",
      },
      weaning: "Sevrage",
      weight: "Poids",
      woundClosed: "The wound is unremarkable / closed ",
      zipcodeState: "",
    },
    gender: "Sexe",
    grants: "Aspects financiers",
    hasAppliedforCarelevel: "La demande a été faite",
    hasAppliedforHigherCarelevel:
      "La demande pour un niveau plus élevé a été faite",
    hasHealthInsurance: "Le patient a la sécurité sociale",
    hasHelpAtHome: "Aides à domicile (IDE, Auxiliaire de vie)",
    hasNoHealthInsurance: "Le patient n'a pas la sécurité sociale",
    hintAdditionalExaminations: "i.e. scanner cérébral, ETT, endoscopies",
    hintIntravenousInjection: "Préciser le médicament administré",
    hintIsNotBarrierFree:
      "par exemple, les escaliers à l'intérieur et à l'extérieur, l'accès à la baignoire n'est pas possible",
    hintMedicalHistory:
      "indiquer les plus importants à connaître pour le receveur",
    hintRehabIE: "",
    hintSpecialisedAdvices: "Choisir jusqu’à 3",
    hintTakesBloodThinner: "Précisez le médicament",
    hospitalStay: "Séjour hospitalier actuel",
    insuranceNumber: "Numéro de sécurité sociale",
    insuranceProvider: "Mutuelle",
    insuranceShortProfile: "",
    integrationMissingFieldsModal: {
      body: "",
      editPatient: "",
      title: "",
    },
    interestedStayLongTerm: "Patient might be interested to stay long term",
    interestedStayLongTermRequest: "long term stay possible",
    intravenousInjection: "IVSE",
    isNotBarrierFree: "La situation du logement n'est pas sans obstacles",
    kis: {
      addPatientTooltip:
        "En entrant l'identifiant patient présent dans votre système interne, toutes les données nécessaires seront importées automatiquement dans Recare. ",
      getFilesError: "",
      headlineImportPatientData: "Importer les données patient depuis le DPI",
      headlineReimportPatientData:
        "Importer de nouvelles données patient depuis le DPI ? \n",
      idPlaceholder: "Veuillez entrer l'identifiant interne du patient",
      importError: "",
      importErrorPopup: "",
      importPatientData: "Importer les données patient",
      importSuccessfulToast: "",
      noDataForInternalId:
        "Aucune donnée n'a pu être trouvée avec cet identifiant patient.",
      reimportPatientData: "Importer à nouveau",
      reimportPatientDataHint:
        "Les nouvelles données patient remplaceront celles importées précédemment.",
      tooltipConnected: "",
      tooltipNotConnected: "",
      tooltipNotConnectedReimport: "",
    },
    lastName: "Nom",
    loadingError: "Impossible d'accéder au patient",
    mainSpecializations: "Spécialité",
    map: {
      mapButton: "Afficher la carte",
      mapReceiverText:
        "Cliquez sur « Afficher la carte » pour voir l'emplacement.",
      mapSenderText:
        "Cliquez sur « Afficher la carte » pour voir l'emplacement du fournisseur de suivi.",
      title: "Carte",
    },
    medicalDiagnosis: {
      addictions: "Addictions",
      allergies: "Allergies",
      assessmentDate: "",
      assessmentDateValidation: "",
      backToProfile: "RETOUR AU PROFIL",
      barthelDate: "",
      barthelHeadingResultPage: (args: {
        patientName: string;
        recareId: string;
      }) => template("Barthel-Index: {{patientName}} {{recareId}}", args),
      barthelIndexPlaceholder: "",
      barthelLink: "",
      barthelNoValue: "",
      barthelScore: "",
      barthelSubtitle: "",
      barthelValidation: "",
      chronicPains: "Douleurs chroniques",
      conductedEarlyRehab: "Transfusion",
      conductedEarlyRehabLabel: "i.e. quel type etc.",
      currentInfectionsError: "Veuillez choisir une option",
      dependencyLevel: "Aide pour les activités quotidiennes",
      details: "Précisions",
      diagnosis: "Exemple : conclusion du dossier",
      diagnosisType: {
        admissionDiagnosis: "",
        admissionDiagnosisDepartment: "",
        ambulanceDiagnosis: "",
        ambulantDischargeDiagnosis: "",
        anaesthesiaDiagnosis: "",
        anamnesis: "Anamnèse",
        careDiagnosis: "",
        causeOfDeath: "",
        childBirthDiagnosis: "",
        childDischargeTransferDiagnosis: "",
        childMorbidity: "",
        conservativeDiagnosis: "",
        cumulativeProcedures: "",
        departmentDiagnosis: "",
        departmentMainDiagnosis: "",
        dischargeDiagnosis: "",
        dischargeDiagnosisDepartment: "",
        evolution: "Evolution",
        examinationDiagnoses: "",
        hospitalMainDiagnosis: "",
        infectionControlDiagnosis: "",
        insuranceExtensionDiagnosis: "",
        invoicingDiagnosis: "",
        medicalInstructions: "Conduite a tenir",
        operationDiagnosis: "",
        otherDiagnosis: "",
        postoperativeDiagnosis: "",
        preoperativeDiagnosis: "",
        principleDRG: "",
        recommendedRoutine: "Mode de vie",
        referralDiagnosis: "",
        rehabMeasures: "",
        secondaryDiagnosis: "",
        secondaryDRG: "",
        transferDiagnosis: "",
        treatmentDiagnosis: "",
        treatmentDiagnosisDepartment: "",
      },
      doctorInChargeInHospital: "Nom du docteur en charge",
      doctorInChargeInHospitalPhone: "Ligne directe (service / portable)",
      earlyRehabBarthelIndexPlaceholder: "Index de Barthel pre-reha",
      earlyRehabDateWarning: "",
      earlyRehabForm: "Index de Barthel pre-reha",
      earlyRehabIndex: {
        behaviouralDisorder: "",
        communicationDisorder: "",
        dateTitle: "",
        earlyRehabLink: "",
        earlyRehabValidation: "",
        headingResultPage: (args: { patientName: string; recareId: string }) =>
          template("Early Rehab Index: {{patientName}} {{recareId}}", args),
        intensiveMonitoring: "",
        intermittentVentilation: "",
        noValue: "",
        orientationConfusion: "",
        scoreTitle: "",
        subtitle: "",
        swallowingDisorder: "",
        tracheostoma: "",
      },
      form: "Formulaire",
      hintSecondaryDiagnoses: "Si diagnostic associé important à connaître ",
      hospitalStay: "Contact direct de votre service ",
      icdCodeMainPatientInfoLabel: "Pertinent pour la rééducation",
      icdCodes: "Codes CIM-10",
      icdCodesAdditional: "Autres codes CIM-10",
      icdCodesAdditionalPatientInfoLabel: "Autres codes ICM",
      icdCodesMain: "Code CIM-10 principal",
      infections: "BMR/Germe nécessitant isolement",
      infectionsClostridien: "Clostridium Difficile",
      infectionsFourMRGN: "EPC",
      infectionsHint: "Veuillez spécifier",
      infectionsMRSA: "SARM",
      infectionsOther: {
        errorMissing: "Veuillez préciser",
        label: "Autre infection",
        placeholder: "i.e. grippe saisonnière",
      },
      infectionsRemarks: "Remarques",
      infectionsRequiresIsolation: "Chambre d'isolement nécessaire",
      infectionsThreeMRGN: "BLSE",
      infectionsVRE: "ERV",
      limitations: "Limitations du patient",
      limitedCapacity: "Capacité limitée à prendre part à la récupération",
      limitedCapacityOther: "Autre",
      limitedCognitiveCapacity: "Capacité cognitive limitée",
      limitedPsychicalCapacity: "Capacité physique limitée",
      limitedPsychologicalCapacity: "Capacité psychologique limitée",
      limitedSocialBehaviour: "Capacité sociale limitée",
      mainDiagnosis: {
        infectionsHint: "",
        label: "Diagnostic principal",
        placeholder:
          "i.e. décompensation cardiaque sur cardiopathie rythmique, fracture du col du fémur gauche, etc ",
        validationError:
          "Veuillez soit sélectionner le code CIM-10, soit indiquer le diagnostic principal dans le champ de texte.",
        validationErrorMainOnly:
          "Veuillez saisir le diagnostic principal dans le champ de texte. ",
      },
      multiMorbidity: "Comorbidité",
      multiMorbidPatient: "Multimorbidité",
      operationDate: "Date de l'opération",
      operationDescription: "Description de l'opération",
      palliativeCertificateNeeded: "Certification nécessaire",
      rehabilitationGoals: "Objectifs de rééducation",
      rehabilitationPotential: "Potentiel de récupération",
      rehabilitationPotentialDescription:
        "Veuillez décrire le potentiel de récupération",
      remarks: "Informations Complémentaires",
      secondaryDiagnosesCostPayer: "",
      secondaryDiagnosis: "Diagnostic secondaire",
      shortTerm: "Retour à domicile possible à court/moyen terme",
      singerIndexPlaceholder: "SINGER patient profile sum",
      singerPatientProfil: "",
      singerPatientProfile: {
        assessmentDatePlaceholder: "",
        assessmentDateValidation: "",
        buttonClearContent: "",
        buttonDelete: "",
        buttonEdit: "",
        buttonFill: "",
        communicationListening: "",
        communicationTalk: "",
        confirmationDelete: "",
        creationDate: "",
        creationDateError: "",
        dateTitle: "",
        detailedForm: "",
        domesticLifeHousehold: "",
        headingResultPage: (args: { patientName: string; recareId: string }) =>
          template("SINGER Index: {{patientName}} {{recareId}}", args),
        inputValidation: "",
        learnRead: "",
        learnWrite: "",
        mobilityStairs: "",
        mobilityTransfer: "",
        mobilityWalk: "",
        mobilityWheelchair: "",
        noValues:
          "Aucun résultat ne peut encore être affiché ici. Retournez au profil du patient et remplissez le profil du patient SINGER pour afficher les résultats.",
        scoreIndependent: "",
        scoreIndependentFive: "",
        scoreIndependentFour: "",
        scorePoints: "",
        scoreProfessionalHelp: "",
        scoreProfessionalHelpOne: "",
        scoreProfessionalHelpZero: "",
        scoreSupporter: "",
        scoreSupporterThree: "",
        scoreSupporterTwo: "",
        scoreTitle: "",
        scoreTotalScore: "",
        selfsufficiencyDress: "",
        selfsufficiencyEat: "",
        selfsufficiencyHygiene: "",
        selfsufficiencyShower: "",
        selfsufficiencyStool: "",
        selfsufficiencyToilet: "",
        selfsufficiencyUrine: "",
        singerDomainCommunication: "",
        singerDomainDomesticLife: "",
        singerDomainLearn: "",
        singerDomainMobility: "",
        singerDomainSelfsufficiency: "",
        singerDomainSocialInteraction: "",
        singerDomainTask: "",
        singerLink: "",
        singerSum: "",
        singerSumValidation: "",
        socialInteraction: "",
        subtitle: "",
        taskConcentration: "",
        taskOrientation: "",
        taskPlanning: "",
        title: "",
      },
      stepperTitle: "Diagnostic médical",
      totalScore: "Score total",
    },
    medicalHistory: "Antécédents principaux",
    medicalHistoryPlaceholder:
      "Indiquer les plus importants à connaître pour le receveur",
    medicalSupplies: {
      existingMedicalSupplies: "Matériel médical déjà disponible",
      neededMedicalSupplies: "Moyens auxiliaires nécessaires / à prévoir ",
      prescribedMedicalSupplies: "Matériel médical nécessaire / à prévoir",
      specificMedicalSupplies: "",
    },
    mentalHealth: "Fonctions supérieures",
    mentalState: {
      aggressivity: "Agitation, agressivité",
      anxiety: "Anxiété",
      closed_area_needed: {
        label: "Zone sécurisée nécessaire",
        placeholder: "i.e. le patient s'enfuit etc.",
      },
      closedStationRequired: "",
      dangerous: "",
      dementia: {
        label: "Alzheimer",
        placeholder: "i.e. stade de la maladie etc.",
      },
      depression: "Dépression",
      facilityCharacteristics: "",
      fixationRequired: "",
      mentalIssues: "Symptômes psycho-comportementaux",
      mentalIssuesOther: "Autres symptômes et information additionnelle",
      nightwatch: {
        label: "Surveillance nocturne",
        placeholder: "i.e. état du patient, etc",
      },
      stepperTitle: "Fonctions supérieures",
    },
    menuAssigneePlaceholder: "Sélectionner",
    mobileShort: {
      stepTitle: "Mobile court",
    },
    mobility: {
      assistiveEquipment: "Appareillages",
      auditive_impairment: {
        label: "Trouble de l'audition",
        placeholder: "i.e. diminuée, appareillée",
      },
      orthese: {
        label: "Orthèse",
        placeholder: "i.e. emplacement, depuis combien de temps, etc.",
      },
      prothese: {
        label: "Prothèse",
        placeholder: "i.e. emplacement, depuis combien de temps, etc.",
      },
      stepperTitle: "Mobilité",
      visual_impairment: {
        label: "Trouble de la vision",
        placeholder: "i.e. diminuée, appareillée",
      },
    },
    needsHelpEating: "Aide pour s'alimenter",
    needsHelpWalking: "Aide pour marcher",
    newResponsibleMessagePlaceholder: "Votre message",
    noAuctionsError:
      "Un problème est survenu lors de la création du patient, veuillez réessayer",
    noCandidates:
      "Considérant les critères sélectionnés, il n'y a aucun établissement à contacter pour le moment",
    nonDeclarationOfConsent:
      "Le consentement du patient ou de la famille n'a pas été recueilli",
    noPersonalDataWarning:
      "Seuls les champs chiffrés (cadenas vert) peuvent contenir des données personnelles",
    notifySocialWorker: {
      assigneeChangeFailed:
        "Votre demande de modification du destinataire de cette demande de patient a échoué. Veuillez actualiser et réessayer.",
      title: (args: { assignedName: string }) =>
        template(
          "Souhaitez-vous informer {{assignedName}} par email de ce changement ?",
          args,
        ),
    },
    palliativeCare: {
      label: "Soins palliatifs",
      placeholder: "i.e. détérioration, surveillance psychologique, etc.",
    },
    patient: "Patient",
    patientAlreadyInHospital: "Patient is already in the hospital",
    patientId: "Identifiant Recare",
    patientInformation: {
      acceptsPrivatePayment: "Seul(e) - Par ses propres moyens ",
      addictionsPlaceholder: "i.e. type, médicaments, etc.",
      allergiesPlaceHolder: "i.e. type, traitement, etc.",
      chronicPainsPlaceHolder: "i.e. localisation, traitement, etc.",
      civilServantsAid: "Civil servants aid",
      civilServantsAidDescription: "",
      civilServantsAidPlaceholder: "e.g. relevant aid office",
      contactIsPatient: "Le patient est la personne de contact ",
      contactIsPatientPlaceholder:
        "e.g. contact information, phone number, email address",
      currentDomesticSituation: "The current domestic situation is safe",
      decisionsLanguages: "Contact",
      encryptionExplanation:
        "Ces informations sont cryptées. Demandez à la clinique de les déverrouiller.",
      financing: "Aspects financiers",
      financingNotSelected: "Aucune option de financement n'a été retenue.",
      floor: "Étage",
      freeFromSupplementaryPayment: "",
      generalInformation: "Information générale",
      generalPractitionerContactDetailsPlaceholder:
        "Coordonnées du médecin traitant",
      generalPractitionerNamePlaceholder: "Nom du médecin traitant",
      generalPractitionerPlaceholder:
        "la spécialité, le numéro de téléphone, l'adresse, etc.",
      generalPractitionerTitle: "Médecin traitant",
      grantsMoreInformation: "Autre information financière",
      guardianContactInformation:
        "i.e. coordonnées de superviseur, tuteur, conseiller, représentant légal",
      guardianRequested: "",
      hasGuardian:
        "Le patient est sous mandat, tutelle, curatelle, ou sauvegarde de justice",
      hasGuardianPlaceholder:
        "e.g. guardian or legal representative for specific areas",
      insurance: "Sécurité sociale",
      isLiftAvailable: "Lift is available",
      liftAvailable: "Ascenseur disponible",
      livingSituation: "Lieu de vie actuel",
      mobilityPlaceholder:
        "Informations supplémentaires concernant la mobilité",
      monitoringNeeds: "",
      needsOxygen: "",
      patientCannotCommunicateInGerman: {
        label: "Le patient ne peut communiquer en français",
        placeholder: "i.e. quelle langue etc.",
      },
      patientIsLiving: "Logement",
      patientUsesSignInLanguage: {
        label: "Le patient utilise la langue des signes",
        placeholder: "i.e. un interprète est disponible, est recherché, etc",
      },
      payers: "Mutuelle",
      payersInsurance: "Insurance (public or private)",
      payersMoreInformations: "Further information",
      payersOther: "Other payer",
      payersOtherDescription: "",
      payersOtherPlaceholder: "Please specify",
      payout: "Payout",
      placeholder: "Veuillez spécifier",
      placeholderCurrentDomesticSituationUnknown: "Please specify",
      placeholderLiftAvailableNo: "e.g. étage, marches dans l'appartement",
      placeholderLiftAvailableUnknown: "Please specify",
      publicAccidentInsurance: "Public accident insurance",
      publicAccidentInsuranceDescription: "",
      publicAccidentInsurancePlaceholder:
        "e.g. relevant employers' association",
      publicPension: "Public pension",
      publicPensionDescription: "",
      relativesAvailable: "Coordonnées de la famille / proches du patient : ",
      relativesContactInformation: "Ex : Nom, numéro de téléphone, adresse...",
      relativesLookingSameTime:
        "Relatives are looking for a care spot at the same time",
      selfPayer: "Self-payer",
      selfPayerDescription: "",
      selfPayerPlaceholder: "e.g. payment of maximum...",
      socialServiceDepartment: "Social service department",
      socialServiceDepartmentDescription: "",
      socialServiceDepartmentPlaceholder: "e.g. relevant social service office",
      specialRequirements: "Type de chambre souhaité ",
      stepperTitle: "Information patient",
      supplementaryInsurance: "Le patient a une mutuelle",
      supplementaryInsuranceDescription: "",
      supplementaryInsurancePlaceholder: "Informations complémentaires",
      supplementaryPayment: "Supplementary payment",
      supplementaryPaymentDescription: "",
      supplementaryPaymentPlaceholder: "e.g. Supplementary payment of 10€/day",
      unsafeCurrentDomesticSituation: "Le logement actuel n'est pas sécurisé",
      unsafeCurrentDomesticSituationPlaceholder:
        "i.e. pas d'équipements spécialisés",
      visualImpairment: "",
    },
    patientProfile: "Profil ",
    patientSearchLabel: "Identifiant Recare / interne",
    patientType: "Type de transfert",
    payers: "",
    personalId: "",
    physicalHealth: "État physique",
    placeholderBarrierFreeUnknown: "Please specify",
    pleaseNoteId: (args: { patientId: string }) =>
      template(
        "Veuillez noter l'ID Recare ({{patientId}}) comme référence dans vos fichiers ou votre système",
        args,
      ),
    preventativeCare: "Allocation personnalisée d'autonomie",
    profile: "Profil",
    profileBanners: {
      noProviderFound: {
        subtitle:
          "Vous pouvez ajouter manuellement des receveurs à la recherche.",
        title: "Il n'y a pas de receveurs potentiels à contacter.",
      },
      profileComplete: {
        subtitle: "Vous pouvez commencer la recherche. ",
        title: "Le profil patient est complet.  ",
      },
      profileIncomplete: {
        subtitle: "Veuillez modifier les informations. ",
        title: "Le profil patient est incomplet.",
      },
    },
    publicCareInsuranceStatus: "",
    reimbursmentForHelpAtHome: "Aide sociale à l'hébergement",
    reimbursmentSecured: "APL/ALS",
    reliefServices: "Allocation logement (APL/ALS)",
    responsiblePerson: "Personne en charge",
    savedWithSolutionChange: (args: { listOfSolutions: string }) =>
      template(
        "Les changements ont été pris en compte. Vous recherchez maintenant une place en {{listOfSolutions}}.",
        args,
      ),
    savedWithSolutionChangeGeneric: (args: { listOfSolutions: string }) =>
      template(
        "Les changements ont été pris en compte. Vous recherchez maintenant une place en {{listOfSolutions}}. Veuillez noter que des changements peuvent affecter les établissements qui peuvent être contactés.",
        args,
      ),
    searchOnlyApplyToSolutions: (args: { solutions: string }) =>
      template("Ce filtre s'appliquera uniquement pour : {{solutions}}", args),
    searchPausedAutomaticallyWarning:
      'En raison des récentes modifications apportées à la fonction recherche, il n\'est dorénavant plus possible de mettre en pause une recherche. Votre recherche a été arrêtée automatiquement et peut être relancée en cliquant sur "Relancer".',
    searchRadius: "Rayon de recherche autour du code postal",
    searchType: "Type de patient",
    shortenedRehabPatientProfile: "Shortened patient profile is sufficient",
    shortTermCareGrant: "Subvention pour le court séjour",
    singerIndex: {
      dressingUndressing: "Habillage ",
      eatingDrinking: "Alimentation",
      personalHygiene: "Hygiène personnelle",
      selfSufficiency: "Mobilité du patient ",
      stoolControl: "Transfert lit/fauteuil \n",
      urineControl: "Contrôle de l'urine",
      washingShowering: "Toilette ",
    },
    socialHelpRecipient: "Prestation de  compensation du handicap",
    socialWorkerName: "Personne en charge du transfert",
    solutionLabel: "Type de soins",
    specialisedAdvices: "Avis spécialisés à prévoir",
    specialistDoctor: "Chambre simple",
    station: "Service adresseur",
    stationPhoneNumber: "Ligne directe service",
    stopAuction: {
      automaticallyStopped:
        "La recherche a été automatiquement arrêtée en raison d'une inactivité de plus de 14 jours.",
      dischargeDelayed: "Le transfert est remis à plus tard ",
      dischargedWithoutProvider: {
        care: "Le patient a quitté l'hôpital sans solution d'accueil",
        geriatrics: "Aucun établissement adapté n'a été trouvé",
      },
      foundWithoutRecare: (args: { receiverTypeSingular: string }) =>
        template(
          "Un {{receiverTypeSingular}} a été trouvé par un autre moyen ou confirmé par téléphone/fax",
          args,
        ),
      insuranceWorkerOrganised: "",
      internalTransfer: "Transfert au sein de l'hôpital",
      notNeeded: (args: { receiverTypeSingular: string }) =>
        template(
          "Un {{receiverTypeSingular}} n'est plus nécessaire pour ce patient",
          args,
        ),
      options: {
        foundWithoutRecare: (args: { receiverTypeSingular: string }) =>
          template(
            "Un {{receiverTypeSingular}} a été trouvé par un autre moyen ou confirmé par téléphone.",
            args,
          ),
        subtitle:
          "Précisez la raison le plus précisément possible pour nous aider à améliorer la plateforme",
        transferNotNeeded: (args: { receiverTypeSingular: string }) =>
          template(
            "Un {{receiverTypeSingular}} n'est plus nécessaire pour ce patient",
            args,
          ),
      },
      other:
        "Je souhaite archiver le patient pour d'autres raisons et annuler la recherche",
      otherReason: "Autre raison",
      patientDied: " L'état médical du patient s'est aggravé",
      patientTransfered: {
        care: "Le patient a été transféré ",
        editProfile:
          "Si vous souhaitez modifier le profil du patient lorsque la recherche est en cours, veuillez {select:cliquer ici}.",
        geriatrics: "Le patient n'a plus besoin d'être transféré",
      },
      providerFound: {
        care: "Un établissement d'accueil a été trouvé",
        geriatrics: "Transfert au sein de l'hôpital ",
      },
      providerFoundWithoutRecare: {
        care: "Un établissement d'accueil a été trouvé",
        geriatrics: "Un établissement d'accueil a été trouvé",
      },
      providerNotNeeded: "Le patient n'a plus besoin de soins de suite",
      providerWasFound: (args: { receiverTypeSingular: string }) =>
        template("Un {{receiverTypeSingular}} a été trouvé", args),
      providerWillBeInformedOfUnavailability:
        "Veuillez noter que les établissements suivants seront informés par email que la demande n'est plus disponible :",
      relativesOrganised: "Par la famille ",
      selectClosestReason:
        "Précisez la raison le plus précisément possible pour nous aider à améliorer la plateforme",
      socialworkerOrganised: "Par notre service",
      title: "Je souhaite arrêter la recherche car ...",
      transferred:
        "Le patient a été transféré vers un autre type de prise en charge (SSR ...)",
      withoutProvider: (args: { receiverTypeSingular: string }) =>
        template(
          "Le patient est rentré à domicile sans {{receiverTypeSingular}}",
          args,
        ),
    },
    stopAuctionAddCareprovider: {
      providerName: (args: { receiverTypeSingular: string }) =>
        template("Nous connaissons l'{{receiverTypeSingular}}", args),
      title: (args: { receiverTypeSingular: string }) =>
        template("Un {{receiverTypeSingular}} a été trouvé", args),
      unknownProvider: (args: { receiverTypeSingular: string }) =>
        template("Nous ne connaissons pas l'{{receiverTypeSingular}}", args),
      viaPatientFamily: "Trouvé par un autre moyen",
    },
    stopAuctionStop: {
      placeholder: "Veuillez préciser",
      title: "Pourquoi souhaitez-vous annuler la recherche ?",
    },
    stopSearch: {
      cancelButton: "Annuler",
      confirmButton: "Confirmer",
      firstStep: {
        firstStepText:
          "Une fois la recherche annulée nous informerons automatiquement tous les receveurs que celle-ci est désormais inactive. ",
        firstStepTitle: (args: { providerNumber: string }) =>
          template(
            "{{providerNumber}} receveur(s) contacté(s) pour cette recherche. ",
            args,
          ),
      },
      nextButton: "Suivant",
      secondStep: {
        dropdownButton: "Le receveur est introuvable",
        mandatoryFieldInformation: "Veuillez sélectionner la raison ",
        mandatoryFieldInformationProvider: "Veuillez entrer le nom du receveur",
        mandatoryFieldInformationSpecialisation:
          "Veuillez sélectionner la spécialité",
        optionOne: "Le receveur a été trouvé par un autre moyen",
        questionText: "Veuillez sélectionner la raison (obligatoire)",
        requestAlreadyAccepted:
          "Cette demande a déjà été acceptée par le fournisseur. \n{select:Cliquez ici} pour voir la validation dans Messenger et afficher les détails sur la page de la demande.",
        subtitle: "Veuillez spécifier la raison le plus précisément possible. ",
        title: "Pour quelle raison souhaitez-vous annuler cette recherche ?",
      },
    },
    takesBloodThinner: "Anticoagulation curative",
    takesBloodThinnerPlaceholder: "Préciser le médicament ",
    titleBarrierFree: "Housing situation is barrier-free",
    titleLengthRehabAssessment: "Scope of the patient profile",
    tooltipPatientMenu: {
      potentialReceivers: "Établissements potentiels :",
      searchRadius: "Rayon de recherche :",
      specialisation: "Spécialité :",
      startDate: "Date du transfert : ",
      treatmentLocation: "Code postal du patient : ",
      typeOfTransfer: "Type de transfert :",
    },
    transfer: "Transfert",
    transferChanges:
      "Vous pouvez modifier le mode de prise en charge. Les unités préalablement contactées qui ne correspondent plus seront automatiquement rejetées",
    transferDateOlderThanTenDaysWarning:
      "La date de transfert souhaitée est dépassée depuis plus de 7 jours. Si la recherche n'est plus nécessaire, veuillez annuler la recherche.",
    transferDetails: {
      hospitalInformation: "Information sur l'hôpital adresseur",
      phoneNumber: "Téléphone",
      specialisationsRequired: "Spécialisations requises",
      station: "Service adresseur",
      title: "Détails du transfert",
    },
    transferSearchInProgress: "Transfert",
    transitionOfPausedSearchesWarning:
      'À compter du 22 août 2023, la fonction permettant de mettre en pause une recherche ne sera plus disponible. Toutes les recherches mises en pause seront arrêtées mais pourront être reprises manuellement en cliquant sur "Relancer". ',
    transport: {
      accompanimentMedicallyNecessary: "Accompaniment is medically necessary",
      accompanyingPersonNecessary: "Personne accompagnante nécessaire",
      dailyTransportNeeded: "Transport quotidien requis",
      labelNameAndContact:
        "Name and contact information of the accompanying person",
      organizedBy: {
        label:
          "i.e. le transport doit être organisé, la famille s'en charge etc.",
        title: "Le transport sera organisé par",
      },
      patientNeedsTransportation: "Patient needs transportation",
      stepTitle: "Transport",
      timeHasPassed: "",
      titleTransportInformation: "Information regarding the transport",
      typeOfTransport: "Type de transport",
      weightLimitAdditionalInfo: (args: { weight: string }) =>
        template("Patient transports are limited to {{weight}} kg", args),
      weightLimitExceeded: "",
      weightLimitExceededToast: "",
    },
    treatmentLocation: {
      privacyDescription:
        "Pour des raisons de protection des données, l'adresse exacte ne peut être affichée. L'adresse du patient se trouve dans le cercle bleu.",
      privacyDescriptionEncrypted:
        "L'emplacement du patient se trouve dans le rayon bleu.",
      title: "Adresse du patient",
    },
    upgradePlan:
      "Votre abonnement ne vous permet pas de sélectionner ce type de transfert",
    walkAlone: "Marche seul(e)",
    warningLeavingAssessment:
      "Êtes-vous sûr(e) de vouloir quitter la page ?\nTout changement effectué sur le profil ne sera pas sauvegardé.",
    yearOld: "ans",
  },
  patientForms: {
    buttonImport: "",
    buttonRefreshData: "",
    buttonSave: "",
    buttonSaveOverwrite: "",
    connectionFailed: {
      buttonTryAgain: "",
      title: "",
    },
    dateField: {
      title: "",
      warning: "",
    },
    drvGeneralForm: {
      description: "",
      doctorFirstName: "",
      insuranceInformation: {
        addressHealthInsurance: "",
        addressHospital: "",
        addressPensionInsurance: "",
        informationAhbFacility: "",
        informationSocialWorker: "",
        pensionInsuranceNumber: "",
        subtitleHospital: "",
        subtitleRehabClinic: "",
        subtitleSocialWorker: "",
      },
      sectionEight: {
        contributionsAbroad: "",
        contributionsPension: "",
        contributionsState: "",
        currentContributionsAbroad: "",
        optionNo: "",
        optionYes: "",
        timeline: "",
        title: "",
      },
      sectionEleven: {
        attached: "",
        authorisedRepresentative: "",
        carer: "",
        city: "",
        email: "",
        guardian: "",
        lackOfCapacity: "",
        legalGuardianType: "",
        legalRep: "",
        nameRep: "",
        optionNo: "",
        optionYes: "",
        phone: "",
        street: "",
        submittedLater: "",
        subtitle: "",
        title: "",
        zipCode: "",
      },
      sectionFive: {
        fulltimeWithNightShifts: "",
        fulltimeWithoutShifts: "",
        fulltimeWithShifts: "",
        homeOffice: "",
        houseWife: "",
        parttime: "",
        partTimeUsual: "",
        registeredUnemployed: "",
        title: "",
        unemployed: "",
        workshopDisabilities: "",
      },
      sectionFour: {
        apprentice: "",
        civilServant: "",
        employee: "",
        foreman: "",
        selfEmployed: "",
        skilledLabourer: "",
        skilledWorker: "",
        subtitle: "",
        title: "",
        unemployed: "",
        unskilledWorker: "",
      },
      sectionNine: {
        jobCenter: "Name of Job center, file number",
        optionNo: "",
        optionYes: "",
        subtitle: "",
        title: "",
      },
      sectionOne: {
        inpatient: "",
        oncologicalRehab: "",
        outpatient: "",
        rehabForInsured: "",
        title: "",
      },
      sectionSeven: {
        doctorCity: "",
        doctorPhone: "",
        doctorStreet: "",
        doctorZipCode: "",
        title: "",
      },
      sectionSix: {
        doctorName: "",
        insuranceInformation: "",
        insuranceName: "",
        optionNo: "",
        optionYes: "",
        patientInsurance: "",
        privatelyInsured: "",
        publiclyInsured: "",
        sinceDate: "",
        subtitleHospitalStay: "",
      },
      sectionTen: {
        accidentOrPerson: "",
        benefitsType: "",
        compensatedOn: "",
        compensationClaims: "",
        damageClaims: "",
        fileNumber: "",
        healthIssues: "",
        namePensionInsurance: "",
        optionNo: "",
        optionYes: "",
        otherBenefits: "",
        penisonApplication: "",
        pensionBenefits: "",
        pensionInformation: "",
        pensionOldAgeBenefits: "",
        recognisedBy: "",
        submittedApplication: "",
        submittedWhere: "",
        title: "",
        workRelatedAccident: "",
      },
      sectionThirteen: {
        additionalFormat: "",
        audio: "",
        brailleFull: "",
        brailleShort: "",
        cd: "",
        confirmation: "",
        description: "",
        germanMailAddress: "",
        largePrint: "",
        subtitleEmail: "",
        title: "",
        visuallyImpaired: "",
      },
      sectionThree: {
        divorced: "",
        fieldLabelProfession: "",
        married: "",
        single: "",
        subtitle: "",
        subtitleProfession: "",
        title: "",
        widowed: "",
      },
      sectionThreeNew: {
        subtitle: "",
        title: "",
        yesIncludeForm: "",
      },
      sectionTwelev: {
        aids: "",
        descriptionAids: "",
        optionNo: "",
        optionYes: "",
        title: "",
      },
      sectionTwo: {
        city: "",
        dateOfBirth: "",
        diverse: "",
        female: "",
        firstName: "",
        formerNames: "",
        gender: "",
        lastName: "",
        maidenName: "",
        male: "",
        nationality: "",
        phone: "",
        street: "",
        title: "",
        unknown: "",
        zipCode: "",
      },
      title: "",
    },
    drvMedicalForm: {
      addressHospital: "",
      description: "",
      descriptionHistory: "",
      sectionFive: {
        accompanyingPerson: "",
        carelevelDescription: "",
        carelevelLabel: "",
        catheters: "",
        cathetersDescription: "",
        eat: "",
        germs: "",
        germsDescription: "",
        incontinence: "",
        lyingDown: "",
        medicalReason: "",
        move: "",
        optionNo: "",
        optionYes: "",
        orientation: "",
        orientationDescription: "",
        sitting: "",
        taxi: "",
        title: "",
        travel: "",
        travelCar: "",
        travelHelicopter: "",
        travelPublic: "",
        urinalIncontinence: "",
        wash: "",
        wheelchair: "",
        wound: "",
      },
      sectionFour: {
        height: "",
        title: "",
        weight: "",
      },
      sectionOne: {
        descriptionDiagnosis: "",
        diagnosisIcd: "",
        drgGroup: "",
        title: "",
      },
      sectionSeven: {
        remarksDescription: "",
        title: "",
      },
      sectionSix: {
        accountHolder: "",
        address: "",
        addressPensionInsurance: "",
        bankName: "",
        iban: "",
        ikNumber: "",
        subtitleBankAccountInformation: "",
        title: "",
      },
      sectionThree: {
        descriptionIllnessess: "",
        diagnosisIcd: "",
        nyhaStages: "",
        optionNo: "",
        optionYes: "",
        title: "",
      },
      sectionTwo: {
        descriptionComplications: "",
        descriptionMeasures: "",
        descriptionTherapies: "",
        endsOnDate: "",
        fromDate: "",
        optionNo: "",
        optionYes: "",
        otherMeasures: "",
        title: "",
        untilDate: "",
      },
      sectionZero: {
        accidentWork: "",
        acuteEvent: "",
        addressAhb: "",
        addressPatient: "",
        ahbEventCheckboxGroupLabel: "",
        ahbEventDate: "",
        ahbStartDate: "",
        antineoplasticTherapy: "",
        city: "",
        contactPersonInformation: "",
        dateBirthPatient: "",
        directDischarge: "",
        dischargeDate: "",
        instructionAfterExamination: "",
        insuranceNumber: "",
        intakeDate: "",
        mobile: "",
        notApplicable: "",
        patientName: "",
        radiation: "",
        static: "",
        subtitleAhb: "",
        subtitleContactPerson: "",
        subtitleHospital: "",
        subtitleHospitalAddress: "",
        subtitlePatientInformation: "",
        subtitleSuggestedRehab: "",
        surgery: "",
        thirdPartyDamage: "",
        typeOfProcedure: "",
        zipCodePatient: "",
      },
      title: "",
    },
    editSavedDocument: "",
    emptyPage: "",
    emptyPageTitle: "",
    fileDeletion: {
      cancelButton: "",
      confirmButton: "",
      text: "",
      title: "",
    },
    generalForm: {
      buttonImportData: "",
      buttonRefreshData: "",
      buttonSaveAndCreate: "",
      buttonSaveAndOverwrite: "",
      description: "",
      insuredPerson: {
        city: "",
        dateOfBirth: "",
        healthInsurance: "",
        insuranceNumber: "",
        name: "",
        phone: "",
        street: "",
      },
      optionNo: "",
      optionYes: "",
      savedFile: {
        description: "",
        print: "",
        savedAt: (args: { savedAt: string }) =>
          template("Gespeichert am {{savedAt}}", args),
        title: "",
      },
      sectionI: {
        accessibilityAtHomeSituation: "",
        accessibilityGuardianType: "",
        currentHomeCareEnsured: "",
        gpCity: "",
        gpName: "",
        gpPhoneNumber: "",
        gpStreet: "",
        gpZipCode: "",
        labelSpecialRequirements: "",
        livingSituationElevator: "",
        livingSituationFloor: "",
        livingSituationSpecifications: "",
        optionAssistedLiving: "",
        optionAtHome: "",
        optionAtHomeAlone: "",
        optionAtHomeWithRelatives: "",
        optionCareHome: "",
        optionCarer: "",
        optionHealthCareRep: "",
        optionLegalRep: "",
        placeholderLabelInsuranceName: "",
        questionGp: "",
        questionGuardian: "",
        questionHomeCare: "",
        questionLivingSituation: "",
        questionNeedForCare: "",
        questionPensionApplication: "",
        questionSpecialRequirements: "",
        subtitleAddressGp: "",
        subtitleHomeCare: "",
        subtitlePensionReducedEarning: "",
        subtitleRetirementPension: "",
        title: "",
        titleNeedForCare: "",
        titlePensionApplication: "",
      },
      sectionTwo: {
        communicationNo: "",
        communicationNoGerman: "",
        communicationWithPatient: "",
        communicationYes: "",
        costCoverage: "",
        costCoverageNo: "",
        costCoverageYes: "",
        detailsSocialWorker: "",
        hospitalAddressCity: "",
        hospitalAddressStreet: "",
        hospitalName: "",
        patientId: "",
        rehabDetails: "",
        rehabIntakeDate: "",
        socialWorkerFax: "",
        socialWorkerName: "",
        socialWorkerPhone: "",
        title: "",
      },
      title: "",
    },
    goToForm: "",
    medicalForm: {
      description: "",
      sectionFive: {
        addictiveDisorders: "",
        amputation: "",
        backPain: "",
        bloodDiseases: "",
        bloodVessels: "",
        bypass: "",
        endocrineDiseases: "",
        endoprostheticTreatment: "",
        gastroenterologicalDiseases: "",
        geriatricRehab: "",
        geriatrics: "",
        gynaecologicalDiseases: "",
        heart: "",
        heartFailure: "",
        inflammatoryDiseases: "",
        mentalIllnesses: "",
        mobile: "",
        mobileOutpatient: "",
        musculoskeletalSystem: "",
        myocardialInfarction: "",
        nephrologicalDiseases: "",
        neurologicalDisease: "",
        neurologicalDiseases: "",
        oncologicalDisease: "",
        oncologicalDiseases: "",
        others: "",
        otherTextfieldLabel: "",
        psychosomaticDisorders: "",
        rehabDirectiveTypeLabel: "",
        rehabFocus: "",
        rehabRl: "",
        respiratoryDisease: "",
        respiratoryDiseases: "",
        skinDiseases: "",
        spinalCanal: "",
        static: "",
        title: "",
        transplants: "",
        typeOfRehab: "",
        urologicalDiseases: "",
      },
      sectionFour: {
        rehabGoals: "",
        title: "",
      },
      sectionOne: {
        accessibilityGender: "",
        admissionRehab: "",
        dischargeDate: "",
        earliestDatePossible: "",
        earlyRehabMeasures: "",
        earlyRehabMeasuresNo: "",
        earlyRehabMeasuresYes: "",
        firstName: "",
        genderDiverse: "",
        genderFemale: "",
        genderMale: "",
        hospitalStayInformation: "",
        informationInsuredPerson: "",
        intakeDate: "",
        lastName: "",
        medicalReasons: "",
        optionDirect: "",
        outsideTwoWeeks: "",
        title: "",
        withinTwoWeeks: "",
      },
      sectionSix: {
        accompanyingPerson: "",
        ambulance: "",
        ambulanceDescriptionLabel: "",
        car: "",
        catheter: "",
        chemotherapy: "",
        contactInformationDoctor: "",
        decubitus: "",
        dialysis: "",
        immunosuppressants: "",
        impairment: "",
        isolation: "",
        lyingDown: "",
        nameDoctor: "",
        obesity: "",
        optionPublicTransport: "",
        other: "",
        overlengthBed: "",
        peg: "",
        phoneDoctor: "",
        prosthesis: "",
        relatives: "",
        specialRequirements: "",
        specialRequirementsSelectOptionsLabel: "",
        stretcher: "",
        taxi: "",
        taxiOptionLabel: "",
        title: "",
        tracheostoma: "",
        transportation: "",
        ventilation: "",
        weaning: "",
        weightBed: "",
        wheelChair: "",
      },
      sectionThree: {
        behaviouralDisorder: "",
        communicationDisorder: "",
        earlyRehabIndex: "",
        fully: "",
        intensiveCareMonitoring: "",
        intermittentVentilation: "",
        musculoskeletalSystem: "",
        orientationDisorder: "",
        partially: "",
        prosthesis: "",
        resilience: "",
        specifics: "",
        stable: "",
        swallowingDisorder: "",
        title: "",
        tracheostoma: "",
      },
      sectionTwo: {
        cause: "",
        complications: "",
        complicationsDescriptionLabel: "",
        diagnosisDescription: "",
        footnoteOne: "",
        functionDiagnoses: "",
        germs: "",
        germsUnknown: "",
        icdCode: "",
        longTermImpairments: "",
        optionCardiovascular: "",
        optionFive: "",
        optionFour: "",
        optionOne: "",
        optionOtherComplication: "",
        optionSix: "",
        optionThree: "",
        optionTwo: "",
        otherTreatments: "",
        performedTreatments: "",
        relatedToDiagnosis: "",
        singer: "",
        surgeries: "",
        surgeryDate: "",
        surgeryDescription: "",
        surgeryOps: "",
        title: "",
        treatmentsDescription: "",
        typeOfGerms: "",
        wound: "",
      },
      title: "",
    },
    menu: {
      buttonDelete: "",
      buttonEdit: "",
      buttonPrint: "",
      drvGeneralForm: "",
      drvMedicalForm: "",
      fillOutForm: "",
      maximumFormHint: "",
      rehabGeneralForm: "",
      rehabMedicalForm: "",
      saveDocuments: "",
      subtitleEligibility: "",
      subtitleGeneralForm: "",
      subtitleMedicalForm: "",
      subtitleTransitionalCare: "",
      title: "",
      transitionalCare: "",
    },
    savedDocumentsDescription: "",
    savedDocumentsTitle: "",
    tabTitle: "",
    tabWithTransitionalCare: "",
    transitionalCareForm: {
      buttonPdf: "",
      contactInformation: {
        confirmedWithLegalRep: "",
        footnoteInsuranceNumber:
          "Temporairement pour les assurés privés, le numéro d'assurance (s'il peut être déterminé)",
        hospitalCity: "",
        hospitalIkNumber: "",
        hospitalName: "",
        hospitalStreet: "",
        hospitalStreetNumber: "",
        hospitalZipCode: "",
        legalRepCity: "",
        legalRepDataProcessing: "",
        legalRepFirstName: "",
        legalRepName: "",
        legalRepStreet: "",
        legalRepStreetNumber: "",
        legalRepZipCode: "",
        patientBirthDate: "",
        patientCity: "",
        patientFirstName: "",
        patientInsuranceNumber: "",
        patientName: "",
        patientStreet: "",
        patientStreetNumber: "",
        patientZipCode: "",
        socialWorkerEmail: "",
        socialWorkerFax: "",
        socialWorkerFirstName: "",
        socialWorkerName: "",
        socialWorkerPhone: "",
        titleHospital: "",
        titleLegalRep: "",
        titlePatient: "",
        titleSocialWorker: "",
      },
      createNewBanner: "",
      deleteForm: "Supprimer",
      descriptionEmptyForm: "",
      descriptionExistingForms: "",
      downloadSection: {
        bodyText:
          "Le document a été créé automatiquement à partir des données de la plateforme Recare et peut avoir été complété ou ajusté par les employés de l'hôpital. Recare n'assume donc aucune responsabilité quant à l'exhaustivité et à l'exactitude des données.",
        hisButton: "Envoyer à HIS",
        hisError:
          "Le formulaire n'a pas pu être envoyé au HIS. Veuillez réessayer plus tard.",
        hisSuccess: "Envoyé avec succès au HIS",
        printButton: "Presse",
        savedAt: (args: { savedAt: string }) =>
          template("Enregistré le {{savedAt}}", args),
        title: "Documentation des soins de transition",
      },
      encryptionInformation: "",
      errorDateInTheFuture: "",
      errorDateInThePast: "",
      errorDischargeBeforeIntake: "",
      explanationTransitionalCare: "",
      hospitalStay: {
        departmentCode: "",
        endDateTransitionalCare: "",
        footnoteOne: "",
        footnoteTwo: "",
        id: "",
        intakeDate: "",
        locationId: "",
        releaseDate: "",
        startDateTransitionalCare: "",
        titlePriorStay: "",
        titleTransitionalCare: "",
      },
      mandatoryFieldInformation: "",
      notPurchased: {
        advantageFive: "",
        advantageFour: "",
        advantageOne: "",
        advantageThree: "",
        advantageTwo: "",
        cta: "",
        paragraphOne: "",
        paragraphTwo: "",
        subtitleAdvantages: "",
        title: "",
      },
      numberOfProviders: "",
      pdfCreated: "",
      savedDate: (args: { date: string }) =>
        template("Saved on {{date}}", args),
      sectionA: {
        dateLabel: "",
        title: "",
      },
      sectionB: {
        dateFieldLabel: "",
        dropdownNumberOfProviders: "",
        textFieldExplanationLabel: "",
        textFieldLabel: "",
        title: "",
        toggleConfirmedWithPatient: "",
        toggleNumberOfProviders: "",
      },
      sectionC: {
        afterCareRequired: "",
        applicationDate: "",
        applicationSubmitted: "",
        careLevel: "",
        careLevelApplied: "",
        careLevelAppliedDate: "",
        medicalRehab: "",
        mobileCare: "",
        shorttermCare: "",
        textFieldDescription: "",
        textFieldLabel: "",
        title: "c) Détermination du besoin de suivi",
      },
      sectionD: {
        dateFieldLabel: "",
        title: "",
      },
      sectionE: {
        addMoreProviders: "",
        deleteButtonAria: "Delete button",
        deletionScreenReaderAnnouncement: (args: { providerName: string }) =>
          template(
            "La demande adressée au fournisseur {{providerName}} a été supprimée",
            args,
          ),
        editButtonAriaLabel: "Edit button",
        editCity: "",
        editDate: "",
        editName: "",
        editStatus: "",
        editStreet: "",
        editStreetNumber: "",
        editTime: "Heure d'envoi",
        editZipCode: "",
        importButton: "",
        importedProvider: "",
        internetbasedPortals: "",
        mandatoryFieldsInformation: "",
        manuallyAddedProvider: "",
        refreshButton: "",
        saveButton: "",
        title: "",
      },
      sectionF: {
        bulletFour: "",
        bulletOne: "",
        bulletThree: "",
        bulletTwo: "",
        dateFieldLabel: "",
        title: "",
      },
      subtitleEditForm: "",
      subtitleEmptyForm: "",
      subtitleExistingForms: "",
      subtitleNew: "",
      tabTitle: (args: { tabName: string }) =>
        template("{{tabName}} – with TRANSITIONAL CARE", args),
      title: "",
      titleDocumentationTransitionalCare: "",
      toTheTop: "",
      validationErrorMessage: "Please complete this field.",
    },
    warningNotSaved: {
      leavePage: "",
      savePage: "",
      subtitle: "",
      title: "",
    },
    warningOverwritePdf: {
      buttonCancel: "",
      buttonConfirm: "",
      subtitle: "",
      title: "",
    },
  },
  people: {
    centralPhoneNumber: "Numéro de téléphone",
    companyMailAddress: "Adresse email centrale du service (si pertinent)",
    companyMailAddressPlaceholder: "par exemple info@, contact@",
    emailAddress: "Adresse email",
    faxNumber: "Numéro de fax",
    firstName: "Prénom",
    ikNumber: "IK Number",
    invalidEmailAddress: "Veuillez entrer une adresse mail valide",
    invalidPhoneNumber: "Saisissez un numéro et un indicatif valides. ",
    jobCategories: {
      admissionBedMgmt: "Bed Management",
      chiefDoctor: "Chef de service",
      disposition: "",
      doctor: "Docteur",
      managementAdministration: "Secrétaires/Cadre de santé",
      medicalStaff: "",
      other: "Autre",
    },
    jobCategory: "",
    lastName: "Nom",
    mailShort: "E-mail :",
    mobile: "Téléphone portable :",
    mobilePhoneNumber: "Numéro de portable",
    phoneNumber: "Numéro de téléphone",
    position: "Fonction",
    tel: "Tel.",
    website: "Site internet",
  },
  planning: {
    loadingError: "Impossible de rafraîchir",
    planningTabTerminated:
      "À partir du 01.07.2020 le Calendrier ne sera plus disponible. Nous vous recommandons d’utiliser les filtres sur votre tableau de bord si vous souhaitez filtrer par dates. ",
  },
  prompts: {
    continueWithoutSaving: {
      explanationText:
        "Toutes les modifications non enregistrées seront perdues.",
      title: "Voulez-vous vraiment continuer?",
    },
    saveChanges: {
      explanationText:
        "Vos modifications seront perdues si vous ne les enregistrez pas.",
      title:
        "Souhaitez-vous enregistrer les modifications que vous avez apportées ?",
    },
  },
  providersearch: {
    accountPage: {
      acceptedPrivacyPolicy: (args: { date: string }) =>
        template("You have agreed to the privacy policy on {{date}}.", args),
      acceptedPrivacyPolicyAdditional: () => "",
      acceptedPrivacyPolicyLink:
        "You can send all other data protection enquiries to {select: pflege-finde-bayern@recaresolutions.com}.",
      acceptedTermsOfUse: (args: { date: string }) =>
        template("You have agreed to the terms of use on {{date}}.", args),
      accountCreationDate: (args: { date: string }) =>
        template("Your account was created on {{date}}.", args),
      accountDeactivation: "",
      accountDeactivationLink:
        "To deactivate your account please send a mail to {select:pflege-finder-bayern@recaresolutions.com}",
      buttonReadDocument: "",
      dataExportRequest: "",
      dataExportRequestLink:
        "To request your data export please send a mail to {select: pflege-finder-bayern@recaresolutions.com}",
      emailAddress: "",
      linkOpenFile: "",
      privacyPolicy: "",
      subtitleAccount: "",
      subtitleLegal: "",
      subtitlePersonalDetails: "",
      termsOfUse: "",
      title: "",
    },
    assessment: {
      ageGroupWarning: "",
      errorAgeGroup: "",
      errorCareDuration: "",
      errorCareLevel: "",
      errorGender: "",
      informationText:
        "This information is used by the provider to process your request. Your data will only be shared with service providers you contact. You can find more information about how we process your data in our {select:data protection information}.",
      informationTitle: "",
      mandatoryFieldInformation: "* indique un champ obligatoire.",
      missing: "",
      nextButton: "",
      overweightService: (args: { weight: string }) =>
        template("Overweight (> {{weight}} kg)", args),
      questionAgeGroup: "",
      questionCareDuration: "",
      questionCareLevel: "",
      questionCareNeeds: "",
      questionGender: "",
      specialCareNeedsWarning: "",
      title: "",
    },
    auctionCreationModal: {
      cancelButton: "",
      nextButton: "",
      startDate: "",
      title: "",
      typeOfCare: "",
      warningDate: "",
      warningDateInPast: "",
      warningZipCode: "",
      warningZipCodeMissing: "",
      zipCode: "",
    },
    auctionResponsePage: {
      buttonLabel: "",
      chatLabels: {
        buttonCancel: "",
        buttonValidate: "",
      },
      contactDetailsProvider: {
        address: "",
        email: "",
        telephone: "",
        title: "",
        website: "",
      },
      patientInformation: {
        ageGroup: "",
        careDuration: "",
        careLevel: "",
        careNeeds: "",
        gender: "",
        profileId: "",
        title: "",
      },
      print: "",
      requestStatus: {
        buttonUndoValidation: "",
        informationUndoValidation: "",
        requestWasAccepted: (args: { date: string }) =>
          template("Request was accepted on {{date}}", args),
        requestWasDeclined: (args: { date: string }) =>
          template("Request declined on {{date}}", args),
        requestWasRejected: (args: { date: string }) =>
          template("Request was cancelled on {{date}}", args),
        requestWasSent: (args: { date: string }) =>
          template("request was sent  {{date}}", args),
        requestWasValidated: (args: { date: string }) =>
          template("Request was validated on {{date}}", args),
        testAttributed: "",
        textAccepted: "",
        textArchived: "",
        textAuctionStopped: "",
        textCancelled: "",
        textPending: "",
        textRejected: "",
        title: "",
      },
      searchDetails: {
        startDate: "",
        title: "",
        typeOfCare: "",
        zipCode: "",
      },
      showProviderLink: "",
      title: "",
      undoValidationDialog: {
        buttonCancel: "",
        buttonConfirm: "",
        cancelValidationReason: "",
        subtitle: "",
        title: "",
      },
    },
    emailAddress: "pflege-finder-bayern@recaresolutions.com",
    errorTooManyRequests: "",
    expiredLink: "",
    filterPanel: {
      filterChip: {
        distanceRadius: "km Rayon",
        freeCapacity: "",
        withConsultants: "",
        withProviders: "",
      },
      filterValues: {
        assistedLiving: "",
        dailyLivingSupportServices: "",
        dayCare: "",
        helpAtHome: "",
        hospiceAssociation: "",
        hospiceMobile: "",
        hospicePartiallyMobile: "",
        hospiceStatic: "",
        isConsultant: "",
        isProvider: "",
        mobileCare: "",
        nightCareMobile: "",
        nightCareStatic: "",
        sharedFlat: "",
        shortTermStaticCare: "",
        staticCare: "",
      },
      hospicePalliativeCareTitle: "",
      partialStaticCareTitle: "",
      receiverTypeTitle: "",
      referrer: "",
      servicesTitle: "",
      title: "",
      titleFilters: "",
      typeOfCareTitle: "",
    },
    footer: {
      accessibilityCertificate: "",
      accessibilityDeclaration: "Accessibility declaration",
      accessibilityDeclarationTooltip:
        "External link: https://assets.recaresolutions.com/recare/pflegefinder_bayern_barrierefreiheitserklarung.pdf (Opens in new window)",
      altTextAccessibilityCertificate: "",
      altTextLogoLandesamt: "",
      altTextLogoStaatsministerium: "",
      altTextRecareLogo: "",
      copyright: "",
      developedBy: "",
      easyLanguage: "",
      emailAddress: "",
      impressum: "Impressum",
      impressumTooltip:
        "External link: https://recaresolutions.com/impressum/ (Opens in new window)",
      inCollaborationWith: "",
      logoLandesamtTooltip:
        "External link: https://www.lfp.bayern.de/ (Opens in new window)",
      logoRecareTooltip:
        "External link: https://recaresolutions.com/ (Opens in new window)",
      logoStaatsministeriumTooltip:
        "External link: https://www.stmgp.bayern.de/ (Opens in new window)",
      partnersHospitals: "",
      partnersHospitalsTooltip:
        "External link: https://www.bkg-online.de/ (Opens in new window)",
      partnersInsurance: "",
      phone: "",
      phoneNumberLabel: "",
      privacyPolicy: "Privacy policy",
      privacyPolicyTooltip:
        "External link: https://assets.recaresolutions.com/recare/pflegefinder_bayern_datenschutzinformation.pdf (Opens in new window)",
      reportBarrier: "",
      reportBarrierLink:
        "mailto:pflege-finder-bayern@recaresolutions.com?Subject=Barriere%20melden",
      reportBarrierTooltip:
        "External link: pflege-finder-bayern@recaresolutions.com (Opens in new window)",
      reportContent: "",
      reportContentLink:
        "mailto:pflege-finder-bayern@recaresolutions.com?Subject=Report%20content",
      signLanguage: "",
      tableOfContent: "",
      termsOfUse: "Terms of use",
      termsOfUseTooltip:
        "External link: https://assets.recaresolutions.com/recare/pflegefinder_bayern_nutzungsbedingungen.pdf (Opens in new window)",
    },
    genericAriaLabelEmailLink: "",
    genericAriaLabelLink: "",
    genericAriaLabelPDFLink: "",
    genericAriaLabelPhoneLink: "Numéro de téléphone",
    genericErrorMessage: "",
    genericLoadingNotification: "Loading",
    glossary: {
      buttonPrint: "",
      careLevels: {
        bulletPoints: {
          eight: "",
          five: "",
          four: "",
          one: "",
          seven: "",
          six: "",
          three: "",
          two: "",
        },
        descriptions: {
          applied: "{select:Applied:} A care level has been applied for.",
          levelFive:
            "{select:Care level 5:} Severe impairment of independence with special care requirements",
          levelFour: "{select:Care level 4:} Severe impairment of independence",
          levelOne: "{select:Care level 1:} Minor impairment of independence",
          levelThree:
            "{select:Care level 3:} Severe impairment of independence",
          levelTwo:
            "{select:Care level 2:} Significant impairment of independence",
          none: "{select:None:} There is no care level.",
          unknown: "{select:Unknown:} The care level is not known.",
        },
        introduction: "",
        paragraphOne: "",
        paragraphThree: "",
        paragraphTwo: "",
      },
      descriptionsConsultants: {
        dementiaCounselling: "",
        descriptionDementiaCounselling: "",
        descriptionSpecialisedOffice: "",
        descriptionSupportPoint: "",
        specialisedOffice: "",
        supportPoint: "",
      },
      errorNotFound: "Error: Glossary item not found",
      glossaryAriaLabel: "Glossary",
      linkToGlossary: "You can find more information in our {select:glossary}",
      navigationCareLevels: "",
      navigationCareNeeds: "",
      navigationConsultants: "",
      navigationSolutions: "",
      navigationTitle: "",
      pageDescription: "Glossary of commonly used medical terms",
      pageTitle: "Glossary",
      solutions: {
        assistedLiving: "",
        assistedLivingDescription: "",
        dailySupportServices: "",
        dailySupportServicesDescription: "",
        helpAtHome: "",
        helpAtHomeDescription: "",
        hospice: "",
        hospiceBulletFour: "",
        hospiceBulletOne: "",
        hospiceBulletThree: "",
        hospiceBulletTwo: "",
        hospiceDescription: "",
        mobileCare: "",
        mobileCareDescription: "",
        partiallyStaticCare: "",
        partiallyStaticCareBulletOne: "",
        partiallyStaticCareBulletThree: "",
        partiallyStaticCareBulletTwo: "",
        partiallyStaticCareDescription: "",
        sharedFlat: "",
        sharedFlatDescription: "",
        shortTermCare: "",
        shortTermCareDescription: "",
        staticCare: "",
        staticCareDescription: "",
      },
    },
    header: {
      altTextGutePflegeLogo: "",
      glossary: "Glossary",
      homePage: "",
      logout: "",
      mainNavigationAriaLabel: "",
      myAccount: "",
      register: "",
      requestDashboard: "",
      title: "Pflegefinder Bayern",
    },
    listingPage: {
      accessibility: {
        altTextLogo: "",
        consultantIconAltText: "",
        filterLabel: "",
        headline: "",
        markerAltText: "",
        notAvailableIconAltText: "",
        paginationCurrentPage: (args: { pagenumber: string }) =>
          template("You are currently on page {{pagenumber}} ", args),
        paginationFirstPageLabel: "",
        paginationGeneralLabel: (args: { page: string }) =>
          template("Go to page {{page}}", args),
        paginationLastPageLabel: "",
        paginationNavigationLabel: "",
        paginationNextPageLabel: "",
        paginationPreviousPageLabel: "",
        permanentFilterLabel: "",
        productTourHeading: "",
        providerIconAltText: "",
        providerSearchBarLabel: "",
        suggestionsAriaLabel: "",
        zipcodeFieldLabel: "",
      },
      actionBar: {
        filter: {
          filterCta: "",
        },
      },
      appliedFilters: {
        clearFilters: "",
        title: "",
      },
      consultantCard: {
        addressDistance: (args: { zipcode: string }) =>
          template("from {{zipcode}}", args),
        agency: (args: { sponsorName: string }) =>
          template("Project sponsor: {{sponsorName}}", args),
        appointmentInformation: "",
      },
      consultantFacility: "",
      consultantsNearby: "",
      noResultsText: "",
      noResultsTitle: "",
      print: "",
      printColumnTitleFacilities: "",
      printTitle: "",
      productTour: "",
      productTourButton: "",
      providerCard: {
        addressDistance: (args: { distance: string; zipcode: string }) =>
          template("{{distance}}km from {{zipcode}}", args),
        capacity: {
          capacityUnknown: "",
          capacityUpdatedAt: (args: { updatedAt: string }) =>
            template("Updated on {{updatedAt}}", args),
          freeCapacity: "",
          noCapacity: "",
        },
        moreProviders: "and more services",
      },
      providerSearchBarNoResult: "",
      providerSearchBarPlaceholder: "",
      result: "",
      results: "",
      zipCodeModal: {
        body: "",
        privacyPolicy: "",
        startButton: "",
        termsOfUse: "",
        title: "",
      },
    },
    loginDropDown: {
      bulletFour: "",
      bulletOne: "",
      bulletThreee: "",
      bulletTwo: "",
      createAccountButton: "",
      loginButton: "",
      providersInformation:
        "We look forward to receiving your e-mail to {select:pflege-finder-bayern@recaresolutions.com}",
      providersTitle: "",
      title: "",
    },
    loginModal: {
      accessibilityHelp: "",
      createAccount: {
        acceptDPA:
          "I consent to the data processing as described in the {select:Data protection information}.",
        acceptDPAError: "",
        acceptTerms: "I accept the {select:User Agreement}.",
        acceptTermsError: "",
        alreadyExistsError:
          "An account with this email address exists already. Please {select:click here} to log in.",
        alreadyExistsMessage: "",
        confirmEmailPlaceholder: "",
        emailErrorNotEmail: "",
        emailErrorNotMatch: "",
        emailOnePlaceholder: "",
        infoText: "",
        logInLinkInfo: "{select:Log in with an existing e-mail address.} ",
        registerAccessibilityHelp: "",
        submitButton: "",
        successCaption: "",
        successText:
          "We sent an email to {select:email} with a sign-up link. Please click on the link in this email to log in to the platform.",
        successTitle: "",
        title: "",
      },
      loginModal: {
        errorAccountDoesNotExist:
          "An account for this email address does not exist.",
        errorAccountNotActive: "",
        errorGeneric: "",
        registerLinkInfo: "{select:Register with a new e-mail address.} ",
        successCTA: "",
        successText: "",
        successTitle: "",
        title: "",
      },
      needHelp: "Do you need help? {select:Contact us here}",
    },
    loginSuccessful: "",
    logoutModal: {
      buttonCancel: "",
      buttonLogout: "",
      subtitle: "",
      title: "",
    },
    map: {
      grantConsentButton: "",
      grantConsentMapboxLink:
        "I agree that the map will be displayed to me and that my IP address and location data will be transmitted to {select:Mapbox Inc.} and stored for a maximum of 30 days. ",
      grantConsentText:
        "You can find more information in our {select:data protection information}.",
      grantConsentTitle: "",
      legendConsultant: "",
      legendNotAvailable: "",
      legendProvider: "",
      legendTitle: "Explication des symboles",
    },
    notLoggedInError: "",
    onboardingFunnel: {
      answerOther: "",
      answerUnknown: "",
      backButton: "",
      firstStep: {
        consultants: "",
        error: "",
        providers: "",
        question: "",
        stepCount: "",
        subtitle: "",
      },
      fourthStep: {
        asap: "",
        days: "",
        question: "",
        stepCount: "",
        weeks: "",
      },
      loadingScreen: "",
      nextButton: "",
      secondStep: {
        helpAtHome: "",
        hospice: "",
        mobileCare: "",
        question: "",
        sharedFlat: "",
        shortTermCare: "",
        staticCare: "",
        stepCount: "",
      },
      skipButton: "",
      startSearchButton: "",
      stepper: (args: { step: string; totalSteps: string }) =>
        template("{{step}} out of {{totalSteps}} questions", args),
      stepperFormatted: (args: { step: string; totalSteps: string }) =>
        template("{select:Question {{step}}} of {{totalSteps}}", args),
      textDontKnowStartSearch: "",
      textDontKnowWithGlossaryLink:
        'Not sure what you want to search for? Find out more in the {select:glossary} or simply click on "Next".',
      textDontKnw: "",
      thirdStep: {
        dementia: "",
        hospice: "",
        intensiveCare: "",
        psychiatricCare: "",
        question: "",
        stepCount: "",
      },
    },
    pageTitles: {
      accountPageDescription: "",
      accountPageTitle: "",
      auctionResponseDescription: "",
      auctionResponseTitle: "",
      consultantProfileDescription: "",
      consultantProfileTitle: "",
      listingsDescription: "",
      listingsTitle: "",
      loginPageTitle: "",
      onboardingFunnelDescription: "",
      onboardingFunnelTitle: "",
      printListingPageTitle: (args: { date: string }) =>
        template(
          "Pflegefinder Bayern - your search results from {{date}}",
          args,
        ),
      providerProfileDescription: "",
      providerProfileTitle: "",
      searchDashboardDescription: "",
      searchDashboardTitle: "",
      searchForDescription: "",
      searchForTitle: "",
      solutionsDescription: "",
      solutionsTitle: "",
      specialNeedsDescription: "",
      specialNeedsTitle: "",
      startTimeDescription: "",
      startTimeTitle: "",
      zipCodeDescription: "",
      zipCodeTitle: "",
    },
    preprodLogin: "Login",
    providerDetailsPage: {
      backToListing: "",
      bannerAlreadyContacted:
        "This provider has already been contacted. You can see these requests on the {select:search dashboard}.",
      contactSection: {
        address: "",
        addressDistance: (args: { distance: string; zipcode: string }) =>
          template("{{distance}} km from {{zipcode}}", args),
        addressDistanceZero: (args: { zipcode: string }) =>
          template("is in {{zipcode}}", args),
        buttonLogin: "",
        buttonSignup: "",
        capacity: {
          buttonContact: "",
          capacityUpdatedAt: (args: { updatedAt: string }) =>
            template("(Last updated on {{updatedAt}})", args),
          freeCapacityNoStartDate: "",
          freeCapacityWithStartDate: "",
          noCapacityNoStartDate: "",
          noCapacityWithStartDate: "",
          unknownCapacity: "",
        },
        contactCaption: "",
        copiedEmailTooltip: "",
        copyEmailFailedTooltip: "",
        copyEmailTooltip: "",
        distance: "",
        email: "Email:",
        logInCaption: "",
        openingHours: {
          closed: "",
          heading: "",
        },
        telephone: "",
        title: "",
        website: "",
        websiteTooltip:
          "External link to the website of the provider (Opens in new window) ",
      },
      defaultImageCaption: "",
      descriptionText: {
        careprovider: "",
        consultant: "",
        consultantCaresupportcenter: "",
        consultantDementia: "",
        consultantSpecialist: "",
      },
      disclaimer: "",
      overweightServiceExplanation: (args: { weight: string }) =>
        template(
          "Adequate care for people weighing more than {{weight}} kg requires appropriate equipment and trained personnel. This point is therefore specifically mentioned.",
          args,
        ),
      print: "",
      providerImageCaption: "",
      servicesHeading: "",
      solutionsHeading: "",
    },
    redactedRequestsInfoCard: (args: { redactedCount: string }) =>
      template(
        "There were  {{redactedCount}} more direct request, but it has been deleted by the user who closed their account.",
        args,
      ),
    requestConfirmationModal: {
      ageGroup: "",
      backButton: "",
      careDuration: "",
      careLevel: "",
      careNeeds: "",
      errorStartDate: "",
      errorTypeOfCareMissing: "",
      errorZipCodeMissing: "",
      gender: "",
      seeMore: "",
      seeRequest: "",
      sendButton: "",
      startDate: "",
      title: "",
      typeOfCare: "",
    },
    searchDashboard: {
      acceptedSection: {
        emptyState: "",
        subtitle: "",
        title: "",
      },
      accessibility: {
        ariaLabelArchivedProfiles: "",
        ariaLabelProfileTab: "",
        menuLabel: "",
      },
      archivedSection: {
        emptyState: "",
        subtitle: "",
        title: "",
      },
      backButton: "",
      inactiveStateTooltip: "",
      informationText: "",
      pageTitle: "",
      patientProfile: {
        activeProfilesSubtitle: "",
        activeProfilesTitle: "",
        archivedTab: "",
        archiveFlow: {
          buttonArchive: "",
          buttonCancel: "",
          subtitle: "",
          title: (args: { id: string }) =>
            template(
              "Are you sure that you want to archive profile {{id}}?",
              args,
            ),
        },
        archiveProfile: (args: { id: string }) =>
          template("Profile {{id}}", args),
        archiveTab: {
          date: (args: { date: string }) =>
            template("Archived on {{date}}", args),
          profile: (args: { id: string }) => template("Profil {{id}}", args),
          title: "",
        },
        informationId: "",
        myProfiles: "",
        profileName: (args: { id: string }) => template("Profil {{id}}", args),
        seeRequestsButton: "",
        tabProfileTitle: "Your profiles",
      },
      profileArchivedSuccessfully: "",
      requestCard: {
        buttonDetails: "",
        buttonNewMessage: "",
        date: (args: { date: string }) => template("Am {{date}}", args),
        seeMore: "",
        startDate: (args: { date: string }) => template("From {{date}}", args),
        status: "",
        subtitleAccepted: "",
        subtitleCancelled: "",
        subtitlePending: "",
        subtitleRejected: "",
        subtitleValidated: "",
        titleAccepted: "",
        titleCancelled: "",
        titlePending: "",
        titleRejected: "",
        titleValidated: "",
      },
      sectionHeader: (args: { id: string }) =>
        template("Contact requests for profile {{id}}", args),
      validatedSection: {
        emptyState: "",
        subtitle: "",
        title: "",
      },
    },
    selectPatientModal: {
      buttonAddNew: "",
      buttonGoToRequest: "",
      noServicePlaceholder: "",
      profileName: (args: { identifier: string }) =>
        template("Profil {{identifier}}", args),
      selectButton: "",
      title: "",
      warningAlreadyContact: "",
      warningDoesNotMatch: "",
      warningMaxNumberOfProfiles:
        'To create another profile, you must first archive an existing profile on the {select: contact request page}. To do this, click on "Archive" below the profiles.',
      warningMaxRequetsSent: "",
    },
    tableOfContents: {
      accountPage: "Account page",
      activePatientsSubheading: "Active Patients",
      activeRequestsSubheading: "Active Requests",
      archivedPatientSubheading: "Archived Patients",
      ariaLabel: "",
      inactiveRequestsSubheading: "Inactive Requests",
      pageDescription: "Provides a table of contents for the application",
      pageTitle: "Table of contents",
      patientDashboard: "Patient dashboard",
      profileIdLink: "Profile ID:",
      requestLink: "Request to careprovider: ",
      startPage: "Start page",
      title: "Table of contents",
      validatedRequestsSubheading: "Validated Requests",
    },
  },
  pwaSettings: {
    chromeInstallInstructionOne:
      "Cliquez sur le bouton ci-dessous pour installer l'application sur votre appareil",
    confirmedActions:
      "Application Recare installée et notifications autorisées",
    copyDebugInformationToClipboard:
      "Copier les informations dans le presse-papiers",
    debugTitle: "Informations sur l'appareil",
    ensurePermissionsGrantedInSettings:
      "Si la notification n'a pas été reçue, assurez-vous que l'application est autorisée à vous envoyer des notifications dans vos paramètres.",
    ensureToAllowNotifications:
      "Assurez-vous d'autoriser les notifications lorsque vous y êtes invité si vous souhaitez recevoir des notifications sur votre appareil. Si vous ne le faites pas, vous devrez peut-être désinstaller et répéter cette étape.",
    errorAskingPermission: (args: { error: string }) =>
      template(
        "Une erreur s'est produite lors du traitement de votre demande : {{error}}",
        args,
      ),
    ifAlreadyInstalled:
      "Si vous avez déjà installé l'application, veuillez accéder à l'application installée sur votre écran d'accueil pour continuer",
    installButtonNotAvailable:
      "Veuillez installer l'application en accédant aux paramètres de votre navigateur et en installant l'application.",
    installToDeviceButton: "Installer sur l'appareil",
    iOsInstallInstructionOne:
      "Cliquez sur Partager {select:icon} et \"Ajouter à l'écran d'accueil\" pour installer l'application",
    iOsVersionError: (args: { version: string }) =>
      template("Version iOS autorisée ci-dessous {{version}}", args),
    notificationAPIUnspported:
      "API de notification non prise en charge sur cet appareil, veuillez essayer de fermer et de rouvrir l'application. Si cela ne fonctionne pas, veuillez contacter Recare avec vos informations de débogage ci-dessous.",
    notificationPermissionGranted: "Autorisation de notifications",
    pleaseInstallWithChrome:
      "Il est recommandé d'installer cette application dans Chrome pour une meilleure fonctionnalité",
    pleaseInstallWithSafari:
      "Les PWA doivent être installées avec Safari lors de l'utilisation d'iOS",
    pleaseUpdatePermissions:
      "Veuillez mettre à jour les autorisations de cette application pour envoyer des notifications dans les paramètres de votre appareil.",
    pleaseUseMobileDevice:
      "Veuillez utiliser un appareil mobile pour installer cette application",
    promptDismissed:
      "Invite d'installation rejetée, veuillez installer l'application via les paramètres du navigateur Chrome ou actualiser la page.",
    pushManagerError:
      "Pas d'abonnement push, fermez et rouvrez l'application, à défaut veuillez la réinstaller pour recevoir les notifications.",
    pushManagerSubscribed: "Gestionnaire de push : abonné",
    pwaInstalled: "Application installée ",
    reestablishPermission:
      "Si vous avez précédemment refusé de recevoir des notifications,  alors ouvrez les paramètres de votre navigateur, autorisez les notifications pour l'application Recare, puis fermez et réouvrez l'application. Si cela ne fonctionne toujours pas, supprimez puis réinstallez l'application et assurez-vous d'autoriser les notifications lors de la première étape.",
    requestNotificationPermissionButton:
      "Demander l'autorisation de notification",
    serviceWorkerState: "Employé de service :",
    serviceWorkerSucess: "Service Worker installé et actif",
    settingsTitle: "Application",
    showDebugSwitchLabel: "Afficher les informations de débogage",
    stepOne: "Étape 1, installez l'application sur l'écran d'accueil",
    stepThree: "Étape 3, activer les notifications",
    stepTwo: "Étape 2, autorisez les notifications",
    subheading:
      "Pour installer l'application Recare sur votre appareil mobile et recevoir des notifications, veuillez suivre les étapes ci-dessous",
    subscribeToRecareNotifications: "Activer les notifications ",
    testNotification: "Je suis une notification de test",
    testNotificationButton: "Notification de test",
    thankYouForInstalling:
      "Merci d'avoir installé l'application, veuillez ouvrir Recare à partir de vos applications installées pour continuer",
    title: "Paramètres application",
    updateServiceWorker:
      "Cliquez ici pour mettre à jour le technicien de service",
  },
  recare: {
    signature:
      "Recare France \n5 rue Denis Poisson\n75017 Paris\ninfo@recaresolutions.fr",
  },
  rehaOnboarding: {
    companyDetails: {
      copyAndPaste: "",
      deleteSpecialization: "",
      hintCompanyMailAddress: "",
      hintCompanyName:
        "You are about to create an account for {select: companyName}",
      hintCopyAndPaste: "",
      hintSpecializations: "",
      modalCopyAndPasteTitle: "",
      modalNothingFilledYet: "",
      modalTitleFillTheFormFor: "Formulaire de",
      specializationsTitle: "",
      title: "",
      toastCompleteForms: "",
    },
    congratulations: {
      contactUs:
        "Contactez-nous au 01 76 42 05 40 si vous avez d'autres questions.",
      infoText:
        "Nous avons reçu toutes vos informations. Vous allez recevoir à cet effet un email de confirmation.",
      thankYou: "Merci beaucoup, nous avons hâte de travailler avec vous !",
      title: "Félicitations !",
      yourTeam: "Votre équipe Recare",
    },
    copyPasteFabHint:
      "Remplir automatiquement ce formulaire aves les informations utilisées précédemment ",
    statusCompleted: "Suivant",
    statusNotFilled:
      "Veuillez cliquer sur la flèche pour remplir ce formulaire",
    statusNotFillNoExpansion: "Veuillez cliquer ici pour remplir ce formulaire",
    yourTeam: {
      contactRole:
        "Afficher les coordonnées de cet utilisateur auprès de l'adresseur",
      deleteEmployee: "Supprimer le compte",
      hintEmail: "Veuillez rentrer votre adresse email personnelle",
      hintEmployee:
        "Veuillez rajouter à la liste votre compte ainsi que celui de vos collègues.",
      mailPlaceholderText: "Veuillez rentrer votre adresse email personnelle",
      modalFinishSubtitle:
        "Veuillez confirmer l’exactitude des informations. Vous pourrez toujours ajouter d’autres comptes à votre établissement dans les réglages. Il faudra néanmoins contacter Recare si vous souhaitez ajouter de nouvelles spécialités. ",
      modalFinishTitle: "Vous avez finalisé l’enregistrement de votre unité",
      placeholderEmployee: (args: { countOfcreatedEmployee: string }) =>
        template("Compte #{{countOfcreatedEmployee}}", args),
      title: "Comptes associés à votre unité",
      titleAccess: "Ce compte a accès à",
      toastCompleteEmployee:
        "Veuillez ajouter au moins un employé, supprimer les profils d'employés qui ne sont pas remplis ou remplir tous les formulaires.",
    },
  },
  report: {
    activeProviders: "Établissements actifs",
    allSearches: "Toutes les recherches",
    attribution: "Attributions",
    automaticSuccesses: "Succès automatiques",
    cancelled: "Annulées",
    cancelledSearches: "Recherches annulées",
    created: "Créées",
    descAttribution:
      "Temps moyen entre le début de la recherche et l'attribution",
    descHours: "In business hours: 8am to 6pm",
    descOmission:
      "Les recherches avec des types de demandes différentes ne sont pas prises en compte",
    descPositive:
      "Temps moyen entre le début de la recherche et la première réponse positive",
    dischargedWithoutProvider:
      "Patient(s) sorti(s) de l'établissement sans solution d'aval",
    endDate: "Date de fin",
    inProgress: "En cours",
    inProgressSearches: "Recherches en cours",
    manualSuccesses: "Succès manuels",
    numPatients: "Nombre de patients attribués",
    other: "Autre",
    patientCreated: "Patients créés :",
    percentagePatients: "% de patients attribués",
    placeholderOnlyAvailableInProd:
      "Report is only visible on production environment",
    positiveResponse: "Première réponse positive",
    providerFoundWithoutRecare: "Établissement trouvé sans passer par Recare",
    providerFoundWithRecare: "Établissement trouvé grâce à Recare",
    providerName: "Nom des établissements",
    providersPerSolution: (args: { radius: string }) =>
      template(
        "Nombre d'établissements receveurs actifs dans un rayon de {{radius}}km",
        args,
      ),
    reason: "Raison sélectionnée",
    relevant: "Pertinentes",
    relevantSearches: "Recherches pertinentes",
    relevantSearchesStatusTitle: "Recherches pertinentes par statut :",
    repartitionSolutionsTitle:
      "Répartition des recherches par type d'hospitalisation",
    reportCreated: (args: { date: string }) =>
      template("Reporting (généré le {{date}})", args),
    solution: "Type",
    startDate: "Date de début",
    thirdPartyReasons: "Tierces raisons d'annulation des recherches",
    timeToSuccessTitle: "Temps jusqu'au succès par type d'hospitalisation",
    total: "Total",
    transferSuccessesTitle:
      "Transferts des succès manuels et automatiques vers :",
    unknown: "Le nom de l'établissement trouvé n'a pas été renseigné",
    unknownAll: (args: { receiverTypeSingular: string }) =>
      template(
        "Le nom de l'{{receiverTypeSingular}} n'a pas été renseigné",
        args,
      ),
  },
  requestEvents: {
    addedTheProvider: {
      myAccount: (args: { providerName: string }) =>
        template("Vous avez ajouté {{providerName}}", args),
      otherAccount: (args: { accountName: string; providerName: string }) =>
        template("{{accountName}} a ajouté {{providerName}}", args),
    },
    answerProviderMessage: "Veuillez répondre au message",
    canceledAcceptance: (args: { providerName: string }) =>
      template("{{providerName}} a annulé la prise en charge.", args),
    canceledDeclination: (args: { providerName: string }) =>
      template(
        "{{providerName}} a annulé le refus de la prise en charge",
        args,
      ),
    canceledProviderRejection: {
      myAccount: (args: { providerName: string }) =>
        template("Vous avez annulé le rejet de {{providerName}}", args),
      otherAccount: (args: { accountName: string; providerName: string }) =>
        template("{{accountName}} a annulé le rejet de {{providerName}}", args),
    },
    canceledProviderValidation: {
      myAccount: (args: { providerName: string }) =>
        template("Vous avez annulé la validation de {{providerName}}", args),
      otherAccount: (args: { accountName: string; providerName: string }) =>
        template(
          "{{accountName}} a annulé la validation de {{providerName}}",
          args,
        ),
    },
    mailBounced: "La demande n'a pas pu être délivrée",
    providerAcceptedTheRequest: (args: { providerName: string }) =>
      template("{{providerName}} a accepté la demande", args),
    providerDeclinedRequest: (args: { providerName: string }) =>
      template("{{providerName}} a décliné la demande", args),
    rejectedThisProvider: {
      myAccount: (args: { providerName: string }) =>
        template("Vous avez rejeté {{providerName}}", args),
      otherAccount: (args: { accountName: string; providerName: string }) =>
        template("{{accountName}} a rejeté {{providerName}}", args),
    },
    removedSolution: "Le type d’hospitalisation ne correspond plus",
    requestAccepted: "La demande a été acceptée.\nMerci d'y répondre.",
    requestedPatientData: {
      myAccount: "Vous avez demandé plus d'informations sur le patient",
      otherAccount: (args: { accountName: string }) =>
        template(
          "{{accountName}} a demandé plus d'informations sur le patient",
          args,
        ),
    },
    requestSent: (args: { careprovider: string }) =>
      template("Demande envoyée à {{careprovider}}", args),
    requestSomeMailBounced: (args: { count: string; total: string }) =>
      template(
        "La demande n’a pu être envoyée qu’à {{count}} adresse(s) email sur {{total}}",
        args,
      ),
    sentaFile: {
      myAccount: "Vous avez envoyé un document",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} a envoyé un document", args),
    },
    sharedPatient: {
      automatic: (args: { providerName: string }) =>
        template(
          "{{providerName}} a reçu les données personnelles du patient ",
          args,
        ),
      myAccount: (args: { providerName: string }) =>
        template(
          "Vous avez donné accès aux données personnelles du patient à {{providerName}}",
          args,
        ),
      otherAccount: (args: { accountName: string; providerName: string }) =>
        template(
          "{{accountName}} a donné accès aux données personnelles du patient à {{providerName}}",
          args,
        ),
    },
    updatedPatient: {
      myAccount: "Vous avez mis à jour le profil du patient",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} a mis à jour le profil", args),
    },
    updatedPatientRuledOut:
      "Le profil a été mis à jour et ne correspond plus à l'offre de services car les champs suivants ont été modifiés :",
    validatedThisProvider: {
      myAccount: (args: { providerName: string }) =>
        template("Vous avez validé {{providerName}}", args),
      otherAccount: (args: { accountName: string; providerName: string }) =>
        template(
          "{{accountName}} a validé {{providerName}} (automatique)",
          args,
        ),
    },
    waitingForProviderAnswer:
      "La demande n'a pas encore été acceptée ou déclinée.",
    wrote: {
      myAccount: "Vous avez envoyé un message",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} a écrit un message", args),
    },
  },
  responseEvents: {
    acceptedMultiSolution: {
      myAccount: "Vous offrez la prise en charge suivante : ",
      otherAccount: (args: { providerName: string }) =>
        template("{{providerName}} propose ce qui suit :", args),
    },
    acceptedTheRequest: {
      myAccount: "Vous avez accepté la demande",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} a accepté cette demande", args),
    },
    answerTheRequest: "Merci de répondre à cette demande",
    askAQuestion: "Poser une question",
    aUser: "un utilisateur",
    canceledAcceptance: {
      myAccount: "Vous avez annulé la prise en charge du patient",
      otherAccount: (args: { accountName: string }) =>
        template(
          "{{accountName}} a annulé la prise en charge du patient",
          args,
        ),
    },
    canceledDeclination: {
      myAccount: "Vous avez annulé le refus de la prise en charge.",
      otherAccount: (args: { accountName: string }) =>
        template(
          "{{accountName}} a annulé le refus de la prise en charge.",
          args,
        ),
    },
    clinicCanceledAttribution: (args: { accountName: string }) =>
      template("{{accountName}} a annulé la prise en charge", args),
    clinicCanceledAttributionWithReason: (args: {
      accountName: string;
      reason: string;
    }) =>
      template("{{accountName}} a annulé la prise en charge: {{reason}}", args),
    declinedRequest: {
      myAccount: "vous avez décliné cette demande",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} a décliné cette demande", args),
    },
    patientAttributedToYou: "Le patient vous a été attribué",
    requestArchivedAutomatically:
      "Cette demande a été archivée automatiquement",
    requestArchivedManually: {
      myAccount: "Vous avez archivé cette demande.",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} a archivé cette demande.", args),
      tools: "La demande a été archivée",
      undo: {
        myAccount: "Vous avez annulé l'archivage.",
        otherAccount: (args: { accountName: string }) =>
          template("{{accountName}} a annulé l'archivage.", args),
        tools: "La demande n'a pas été archivée",
      },
    },
    requestReceived: (args: { socialWorker: string }) =>
      template("Demande reçue de {{socialWorker}}", args),
    searchArchivedAutomatically: "Ce profil a été archivée automatiquement",
    waitingForClinic: "En attente de réponse ...",
  },
  search: {
    accepted: "Accepté",
    accessibility: {
      searchLabel: "",
    },
    accessToPatientDataGrantedTooltip: "Données chiffrées du patient partagées",
    accessToPatientDataNotGrantedTooltip:
      "Le receveur n'a pas accès aux données du patient",
    actionRequiredBannerTitle: "",
    addPreferredProvider: (args: { receiverTypeSingularPreferred: string }) =>
      template("Ajouter un {{receiverTypeSingularPreferred}}", args),
    addProvider: {
      addAnother: "Autre choix",
      care: "Ajouter une préférence",
      contactProviders: "Contacter",
      geriatrics: "Ajouter une préférence",
      noProviderSelected: "Veuillez sélectionner des receveurs",
      restartedMovedToStoppedSunsetCostpayerflow: "",
      successfullySentRequests: (args: { numRequests: string }) =>
        template(
          "{{numRequests}} receveurs ont été contactés avec succès",
          args,
        ),
      successfullySentSingleRequest: "Le receveur a été contacté avec succès",
    },
    allContacted: (args: { receiverTypePlural: string }) =>
      template(
        "Tous les {{receiverTypePlural}} correspondant au patient ont été contactés",
        args,
      ),
    allContactedNotAllAnsweredTitle: {
      care: "Tous les établissements ont été contactés. Certains n'ont pas encore répondu :",
      geriatrics:
        "Tous les établissements ont été contactés. Certains n'ont pas encore répondu :",
    },
    allContactedTitle: {
      care: "Tous les établissements ont été contactés",
      geriatrics: "Tous les établissements ont été contactés",
    },
    allNegativeSendMore:
      "Toutes les demandes envoyées ont été rejetées. Les autres établissements sont pour l'instant filtrés de la recherche (sectorisation, pas de lits disponibles, etc). ",
    allNegativeSuggestMore: "",
    allProvidersRejected: {
      subtitle: "Veuillez ajouter d'autres receveurs à la recherche.",
      title: "Toutes les demandes ont été refusées. ",
    },
    answerSent: "Votre réponse a été envoyée",
    archivePatientDescription:
      'Vous pouvez retrouver les recherches archivées sous l\'onglet "Archivé" ou via la barre de recherche',
    auctionStatusStepper: {
      notStarted: "La recherche n'a pas encore été lancée ",
      providerConfirmed: "Transfert validé",
      requestsSent: "Demandes envoyées",
      responseReceived: "Réponse positive reçue ",
      searchStarted: "Recherche lancée",
      waitingForResponse: "En attente de réponse",
    },
    cancelCardAction: "Annuler",
    cancelValidationPanel: {
      confirm: "Annuler la prise en charge",
      hint: "Veuillez noter que l'explication entrée ci-dessous sera affichée dans l'onglet Activité.",
      placeholder: "Veuillez spécifier",
      title:
        "Pourquoi souhaitez-vous annuler la prise en charge par ce receveur ? ",
    },
    candidatesCalculating:
      "Nous recherchons les receveurs potentiels, veuillez patienter",
    canNotStart:
      "Veuillez modifier le profil afin de lancer la recherche. Tous les champs obligatoires doivent être remplis.",
    cannotStartSearchNoCandidates: {
      care: "Il n'y a aucun établissement à contacter, la recherche ne peut être démarrée. Merci de vérifier que les informations sont correctes (adresse etc.).",
      geriatrics:
        "Il n'y a aucun établissement à contacter, la recherche ne peut être démarrée. Merci de vérifier que les informations sont correctes (adresse etc.).",
    },
    careproviderSearch: "Rechercher un receveur",
    careproviderUnknown: {
      care: "Nom inconnu",
      geriatrics: "Nom inconnu",
    },
    careproviderUnknownViaFamily:
      "Le nom de l'établissement d'accueil est inconnu",
    chipPaused: "En pause",
    chosen: "Sélectionné",
    confirmChooseProvider: (args: {
      careproviderName: string;
      patientId: string;
    }) =>
      template(
        "Vous confirmez choisir {{careproviderName}} pour le patient {{patientId}} ?",
        args,
      ),
    confirmTitleAboutToValidate: (args: { careproviderName: string }) =>
      template("Vous êtes sur le point de valider {{careproviderName}}.", args),
    contactedNotAllAnswered: (args: { receiverTypePlural: string }) =>
      template(
        "Tous les {{receiverTypePlural}} correspondant au patient ont été contactés. Certains n'ont pas encore répondu: ",
        args,
      ),
    contactedProviders: "",
    continueSearch: "Re-lancer",
    declined: {
      care: "Demande déclinée",
      geriatrics: "Demande déclinée",
    },
    draft: "Brouillon",
    draftMovedToStoppedSunsetCostpayerflow: "",
    editAcceptedSearch:
      "Il est possible de modifier le profil patient une fois la recherche aboutie :",
    editOrRestart:
      "Il est également possible de modifier le profil ou re-lancer la recherche.",
    editProfileTitle: "Ou continuez d'éditer le profil :",
    editProfileToBeStarted: "",
    editRunningSearch:
      "Vous pouvez modifier le profil du patient lorsque la recherche est en cours :",
    filteredProviders: "",
    foundProviderTitle: (args: { receiverTypeSingular: string }) =>
      template("Cet {{receiverTypeSingular}} a été sélectionné", args),
    goToSearch: "Commencer la recherche",
    grantAccessToFollowing:
      "Vous pouvez désormais donner accès aux données personnelles suivantes :\n",
    handoverSearchTitle: "",
    howToStartSearchHint:
      'Vous pouvez ajouter manuellement des receveurs\nà la recherche en cours en les sélectionnant dans la liste ci-dessous. Ou bien, vous pouvez cliquer sur "contacter tous" pour contacter tous les receveurs potentiels. Vous pouvez également rechercher des receveurs spécifiques en saisissant leur nom dans la barre de recherche.',
    infoAutomaticVSManual: "",
    manuallyAdded: "Ajouté manuellement",
    manuallyRejected: "",
    manuallyRunning: "Recherche manuelle",
    manualNewAnswersBanner:
      "Actuellement, seuls les établissements privilégiés ont été contactés.",
    manualNoPendingRequestsTitle:
      "Toutes les demandes ont été déclinées ou rejetées",
    manualSearchInProgressTitle: "Recherche manuelle en cours",
    manualTransportBanner: "",
    maximumAcceptsReached: (args: { receiverTypePlural: string }) =>
      template(
        "Plusieurs {{receiverTypePlural}} ont déjà accepté votre demande ! Nous n'envoyons donc plus de nouvelles demandes pour le moment. Si vous rejetez une ou plusieurs acceptations, nous recommencerons alors à envoyer de nouvelles demandes.",
        args,
      ),
    maximumAcceptsReachedSearchMerge: () => "",
    noCandidatesCannotManuallySearch: (args: { receiverTypePlural: string }) =>
      template(
        "Il n'y a aucun {{receiverTypePlural}} potentiel à contacter. La recherche peut toujours être lancée et les destinataires trouvés lorsqu'ils sont disponibles.",
        args,
      ),
    noCandidatesCannotSearch: (args: { receiverTypePlural: string }) =>
      template(
        "Il n'y a pas d'{{receiverTypePlural}} à contacter. Merci de vérifier que les informations sont correctes (adresse etc.).",
        args,
      ),
    noCandidatesStartSearch: (args: { receiverTypePlural: string }) =>
      template(
        "Il n'y a pas d' {{receiverTypePlural}} à contacter. Vous pouvez toutefois lancer la recherche et ajouter des receveurs manuellement. ",
        args,
      ),
    noPotentialReceiversBanner: (args: { receiverTypePlural: string }) =>
      template(
        "Pour trouver plus d'{{receiverTypePlural}} disponibles, vous pouvez envoyer des demandes manuellement, augmenter le rayon de recherche ou nous contacter pour plus d'informations. ",
        args,
      ),
    noPotentialReceiversNoRequestSentBanner: {
      care: "Si aucun établissement n’a été contacté, veuillez vérifier que les informations telles que le code postal du patient ou le rayon de recherche sont correctes.",
      geriatrics:
        "Si aucun établissement n’a été contacté, veuillez vérifier que les informations telles que le code postal du patient ou le rayon de recherche sont correctes.",
    },
    noSuggestionsYet: {
      insurance: "",
      shareFiles:
        "En attendant: {select:Cliquez ici} pour partager des documents avec l'assurance",
      socialWorker: "",
    },
    notActiveTitle: "Établissement non enregistré sur la plateforme ",
    notStarted: "Recherche à lancer",
    notStartedYet: "",
    notStartedYetHints: {
      insurance: "",
      socialWorker: "",
    },
    onboarding: "L'établissement est en cours d'enregistrement",
    oneAcceptInfoBanner:
      "Merci de valider ou rejeter l'acceptation du receveur, les autres établissements ne pouvant plus répondre tant que l'acceptation n'a pas été traitée.",
    patientPreferred: {
      dataNotEncrypted:
        "Ce champ n'est pas chiffré. Veuillez ne pas saisir d'informations sensibles.",
      markAs: "Marquer comme souhait du patient",
      placeholder: "Raison de l'ajout du receveur comme privilégié",
      reason: "Raison",
      reasonNotEmpty: "Veuillez d'abord ajouter une nouvelle raison ",
      tooltipRemovePatientPreferred: "Ne plus indiquer comme privilégié",
    },
    patientWishInfo: "Les souhaits du patient sont marqués d'une étoile.",
    patientWishInfoIcon: "Souhait du patient",
    pause: "Mettre en pause",
    paused: "En pause",
    pauseDeprecated:
      "Veuillez noter que la recherche de pauses n'est plus nécessaire, car tous les fournisseurs potentiels ont été contactés.",
    pauseDeprecatedInformation:
      "En raison des récents changements apportés à la fonction recherche, il n'est désormais plus possible de mettre en pause une recherche.",
    pausedMovedToStoppedSunsetCostpayerflow: "",
    pausedTitle: "La recherche est en pause",
    pauseTitle: "Êtes-vous sûr(e) de vouloir mettre la recherche en pause ?",
    pauseWarning:
      "Une fois la recherche mise en pause, le système n'enverra plus de nouvelles demandes automatiquement. Vous pourrez envoyer de nouvelles demandes manuellement et toutes les demandes déjà envoyées pourront être traitées.",
    pending: "En attente",
    potentialProviders: "",
    printConfirmation: "Imprimer la confirmation",
    providerFound: "Établissement trouvé",
    providerFoundTitle: {
      care: "Vous avez validé ce receveur",
      geriatrics: "Cet établissement a été sélectionné",
    },
    providersWhoAccepted: "",
    providersWhoDeclined: "",
    providerUnknown: (args: { receiverTypeSingular: string }) =>
      template("Nom de l'{{receiverTypeSingular}} inconnu", args),
    receiverDecliner: (args: { receiverTypeSingular: string }) =>
      template("{{receiverTypeSingular}} a décliné ", args),
    receiverValidatedEligibilityRejected: {
      insurance: "",
      socialWorker: "",
    },
    receiverValidatedWaitingEligibility: {
      insurance: "",
      socialWorker: "",
    },
    rejected: "Demande rejetée",
    rejectedTitle: "Demandes rejetées",
    restartSearch: "Re-lancer",
    reviewRequest: "",
    running: "En cours",
    search: "Recherche",
    searchHasBeenRunningFor:
      "La recherche est en cours depuis plus de 2 heures. Il n'y a pas encore eu de réponse positive.",
    searchInProgress: "Recherches en cours",
    searchInProgressTitle: "La recherche est en cours",
    searchMergeNoProviders: "",
    searchPageTables: {
      accessibility: {
        clickFunctionality: (args: {
          providerName: string;
          tableName: string;
        }) =>
          template(
            "Cliquez pour afficher la page de réponse pour {{providerName}} dans le tableau {{tableName}}.",
            args,
          ),
        deselectAll:
          "Cliquez pour désélectionner tous les receveurs dans le tableau des receveurs potentiels afin d'ajouter manuellement de nouveaux receveurs. ",
        deselectProvider: (args: { providerName: string }) =>
          template(
            "Cliquez pour désélectionner {{providerName}} du tableau des receveurs potentiels",
            args,
          ),
        patientWishAccessibility: {
          addReason:
            "Cliquez pour indiquer, en option, une raison pour le souhait du patient",
          cancelReason: "Cliquez pour annuler la saisie",
          checkedStar: (args: { providerName: string }) =>
            template(
              "Cliquez pour désélectionner {{providerName}} comme souhait du patient",
              args,
            ),
          confirmReason: "Cliquez pour enregistrer la saisie",
          disabled:
            "Patient wish can't be edited as this field is currently disabled",
          editReason: "Cliquez pour modifier le souhait du patient",
          uncheckedStar: (args: { providerName: string }) =>
            template(
              "Cliquez pour indiquer {{providerName}} comme souhait du patient",
              args,
            ),
        },
        selectAll:
          "Cliquez pour sélectionner tous les receveurs dans le tableau des receveurs potentiels",
        selectAllIntermediary:
          "Cliquez pour sélectionner tous les receveurs dans le tableau des receveurs potentiels",
        selectProvider: (args: { providerName: string }) =>
          template(
            "Cliquez pour sélectionner {{providerName}} dans le tableau des receveurs potentiels",
            args,
          ),
        sortingMenu: {
          sortByDefault: "",
          sortByDistance: "",
          sortByName: "",
          sortByStatus: "",
          title: "Cliquez ici pour modifier le tri actuel",
        },
      },
      actionMenu: {
        acceptRequest: "Accept",
        declineRequest: "Decline",
        dismissSuggestedProvider: "Dismiss suggested provider",
        giveAccessToData: "Give access to patient data",
        nextRequest: "Next request",
        openRequest: "Open",
        rejectProvider: "Reject",
        sendFax: "Send fax",
        sendFile: "Send File",
        sendMessage: "Send message",
        sendRequestToSuggestedProvider: "Send request to suggested provider",
        setReceiverAssignee: "Set assignee",
        undoAcceptRequest: "Undo acceptance",
        undoDeclineRequest: "Undo declination",
        undoDismissRejectedProvider: "Undo dismissal of suggested providerUndo",
        undoManuallyArchiving: "Undo archiving",
        undoRejectProvider: "Undo rejection",
        undoSuggestProvider: "Undo suggestion",
        validateProvider: "Validate",
      },
      columnNames: {
        dateOfRequest: "Date de la recherche",
        declineDate: "Date du refus ",
        declineReason: "Raison du refus ",
        distance: "Domicile du patient",
        filterReason: "Filtre",
        hint: {
          patientWish:
            "Indiquez qu'il s'agit du souhait du patient en cliquant sur l'étoile",
        },
        internalRejectReason: "Raison de refus interne ",
        location: "Lieu ",
        potentialStartDate: "Date de transfert souhaitée",
        providerName: "Receveur",
        rejectDate: "Date du refus",
        rejectReason: "Raison du refus ",
        solutions: "Type de transfert ",
        startDate: "Date de début ",
        status: "Statut",
      },
      distanceToPatient: (args: { distance: string }) =>
        template("à {{distance}}", args),
      key: "key",
      manuallyAssignedWinner: {
        dataNotAvailable: "Inconnue",
        internalTransfer: "Transfert interne",
        unknownProvider: "Institution inconnue",
      },
      modals: {
        contactAnyway: {
          subtitle:
            'Cliquez sur "confirmer" pour ajouter le receveur à la recherche.',
          subtitleFax:
            "Veuillez noter que ce receveur n'est pas actif sur Recare, mais qu'il sera contacté par fax.",
          title: "Voulez-vous ajouter ce receveur à la recherche ?",
        },
        sendFax: {
          buttonCancel: "",
          buttonConfirm: "",
          buttonSend: "",
          subtitle: "",
          title: "",
          titleConfirmation: (args: { providerName: string }) =>
            template(
              "Are you sure you want to send a fax to {{providerName}}? ",
              args,
            ),
          warning: "",
        },
      },
      pagination: {
        next: "Next page",
        previous: "Previous page",
      },
      paginationSelectLabel: "Résultats par page",
      placeholderNoResults: "Il n'y a pas encore de résultat",
      potentialTableActions: {
        contactAll: "Contact all",
        contactSelected: "Contact selected",
        infoText:
          "These providers have not been contacted. You can contact a limited pool of providers by selecting them from the list and clicking on “contact selected”. To contact every potential provider please select “contact all”.",
        requestPreferredProvider: "Request preferred provider",
      },
      printModalTitle: "Quelles rubriques souhaitez-vous imprimer ?",
      printModalTooManyWarning: (args: { maxCount: string }) =>
        template(
          "Nous ne pouvons imprimer que jusqu'à {{ maxCount }} fournisseurs par section",
          args,
        ),
      sortBy: "Trier par",
      sorting: {
        default: "Défaut",
        distance: "Distance",
        name: "Nom",
        status: "Statut",
      },
      status: {
        accepted: "Accepté",
        faxSent: "Fax sent",
        newMessage: "Nouveau message",
        notSeen: "Envoyé",
        providerInactive: "Inactive",
        seen: "Vu",
      },
      tableNames: {
        accepted: (args: { total: string }) =>
          template("Acceptée ({{total}})", args),
        contacted: (args: { total: string }) =>
          template("Receveurs contactés ({{total}})", args),
        declined: (args: { total: string }) =>
          template("Refusée(s) ({{total}})", args),
        filtered: (args: { total: string }) =>
          template("Receveurs filtrés ({{total}})", args),
        potential: (args: { total: string }) =>
          template("Receveurs potentiels ({{total}})", args),
        rejected: (args: { total: string }) =>
          template("Rejeté(s) ({{total}})", args),
        subtitle: {
          contactedTable:
            "Les prestataires mentionnés ici n'ont pas encore répondu sous forme d'acceptation ou de refus.",
          filteredTable: "",
          potentialReceiversTable:
            "Cliquez sur la grande coche pour sélectionner ou désélectionner tous les receveurs d'un coup, ou bien sélectionnez des receveurs individuellement en cliquant sur la case située à côté de leur nom. ",
          rejectedTable:
            "Vue d'ensemble de tous les receveurs que vous avez rejetés. Si vous souhaitez annuler le rejet, cliquez sur la ligne du receveur puis sur \"Annuler\" dans l'espace de messagerie.\n",
        },
        validated: "Vous avez sélectionné ce receveur",
      },
    },
    searchPausedArchiveTitle:
      "Vous avez annulé la recherche. Souhaitez-vous archiver le profil ?",
    searchPausedTitle: "La recherche a été annulée",
    searchPausedWarning:
      "Veuillez noter que si vous éditez le profil et relancez la recherche, les établissements précédemment contactés seront alors notifiés de ces changements.",
    searchUpdatedNotification:
      "La mise à jour de vos informations patients peut avoir un impact sur la sélection des prestataires de soins contactés.",
    sendRequestCta: {
      contactAnyway: "Contact de toute façon",
      contactAnywayAndStartSearch: "Lancer la recherche et envoyer la demande",
      contactAnywaySearchNotStarted: "Contact this provider anyway",
      contactAnywaySearchRunning: "Ajouter ce receveur à la recherche",
      contactAnywaySearchStopped:
        "You need to restart the search in order to send requests",
      contactAnywaySearchWon:
        "Impossible d'envoyer des demandes une fois le patient attribué",
      sendFaxAndStartSearch: "Lancer la recherche et envoyer un fax",
    },
    sent: "Demande envoyée",
    sentTitle: "Demandes envoyées",
    setUpSearch: "Aller à la recherche ",
    shareEncryptedData: {
      assessment: {
        checkbox: (args: { receiverTypePlural: string }) =>
          template(
            "Partager automatiquement les données du patient (nom, prénom, date de naissance) avec les {{receiverTypePlural}}",
            args,
          ),
        title: "Partage des données personnelles du patient",
      },
      grantAccess: "Donner accès",
      hint: "Vous pouvez donner accès maintenant au prénom, nom et âge ou plus tard via l’espace de messagerie.",
      later: "Plus tard",
      title:
        "Souhaitez-vous donner accès aux données personnelles au(x) receveur(s) ?",
    },
    showMore: "Afficher plus",
    smartSuggestions: {
      autoSearchSelectedAll: (args: { receiverTypePlural: string }) =>
        template(
          "Aux {{receiverTypePlural}} sélectionnés et à d'autres receveurs potentiels ",
          args,
        ),
      autoSearchSelectedCare:
        "A tous les services (sélectionnés et potentiels)",
      autoSearchSelectedRehab:
        "À tous les services (services sélectionnés et potentiels)\n\n",
      distanceFromPatient: (args: { distance: string }) =>
        template("à {{distance}} du patient", args),
      hintManuallyAddedAll: (args: { receiverTypeSingular: string }) =>
        template(
          "Vous avez ajouté cet {{receiverTypeSingular}} manuellement.",
          args,
        ),
      hintManuallyAddedCare: "Vous avez ajouté cet établissement manuellement.",
      hintManuallyAddedRehab:
        "Vous avez ajouté cet établissement manuellement.",
      hintManuallyAddedShort: "Ajouté manuellement",
      manualSearchSelectedAll: (args: { receiverTypePlural: string }) =>
        template("Uniquement aux {{receiverTypePlural}} sélectionnés", args),
      manualSearchSelectedCare: "Uniquement aux services sélectionnés",
      manualSearchSelectedRehab: "Uniquement aux services sélectionnés",
      markAsPatientWish:
        "Informer le receveur qu'il s'agit du souhait du patient",
      panelAllTitleManualAndPreferred: (args: { receiverTypePlural: string }) =>
        template(
          "Ajoutez des {{receiverTypePlural}}  et cliquez sur l'étoile pour les informer de votre préférence \n\n",
          args,
        ),
      panelAllTitleManualAndPreferredAb: (args: {
        receiverTypeSingular: string;
      }) =>
        template(
          "Cochez la case pour contacter un {{receiverTypeSingular}} spécifique (sélection multiple possible) ou bien indiquez qu'il s'agit du souhait du patient en cliquant sur l'étoile à côté du receveur",
          args,
        ),
      panelTitleAll: (args: { receiverTypePluralPreferred: string }) =>
        template("Sélectionner les {{receiverTypePluralPreferred}}", args),
      panelTitleCare: "Sélectionner les établissements privilégiés (optionnel)",
      panelTitleRehab:
        "Sélectionner les établissements privilégiés (optionnel)",
      panelTitleRehaInsurance: "",
      panelTitleRehaInsurancePayer: "",
      panelTitleRunningAll: (args: { receiverTypePlural: string }) =>
        template(
          "Envoyer de nouvelles demandes aux {{receiverTypePlural}}",
          args,
        ),
      panelTitleRunningCare: "Envoyer de nouvelles demandes",
      panelTitleRunningCareAb: "",
      panelTitleRunningRehab: "Envoyer de nouvelles demandes",
      searchSelectorHintAll: (args: { receiverTypePlural: string }) =>
        template(
          "Si vous n'avez pas de préférence(s), Recare choisira les {{receiverTypePlural}} les plus pertinents",
          args,
        ),
      searchSelectorHintCare:
        "Si vous n'avez pas de préférence(s), Recare choisira les établissements les plus pertinents",
      searchSelectorHintRehab:
        "Si vous n'avez pas de préférence(s), Recare choisira les établissements les plus pertinents",
      searchSelectorOptionHintAll: (args: { receiverTypePlural: string }) =>
        template(
          "(à noter: les {{receiverTypePlural}} contactés précédemment ne seront pas affectés)",
          args,
        ),
      searchSelectorOptionHintCare:
        "(à noter : les établissements contactés précédemment ne seront pas affectés)",
      searchSelectorOptionHintRehab:
        "(à noter : les établissements contactés précédemment ne seront pas affectés)",
      searchSelectorTitle: "À qui souhaitez-vous envoyer la demande ? *",
      sendMoreRequests: "Envoyer d’autres demandes",
      sendRequests: "Envoyer d'autres demandes",
      titleAll: (args: { receiverTypePlural: string }) =>
        template("Recare suggère les {{receiverTypePlural}} suivants : ", args),
      titleAllNoSuggestions: (args: { receiverTypePluralPreferred: string }) =>
        template(
          "Vous pouvez sélectionner certains {{receiverTypePluralPreferred}} manuellement",
          args,
        ),
      titleCare: "Services recommandés : ",
      titleCareNoSuggestions:
        "Vous pouvez sélectionner des établissements privilégiés",
      titleRehab: "Services recommandés : ",
      titleRehabNoSuggestions:
        "Ajoutez manuellement vos établissements privilégiés",
      titleRehaInsurancePayer: "",
      titleRehaInsurancePayerNoSuggestions: "",
      tooltipPartnerClinic: (args: { receiverTypeSingular: string }) =>
        template("{{receiverTypeSingular}} is a partner of you", args),
      tooltipPatientsReason:
        "Here you can specify a reason for a preferred clinic of the patient. The insurance will then check this in accordance with §8 SGB IX (right of beneficiaries to request and choose).",
    },
    startSearchNoCandidates: {
      care: "Selon notre système, il n'y a aucun établissement à contacter. Vous pouvez néanmoins démarrer la recherche et ajouter un établissement manuellement.",
      geriatrics:
        "Selon notre système, il n'y a aucun établissement à contacter. Vous pouvez néanmoins démarrer la recherche et ajouter un établissement manuellement.",
    },
    startTheSearch: "Lancer la recherche",
    startTheSearchTitle:
      "Le profil patient est complet ? Vous pouvez lancer la recherche : ",
    stopNotEligible: "",
    stopped: "Annulée",
    stoppedSunsetCostpayerflow: "",
    suggestProvider: "",
    suggestToCostPayer: "",
    summary: {
      accepted: "Accepté",
      approved: "Validé",
      declined: "Decliné",
      declinedByProvider: "Prise en charge impossible",
      dismissedByCostPayer: "",
      oneAcceptTooltip:
        "Vous devez d’abord valider ou rejeter la demande qui a été acceptée avant de pouvoir envoyer de nouvelles demandes ",
      rejected: "Rejeté",
      sent: "Envoyé",
      sentFax: "",
      summary: "Résumé",
      unavailable: "Non disponible",
      waitingForClinicAnswer:
        "En attente de la réponse de l'établissement d'origine",
      waitingForProviderAnswer:
        "En attente de la réponse de l'établissement receveur",
    },
    switchOnSearchMergeActivated: "Activez la Search Merge: ",
    titleInfo: {
      confirmGrantAccess: (args: { careproviderName: string }) =>
        template(
          "Vous êtes sur le point de valider {{careproviderName}}. Souhaitez-vous leur donner accès aux données personnelles suivantes ? ",
          args,
        ),
      onlyPreferred: {
        care: "Actuellement, seuls les établissements privilégiés ont été contactés.",
        geriatrics:
          "Actuellement, seuls les établissements privilégiés ont été contactés.",
      },
      preferredOnlyContacted: (args: { receiverTypePluralPreferred: string }) =>
        template(
          "Actuellement seuls les {{receiverTypePluralPreferred}} ont été contactés",
          args,
        ),
      searchPaused:
        "Notre système n'envoie plus automatiquement de nouvelles demandes mais vous pouvez répondre aux demandes déjà envoyées.",
    },
    toBeSentTitle: "",
    toBeStarted: "",
    toBeStartedIns: "Search handed over",
    validated: "Validée",
    viaPatientFamily: "Trouvé par un autre moyen",
    willSendRequestShortly:
      "Les premières demandes vont être envoyées sous peu",
    zeroPotentialClinicsPlaceholder:
      "Considérant les critères sélectionnés, il n'y a aucun établissement à contacter pour le moment",
    zeroPotentialProvidersPlaceholder:
      "Considérant les critères sélectionnés, il n’y a aucun établissement à contacter pour le moment",
    zeroPotentialReceiversPlaceholderAll: (args: {
      receiverTypePlural: string;
    }) =>
      template(
        "Il n’y a pas d'{{receiverTypePlural}} à proximité en capacité de prendre des patients. \n",
        args,
      ),
  },
  termsAndConditions: {
    accept:
      "J'ai lu et j'accepte les {select:Conditions générales d'utilisation}.",
    agreedAt: (args: { date: string }) =>
      template("Accepté le {{date}}\n\n", args),
    termsAndConditions: "Conditions générales d'utilisation",
    titleOtherDocuments: "Autres documents",
    uploadedOn: (args: { date: string }) =>
      template("Téléchargé le  {{date}}", args),
  },
  test: "test",
  timeline: {
    addedPatientWishReason: {
      deletedPatientWishReason: "",
      myAccount: "",
      otherAccount: () => "",
    },
    anotherProviderSelected: "Un autre établissement receveur a été choisi",
    anotherProviderWasSelected: "Un autre établissement a été choisi",
    assigneeChanged: (args: {
      oldAssigneeName: string;
      newAssigneeName: string;
    }) =>
      template(
        "The case has been transferred from {{oldAssigneeName}} to {{newAssigneeName}}",
        args,
      ),
    auctionAllContacted: "Tous les établissements potentiels ont été contactés",
    auctionCancelled: (args: { accountName: string }) =>
      template("La recherche a été annulée par {{accountName}}", args),
    auctionPaused: {
      automatic: "La recherche a été automatiquement mise en pause",
      myAccount: "Vous avez mis la recherche en pause",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} a mis la recherche en pause", args),
    },
    auctionStarted: (args: { accountName: string }) =>
      template("La recherche a été démarrée par {{accountName}}", args),
    auctionSuccess: "L'établissement receveur a été trouvé",
    auctionSuccessProviderKnown: (args: {
      name: string;
      accountName: string;
    }) => template("{{name}} manuellement choisi par {{accountName}}", args),
    auctionSuccessProviderUnknown:
      "Identité de l'établissement receveur inconnue, trouvé par un autre moyen",
    auctionSuccessUnknown: (args: { receiverTypeSingular: string }) =>
      template(
        "L'{{receiverTypeSingular}} n'est pas connu, il a été trouvé par un autre moyen",
        args,
      ),
    auctionToBeStarted: (args: { accountName: string }) =>
      template("{{accountName}} handed over the search", args),
    auctionValidationCanceledUnknown: (args: { accountName: string }) =>
      template(
        "{{accountName}} a annulé la validation de l'établissement receveur non spécifié",
        args,
      ),
    auctionValidationCanceledUnknownReason: (args: {
      accountName: string;
      reason: string;
    }) =>
      template(
        "{{accountName}} a annulé la validation de l'établissement receveur non spécifié: {{reason}}",
        args,
      ),
    cancelledValidation: (args: {
      workerName: string;
      providerName: string;
      reason: string;
    }) =>
      template(
        "{{workerName}} a annulé la prise en charge par {{providerName}}: {{reason}}",
        args,
      ),
    cancelRejection: (args: { accountName: string; careprovider: string }) =>
      template(
        "{{accountName}} cancelled the rejection of '{{careprovider}}'",
        args,
      ),
    capacityConfirmed: "La capacité a été confirmée",
    capacityUpdated: "La capacité a été mise à jour",
    careproviderCreated: "Receveur créé ",
    careproviderOnboarding: (args: { name: string }) =>
      template(
        "{{name}} est en train d'être enregistré sur notre plateforme. Aucune demande ne lui a été envoyée.",
        args,
      ),
    careproviderOnboardingStatusChanged: (args: {
      prevStatus: string;
      newStatus: string;
    }) =>
      template(
        "Statut d'onboarding changé de {select:{{prevStatus}}} à {select:{{newStatus}}}",
        args,
      ),
    careproviderStatusChanged: (args: {
      prevStatus: string;
      newStatus: string;
    }) =>
      template(
        "Le statut a changé de {select:{{prevStatus}}} à {select:{{newStatus}}}",
        args,
      ),
    careproviderUpdated: "Établissement receveur updated",
    companyCreated: "Company created",
    companyResourceAdded: (args: { resource: string }) =>
      template("{{resource}} added to company", args),
    companyResourceRemoved: (args: { resource: string }) =>
      template("{{resource}} removed from company", args),
    companyStatusChange: (args: { newStatus: string }) =>
      template("Company status changed to {{newStatus}}", args),
    companyUpdated: "Company updated",
    costPayerChanged: "Cost payer was changed",
    documentationTitle: "Résumé de l'activité",
    eligibilityApproved: (args: { accountName: string }) =>
      template(
        "The request for cost coverage was approved by {{accountName}}",
        args,
      ),
    eligibilityBackToNotProcessed: (args: { accountName: string }) =>
      template(
        "The submission of the request was canceled by {{accountName}}",
        args,
      ),
    eligibilityCancelled: (args: { accountName: string }) =>
      template(
        "The status of the cost coverage was reset by {{accountName}}",
        args,
      ),
    eligibilityInProcess: (args: { accountName: string }) =>
      template(
        "The request for cost coverage was sent by {{accountName}}",
        args,
      ),
    eligibilityRejected: (args: { accountName: string }) =>
      template(
        "The request for cost coverage was rejected by {{accountName}}",
        args,
      ),
    filesChanged: {
      fileAccessChangedMyAccount: (args: {
        previousFileShareMode: string;
        newFileShareMode: string;
      }) =>
        template(
          "Vous avez changé l'accès au fichier de {{previousFileShareMode}} à {{newFileShareMode}}",
          args,
        ),
      fileAccessChangedOtherAccount: (args: { accountName: string }) =>
        template("{{accountName}} a changé l'accès au fichier", args),
      fileAccessRevokedMyAccount: (args: { name: string }) =>
        template("Vous avez révoqué l'accès au fichier pour {{name}}", args),
      fileAccessRevokedMyAccountMessenger:
        "Vous avez révoqué l'accès au fichier ",
      fileAccessRevokedOtherAccount: (args: { name: string }) =>
        template("{{name}} a révoqué l'accès au fichier", args),
      fileAccessRevokedOtherAccountMessenger: (args: { name: string }) =>
        template("{{name}} a révoqué l'accès au fichier ", args),
      fileDeletedMyAccount: "Vous avez supprimé un fichier",
      fileDeletedOtherAccount: (args: { name: string }) =>
        template("{{name}} a supprimé un fichier ", args),
      fileSharedMyAccount: (args: { name: string }) =>
        template("Vous avez partagé un fichier avec {{name}} ", args),
      fileSharedOtherAccount: (args: { accountName: string }) =>
        template("{{accountName}} a partagé un fichier", args),
      fileUploaded: "Vous avez téléchargé un fichier",
    },
    mailFailure: "L'email n'a pu être envoyé",
    markedPatientPreferred: {
      myAccount: (args: {
        providerName: string;
        receiverTypeSingularPreferred: string;
      }) =>
        template(
          "Vous avez sélectionné {{providerName}} comme {{receiverTypeSingularPreferred}} ",
          args,
        ),
      otherAccount: (args: {
        accountName: string;
        providerName: string;
        receiverTypeSingularPreferred: string;
      }) =>
        template(
          "{{accountName}} a sélectionné {{providerName}} comme {{receiverTypeSingularPreferred}} ",
          args,
        ),
    },
    onboardingEmail: {
      notSpecified: "Type: non spécifié",
      prio: "Type: Prioritaire",
      regular: "Type: Ordinaire",
      reminder: "Type: Rappel",
    },
    onboardingEmailSent: "Email d'onboarding envoyé",
    patientArchived: "Le profil a été archivé",
    patientArchivedNotification:
      'La recherche a été archivée - vous pouvez la retrouver sous l\'onglet "Archivé" ou via la barre de recherche',
    patientCreated: "Le patient a été créé",
    patientDischarged: "Le patient a quitté l'établissement",
    patientUpdated: {
      infoExcludedProviders: (args: { reason: string }) =>
        template(
          "En raison de changements dans {{reason}}, la liste des fournisseurs peut avoir été mise à jour.",
          args,
        ),
      infoExcludedProvidersNoReason:
        "En raison de changements, la liste des fournisseurs peut avoir été mise à jour.",
      myAccount: "Vous avez mis le profil du patient à jour.",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} a mis à jour le profil.", args),
    },
    pushedToCallcenter: "Envoyé au Callcenter",
    rehaFormCreated: (args: { formName: string; accountName: string }) =>
      template("{{formName}} was created by {{accountName}}", args),
    rehaFormDeleted: (args: { formName: string; accountName: string }) =>
      template("{{formName}} a été supprimé par {{accountName}}", args),
    rehaFormUpdated: (args: { formName: string; accountName: string }) =>
      template("{{formName}} was edited by {{accountName}}", args),
    rehaFormUpdatedAlreadySent: (args: {
      formName: string;
      accountName: string;
    }) => template("{{formName}} was marked as sent by {{accountName}}", args),
    rehaFormUpdatedBackToDraft: (args: {
      formName: string;
      accountName: string;
    }) =>
      template("{{formName}} was moved back to draft by {{accountName}}", args),
    rehaFormUpdatedCompleted: (args: {
      formName: string;
      accountName: string;
    }) => template("{{formName}} was completed by {{accountName}}", args),
    removedPatientPreferred: {
      myAccount: (args: {
        providerName: string;
        receiverTypeSingularPreferred: string;
      }) =>
        template(
          "Vous avez désélectionné {{providerName}} comme {{receiverTypeSingularPreferred}} pour le patient.",
          args,
        ),
      otherAccount: (args: {
        accountName: string;
        providerName: string;
        receiverTypeSingularPreferred: string;
      }) =>
        template(
          "{{accountName}} has de-selected {{providerName}} as {{receiverTypeSingularPreferred}} of the patient",
          args,
        ),
    },
    requestAvailable: "Cette demande est à nouveau disponible",
    requestDismissed: (args: { accountName: string; careprovider: string }) =>
      template(
        "{{accountName}} dismissed the suggestion of '{{careprovider}}'",
        args,
      ),
    requestManuallyAdded: (args: {
      socialWorker: string;
      careprovider: string;
    }) => template("{{socialWorker}} a ajouté '{{careprovider}}'", args),
    requestResent: (args: { careprovider: string }) =>
      template("La demande a été envoyée à nouveau à {{careprovider}}", args),
    requestToBeSent: (args: { accountName: string; careprovider: string }) =>
      template("{{accountName}} suggested '{{careprovider}}' to review", args),
    requestUnavailable: "Cette demande n’est plus disponible",
    requestUnavailableProvider: "Cette demande n’est plus disponible",
    senderCreated: "Sender created",
    senderUpdated: "Sender updated",
    smsSent: "SMS envoyé",
    statusChanged: (args: { prevStatus: string; newStatus: string }) =>
      template(
        "Le statut a changé de {select:{{prevStatus}}} à {select:{{newStatus}}}",
        args,
      ),
    suggestionCancelled: (args: {
      accountName: string;
      careprovider: string;
    }) =>
      template(
        "{{accountName}} cancelled the suggestion of {{careprovider}}",
        args,
      ),
    testAnswered: "Test réussi",
    testSent: "Test envoyé",
    title: "Activité",
    transitionalCareCreated: (args: { accountName: string }) =>
      template(
        "Documentation for transitional care was created by {{accountName}}",
        args,
      ),
    transitionalCareDeleted: (args: { accountName: string }) =>
      template(
        "Documentation for transitional care was deleted by {{accountName}}",
        args,
      ),
    transitionalCareUpdated: (args: { accountName: string }) =>
      template(
        "Documentation for transitional care was edited by {{accountName}}",
        args,
      ),
    you: "vous",
  },
  todo: {
    abbreviatedCareLevel: "GIR :",
    archived: "Archivées",
    cards: {
      answer: "Répondre",
      continueEditing: "Poursuivre",
      continueSearch: "Reprendre",
      insuranceChip: "",
      internalId: "Identifiant interne",
      newMessage: "nouveau message",
      prereserve: "Pre-reserve",
      restartSearch: "Relancer",
      sendRequests: "Envoyer d'autres demandes",
      startSearch: "Lancer la recherche",
      stopSearch: "Annuler la recherche",
      tooltipFormShared: "AHB form has been shared",
      tooltipInsuranceChip: "",
      updateProfile: "Mettre à jour (date)",
    },
    categoryElective: "",
    categoryFinished: "Terminées",
    categoryPatients: "Patients",
    discharge: "Date de transfert",
    dischargeDate: "Date de sortie",
    drafts: "Brouillons",
    filteredBy: "Filtré par",
    finishedHint:
      "Veuillez noter que les recherches annulées depuis plus de 10 jours et les recherches validées depuis plus de 30 jours seront automatiquement archivées.",
    loadingError: "Impossible de rafraîchir",
    noRequestFilterResults:
      "La liste de résultats est filtrée, réinitialisez pour tout voir.",
    noRequests: "Aucun patient",
    patient: "Patient",
    reenterDataPostIncident: {
      body: "",
      title: "",
    },
    refreshApp:
      "Veuillez s'il-vous-plaît rafraichir la page pour utiliser la dernière version à jour de Recare.",
    responsiblePerson: "Personne en charge",
    running: "En cours",
    startDate: "Date de transfert",
    stopped: "Annulées",
    stoppedHint:
      "Veuillez noter que les recherches annulées depuis plus de 10 jours seront automatiquement archivées.",
    titleNavBar: "Recherche",
    transfer: "Receveur souhaité",
    validated: "Validées",
    validatedHint:
      "Veuillez noter que les recherches validées depuis plus de 30 jours seront automatiquement archivées.",
  },
  tours: {
    accessPatientData: {
      stepA: {
        text: "Vous pouvez consulter ici si l'accès aux données du patient a déjà été donné au receveur. \nVous pouvez décider de donner accès à tout moment. ",
        title: "Garder un aperçu ",
      },
      stepB: {
        text: "En cliquant sur \"Autoriser l'accès aux données du patient\", vous obtenez un aperçu détaillé de toutes les données à partager et vous pouvez également annuler le processus si vous le souhaitez. Vous ne devez pas accorder l'accès aux données avant la validation finale.",
        title: "Autoriser l'accès aux données des patients",
      },
      stepC: {
        text: "Le partage des données des patients est affiché dans l'historique du chat. L'aperçu complet des données patient que vous avez partagées les receveurs se trouve dans l'onglet \"Activité\".",
        title: "Suivre les modifications",
      },
    },
    availableFilesToShare: {
      stepA: {
        intro:
          "Cliquez ici pour partager des fichiers ou modifier les droits d'accès",
        title: "Partager des fichiers",
      },
    },
    files: {
      backButton: "Précédent",
      doneButton: "Fini",
      nextButton: "Suivant",
      stepA: {
        intro:
          "Partage sécurisé de documents PDF (limite de taille de fichier de 20 Mo)",
        title: "Disponible maintenant :",
      },
      stepB: {
        intro:
          "Téléchargez un fichier et attribuez-le à une catégorie pour que le receveur puisse déterminer plus facilement votre envoi. ",
        title: "Téléchargement de fichiers",
      },
      stepC: {
        intro:
          "En savoir plus sur cette fonctionnalité et sur la manière de l'utiliser.",
        title: "Instructions",
      },
      stepD: {
        intro:
          "Les actions liées au dossier seront enregistrées dans l'onglet documentation à des fins d'audit.",
        title: "Documentation",
      },
      stepE: {
        intro:
          "Si vous souhaitez partager un fichier avec des receveurs sélectionnés plutôt qu'avec tous, cliquez sur le receveur avec lequel vous souhaitez partager un fichier et utilisez la messagerie pour le partage.",
        title: "Partage avec des receveurs sélectionnés",
      },
    },
    parallelSearch: {
      stepA: {
        intro: "",
        title: "",
      },
      stepB: {
        intro: "",
        title: "",
      },
    },
    receiverDashboard: {
      stepA: {
        intro: "",
        title: "",
      },
      stepB: {
        intro: "",
        title: "",
      },
      stepC: {
        intro: "",
        title: "",
      },
      stepD: {
        intro: "",
        title: "",
      },
      stepThree: {
        intro: "",
        title: "",
      },
    },
    receiverSettings: {
      stepA: {
        imagePath: "",
        intro: "",
        title: "",
      },
      stepB: {
        imagePath: "",
        intro: "",
        title: "",
      },
      stepC: {
        imagePath: "",
        intro: "",
        title: "",
      },
    },
    reverseSearch: {
      backButton: "",
      doneButton: "",
      nextButton: "",
      stepA: {
        intro: "",
        title: "",
      },
      stepB: {
        intro: "",
        title: "",
      },
      stepC: {
        intro: "",
        title: "",
      },
      stepD: {
        intro: "",
        title: "",
      },
      stepE: {
        intro: "",
        introCapacityMode: "",
        title: "",
      },
      stepF: {
        intro: "",
        introCapacityMode: "",
        title: "",
      },
      stepG: {
        intro: "",
        title: "",
      },
    },
    searchMerge: {
      stepA: {
        intro: "Toutes les informations sur votre recherche en un coup d'œil",
        title: "Plus intuitif : ",
      },
      stepB: {
        intro:
          'Cliquez sur "Contacter" pour contacter tous les receveurs potentiels. Vous ne souhaitez contacter que certains receveurs ? Dans ce cas, cliquez d\'abord sur la case bleue pour les désélectionner, puis sélectionnez individuellement les receveurs souhaités. Enfin, cliquez sur "Contacter".',
        title: "Commencer la recherche :",
      },
      stepC: {
        intro:
          "Indiquez le souhait du patient en cliquant sur l'étoile située à côté du receveur concerné. Vous pouvez également spécifier la raison. Le receveur concerné en sera informé.",
        title: "Indiquer le souhait du patient :",
      },
      stepD: {
        intro:
          "Cliquez sur une ligne pour ouvrir la recherche et communiquer avec le receveur via l'espace de messagerie.",
        title: "Voir les détails : ",
      },
    },
    searchMergeContacted: {
      stepA: {
        intro:
          "Dans ce tableau, vous pouvez voir tous les receveurs qui ont déjà été contactés. Cliquez sur une ligne pour ouvrir une demande. ",
        title: "Suivez vos recherches :",
      },
      stepB: {
        intro:
          "Indiquez les souhaits du patient pour les receveurs qui ont été contactés. Pour ce faire, cliquez sur l'étoile située à côté du nom du receveur et indiquez une raison si vous le souhaitez. Le receveur en sera informé.",
        title: "Souhaits du patient :",
      },
      stepC: {
        intro:
          'La colonne "Statut" vous informe sur les progressions de vos demandes, comme par exemple un nouveau message ou document reçu. L\'événement le plus récent sera affiché. ',
        title: "Voir les statuts des demandes :",
      },
    },
    transitionalCare: {
      stepA: {
        intro: "",
        title: "",
      },
      stepB: {
        intro: "",
        title: "",
      },
    },
  },
  userManagement: {
    accountEdit: "Modifier le compte",
    accountInputLabel: "Compte d'utilisateur",
    accountReactivate: "Réactiver le compte",
    activationEmailFail: "",
    activationEmailSuccess: "",
    addAccountFlow: {
      accountEditPreTitle: "Cet utilisateur existe déjà dans notre système.",
      accountEditSubTitleCareseeker:
        "Les informations affichées dans ces champs sont accessibles à toutes les cliniques connectées à ce compte utilisateur. Si vous effectuez des modifications, elles seront appliquées à toutes les cliniques.",
      backButton: "",
      cancelButton: "",
      confirmButton: "",
      createButton: "",
      emailAddress: "E-mail address",
      mandatoryFieldEmail: "",
      mandatoryFieldFirstName: "",
      mandatoryFieldLastName: "",
      mandatoryFieldRole: "",
      rolesPermissionTitle: (args: { clinicName: string }) =>
        template(
          "Définir les rôles et les autorisations pour cet utilisateur dans {{clinicName}}",
          args,
        ),
      rolesSectionTitle: "Informations spécifiques à la clinique",
      stepOneSubtitle: "",
      stepOneTitle: "",
    },
    confirmationModalActivationMail: "",
    confirmationModalDeactivateText: "",
    confirmationModalDeactivateTextSSO: "",
    confirmationModalDeactivateTitle: "",
    confirmationModalNormalAccount: "",
    confirmationModalPasswordReset: "",
    confirmationModalReactivateText: "-",
    confirmationModalReactivateTitle: "-",
    confirmationModalResetUserManager: "",
    confirmationModalSetUserManager: "",
    deactivateButton: "",
    deactivationDisabledHover: "",
    deactivationFail: "",
    deactivationSuccess: "",
    email: "",
    errorAccountOutsideGroup: "",
    errorDomainNotWhitelisted:
      "The domain you entered is not included in the list of whitelisted domains for your company.\nPlease make sure that the spelling is correct. If the problem persists, please contact our support team.",
    errorEmailAlreadyExists: (args: { emailAddress: string }) =>
      template("{{emailAddress}}", args),
    fileInputLabel: "Accord signé",
    informationBanner: "",
    informationBannerShowMore: "",
    informationBannerSSO: "",
    infoSection: {
      infoSectionFiveText: "",
      infoSectionFiveTitle: "",
      infoSectionFourText: "",
      infoSectionFourTitle: "",
      infoSectionOneText: "",
      infoSectionOneTitle: "",
      infoSectionThreeText: "",
      infoSectionThreeTitle: "",
      infoSectionTwoText: "",
      infoSectionTwoTitle: "",
    },
    infoSectionOneText:
      "Les comptes existants se voient toujours attribuer l'un des trois statuts suivants :\n\nActif : les comptes ayant ce statut se sont déjà connectés à la plateforme au moins une fois et peuvent utiliser certaines fonctionnalités, en fonction des rôles et autorisations qui leur sont attribués.\n\nCréé : Ce statut indique que le compte a été créé et qu'un email d'activation a été envoyé. Cependant, l'utilisateur n'est pas encore connecté et n'a donc pas finalement activé son compte.\n\nInactif : les comptes avec ce statut ont été désactivés. Les utilisateurs ne peuvent plus se connecter à la plateforme. En réactivant le compte, un email d'activation est à nouveau envoyé et l'utilisateur reçoit le statut \"Créé\".",
    infoSectionOneTitle: "Statut",
    infoSectionTitle: "Présentation des comptes d'utilisateurs",
    limitCreationInfo: "",
    limitDeactivationInfo: "",
    limitReactivationInfo: "",
    mandatoryFieldInformation: "* indique un champ obligatoire.",
    name: "",
    numberAccounts: (args: {
      numbersearchresults: string;
      numberexistingaccounts: string;
    }) =>
      template("{{numbersearchresults}} of {{numberexistingaccounts}}", args),
    passwordEmailFail: "",
    passwordEmailSuccess: "",
    permissions: "Permissions",
    reactivateButton: "",
    reactivationFail: "",
    reactivationSuccess: "",
    resendActicationMailButton: "",
    resetPasswordButton: "",
    roles: "Rôles",
    searchFieldPlaceholder: "",
    searchNoResult: "",
    status: "",
    tableDescription: "Table description",
    tableTitle: "Comptes",
    title: "",
  },
  weekdays: {
    everyday: "",
    friday: "Vendredi",
    monday: "Lundi",
    saturday: "Samedi",
    sunday: "Dimanche",
    thursday: "Jeudi",
    timeFormat: "hh:mm",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    weekdays: "",
    weekends: "",
  },
} as const;

export default fr;
